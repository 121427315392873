import React, { useEffect, useState } from 'react';
import { Spinner, Tab } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import ChartLabel from './chartLabel';
import ReactApexChart from 'react-apexcharts';

const AverageStageDurationChart = ({}) => {
  const [chartData, setChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const orgId = userDetails?.user?.organizationId;

  const fetchData = async () => {
    setIsLoading(true);
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const accessToken = userDetails?.session.accessToken || null;
    if (!accessToken) {
      throw new Error('Access token not found');
    }
    const URL = `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/analytics/application-average-status-time`;

    try {
      const response = await fetch(URL, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      });
      if (!response.ok) {
        throw new Error('Error getting reponse');
      }
      const body = await response.json();
      const data = body.data;
      setChartData(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log('Could not fetch pie data', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const aiAnswerSubmitted = chartData?.find(
    ({ status }) => status === 'AI_ANSWERS_SUBMITTED'
  )?.averageDuration;
  const applicationSubmitted = chartData?.find(
    ({ status }) => status === 'APPLICATION_SUBMITTED'
  )?.averageDuration;
  const aiAnswerAccepted = chartData?.find(
    ({ status }) => status === 'AI_ANSWERS_ACCEPTED'
  )?.averageDuration;
  const preAssessmentPassed = chartData?.find(
    ({ status }) => status === 'PRE_ASSESSMENT_PASSED'
  )?.averageDuration;
  const profileAnalyzed = chartData?.find(
    ({ status }) => status === 'PROFILE_ANALYZED'
  )?.averageDuration;
  const rejected = chartData?.find(
    ({ status }) => status === 'REJECTED'
  )?.averageDuration;

  const data = {
    labels: ['Application Stages'],
    datasets: [
      {
        label: 'Application Submitted',
        data: [applicationSubmitted],
        backgroundColor: 'rgb(72, 133, 237)'
      },
      {
        label: 'Profile Analyzed',
        data: [profileAnalyzed],
        backgroundColor: 'rgb(183, 35, 173)'
      },
      {
        label: 'AI Answers Submitted',
        data: [aiAnswerSubmitted],
        backgroundColor: 'rgb(20, 86, 80)'
      },

      {
        label: 'AI Answers Accepted',
        data: [aiAnswerAccepted],
        backgroundColor: 'rgb(38, 199, 170)'
      },

      {
        label: 'Pre Assesment Passed',
        data: [preAssessmentPassed],
        backgroundColor: 'rgb(56, 184, 86)'
      },
      {
        label: 'Rejected',
        data: [rejected],
        backgroundColor: 'rgb(245, 79, 82)'
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
          color: 'rgba(89, 59, 219,0.1)',
          drawBorder: true
        }
      },
      y: {
        type: 'logarithmic',
        grid: {
          color: 'rgba(89, 59, 219,0.1)',
          drawBorder: true,
          display: true
        }
      }
    }
  };

  return (
    <Tab.Container defaultActiveKey="Daily">
      <div className="card" style={{ height: '30rem' }}>
        <div className="card-header border-0 pb-0 flex-wrap">
          <h4 className="fs-20 font-w500">
            Average Stage Durations In Minutes
          </h4>
        </div>
        <div className="card-body">
          <div className="pb-3 d-flex flex-wrap justify-content-between">
            <ChartLabel title={'Application Submitted'} color={'#4885ED'} />
            <ChartLabel title={'Profile Analyzed'} color={'#B723AD'} />
            <ChartLabel title={'AI Answers Submitted'} color={'#145650'} />
            <ChartLabel title={'AI Answers Accepted'} color={'#26C7AA'} />
            <ChartLabel title={'Pre Assesment Passed'} color={'#38B856'} />
            <ChartLabel title={'Rejected'} color={'#F54F52'} />
          </div>
          <Tab.Content>
            <div style={{ height: '19rem' }}>
              {isLoading ? (
                <div className="h-100 d-flex align-items-center justify-content-center">
                  <Spinner
                    animation="border mb-3"
                    style={{ color: '#858585' }}
                  />
                </div>
              ) : (
                <>
                  {chartData ? (
                    <Bar options={options} data={data} />
                  ) : (
                    <h5>No Data Found</h5>
                  )}
                </>
              )}
            </div>
          </Tab.Content>
        </div>
      </div>
    </Tab.Container>
  );
};

export default AverageStageDurationChart;
