import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Spinner,
  Form
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ToggleSwitch from '../bootstrap/Toggle';
import { FaEye, FaPencilAlt, FaCopy } from 'react-icons/fa';
import { FaAngleLeft, FaAngleRight, FaRegTrashCan } from 'react-icons/fa6';
import { IoSearch } from 'react-icons/io5';
import { MdOutlineWifiTetheringErrorRounded } from 'react-icons/md';
import Search from '../Jobick/Jobs/ui/search';
import currencySymbols from 'currency-symbol-map';
import { formatToCurrency, getItemsPerPage } from '../../utils/CommonFunctions';
import { FaPlus } from 'react-icons/fa6';
import { FaFilter } from 'react-icons/fa6';

const JobLists = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState(''); // for status filter
  const [pageVal, setPageVal] = useState(1); // for value of pagination
  const [totalJobs, setTotalJobs] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(1);
  const [jobList, setJobList] = useState(null);
  const [searchVal, setSearchVal] = useState('');
  const jobsPerPage = 6;
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const orgId = userDetails?.user?.organizationId;
  const [currencySymbol, setCurrencySymbol] = useState('₹');

  useEffect(() => {
    const storedCurrency = localStorage.getItem('currency');

    if (storedCurrency) {
      const symbol = currencySymbols(storedCurrency) || '₹';
      setCurrencySymbol(symbol);
    }
  }, []);

  const handlePreviousPageClick = () => {
    if (pageVal === 1) {
      return;
    }
    setPageVal(pageVal - 1);
  };

  const handleNextPageClick = () => {
    if (pageVal >= totalPages) {
      return;
    }
    setPageVal(pageVal + 1);
  };

  const fetchJobList = async () => {
    !jobList && setLoading(1);
    const URL = `${process.env.REACT_APP_DEV_API}/v1/organization/
	${orgId}/
	job?search=${searchVal}&page=${pageVal}&limit=${getItemsPerPage()}${
    status && '&status=' + status
  }&sortBy=createdAt&sortOrder=desc`;
    try {
      const response = await fetch(URL);
      if (!response.ok) {
        throw new Error('Fetching failed');
      }
      const body = await response.json();
      const { data, total, totalPages } = body;
      setJobList(data);
      setTotalJobs(total);
      setTotalPages(totalPages);
      setLoading(0);
    } catch (error) {
      setLoading(2);
      console.error('Error fetching job list', error);
    }
  };

  useEffect(() => {
    fetchJobList();
  }, [pageVal]);

  useEffect(() => {
    if (pageVal === 1) {
      fetchJobList();
    } else {
      setPageVal(1);
    }
  }, [searchVal, status]);

  const handleStatusToggle = async (jobId, jobStatus) => {
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const accessToken = userDetails?.session.accessToken || null;
    if (!accessToken) {
      throw new Error('Access token not found');
    }
    const URL = `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/job/${jobId}`;
    const bodydata = {
      status: jobStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
    };
    try {
      const response = await fetch(URL, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify(bodydata)
      });
      if (!response.ok) {
        throw new Error('Error getting reponse');
      }
      fetchJobList();
    } catch (error) {
      console.error('Could not fetch pie data', error);
    }
  };

  const handleEditClick = (jobId) => {
    navigate(`/update-job/${jobId}`);
  };

  const handleCloneJob = (jobId) => {
    navigate(`/clone-job/${jobId}`);
  };

  const handleAddNewJob = () => {
    navigate('/new-job');
  };

  const pgItem1 =
    pageVal === 1
      ? 1
      : pageVal >= totalPages && totalPages > 2
        ? totalPages - 2
        : pageVal - 1;
  const pgItem2 =
    pageVal === 1
      ? 2
      : pageVal >= totalPages && totalPages > 2
        ? totalPages - 1
        : pageVal;
  const pgItem3 =
    pageVal === 1
      ? 3
      : pageVal >= totalPages && totalPages > 2
        ? totalPages
        : pageVal + 1;

  return (
    <>
      <style>
        {`
    .custom-dropdown-toggle,
    .custom-dropdown-toggle:hover,
    .custom-dropdown-toggle:focus,
    .custom-dropdown-toggle:active {
      border: 1px solid #d3d3d3 !important;
      outline: none !important;
      box-shadow: none !important;
      
    }
    .custom-dropdown-toggle::after {
      display: none !important;
    }
  `}
      </style>
      <div className="d-flex gap-2 align-items-center mb-4 flex-wrap">
        <h3 className="mb-0 me-auto">Job List </h3>
        <Search searchVal={searchVal} setSearchVal={setSearchVal} />
        <Dropdown
          as="li"
          className="nav-item solid style-1 notification_dropdown"
        >
          <Dropdown.Toggle
            variant="secondary"
            size="sm"
            id="  "
            className="nav-link ai-icon i-false c-pointer"
          >
            <FaFilter size="13" />
          </Dropdown.Toggle>

          <Dropdown.Menu
            align="end"
            className="p-2 status-dropdown-menu"
            style={{ color: '#4885ed', overflow: 'visible' }}
          >
            <Dropdown.Header
              as="h4"
              style={{
                fontSize: '1.125rem',
                fontWeight: '500',
                padding: '0.5rem'
              }}
            >
              Filters
            </Dropdown.Header>
            <Dropdown.Divider
              style={{ border: '0', backgroundColor: '#888888' }}
            />
            <div className="ps-2">
              <Form.Group className="d-flex align-items-center gap-3">
                <Form.Label
                  className="text-black fs-5"
                  style={{ marginBottom: '0.5rem' }}
                >
                  Status
                </Form.Label>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="light"
                    id="dropdown-status"
                    className="text-center"
                    style={{
                      width: 'fit-content',
                      color: '#4885ed',
                      background: 'transparent',
                      padding: '0.2rem 0.5rem',
                      borderRadius: '5px'
                    }}
                  >
                    {status || 'Default'}
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={{ padding: '0' }}>
                    <Dropdown.Item
                      onClick={() => setStatus('')}
                      className="custom-dropdown-item"
                      style={{
                        color: '#4885ed',
                        backgroundColor:
                          status === '' ? '#ecf3fd' : 'transparent'
                      }}
                    >
                      Default
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => setStatus('ACTIVE')}
                      className="custom-dropdown-item"
                      style={{
                        color: '#4885ed',
                        backgroundColor:
                          status === 'ACTIVE' ? '#ecf3fd' : 'transparent'
                      }}
                    >
                      Active
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => setStatus('INACTIVE')}
                      className="custom-dropdown-item"
                      style={{
                        color: '#4885ed',
                        backgroundColor:
                          status === 'INACTIVE' ? '#ecf3fd' : 'transparent'
                      }}
                    >
                      InActive
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
            </div>
          </Dropdown.Menu>
        </Dropdown>

        <div>
          <Button className="btn btn-primary btn-sm" onClick={handleAddNewJob}>
            <FaPlus size="11" /> Add New Job
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className=" mb-4 table-responsive">
            <table
              id="job-table"
              className="table  display dataTablesCard mb-4 job-table table-responsive-xl card-table dataTable no-footer"
            >
              <thead>
                <tr className="user-select-none ">
                  <th style={{ width: '4rem' }}>No</th>
                  <th style={{ width: '11rem' }}>Position </th>
                  <th style={{ width: '7rem' }}>Type</th>
                  <th style={{ width: '5rem' }}>Location</th>
                  <th style={{ width: '12rem' }}>Salary</th>
                  <th style={{ width: '3rem' }}>Posted Date</th>
                  <th style={{ width: '8rem' }}>Status</th>
                  <th style={{ width: '12rem' }} className="text-center">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading === 0 &&
                  jobList.map((job, index) => (
                    <tr
                      key={index}
                      style={{
                        height: '4.5rem',
                        borderTop: '1px solid #dee2e6'
                      }}
                    >
                      <td
                        style={{ maxWidth: '4rem' }}
                        className="text-truncate"
                      >
                        {(pageVal - 1) * jobsPerPage + index + 1}.
                      </td>
                      <td
                        style={{ maxWidth: '11rem' }}
                        className="text-truncate"
                      >
                        {job.title}
                      </td>
                      <td
                        style={{ maxWidth: '7rem' }}
                        className="text-truncate wspace-no"
                      >
                        {job.jobType}
                      </td>
                      <td
                        style={{ maxWidth: '5rem' }}
                        className="text-truncate"
                      >
                        {job.location}
                      </td>
                      <td
                        style={{ maxWidth: '12rem' }}
                        className="text-truncate"
                      >
                        {job.minimumSalary
                          ? `${formatToCurrency(job.minimumSalary) || 0} - ${
                              formatToCurrency(job.maximumSalary) || 0
                            }`
                          : job.maximumSalary
                            ? `${formatToCurrency(job.maximumSalary) || 0}`
                            : 'NA'}
                      </td>

                      <td
                        style={{ maxWidth: '3rem' }}
                        className="text-truncate"
                      >
                        {job.createdAt.slice(0, 10)}
                      </td>
                      <td
                        style={{ maxWidth: '9rem' }}
                        className="text-truncate"
                        // style={{ borderTop: "none", borderBottom: "none" }}
                      >
                        <div className="d-flex">
                          <ToggleSwitch
                            isChecked={job.status === 'ACTIVE'}
                            handleToggle={() => {
                              handleStatusToggle(job._id, job.status);
                            }}
                            disabled={job.status === 'DISABLED'}
                          />

                          <span
                            className={`badge badge-lg light badge-${
                              job.status === 'ACTIVE' ? 'success' : 'danger'
                            }`}
                          >
                            {job.status}
                          </span>
                        </div>
                      </td>
                      <td style={{ maxWidth: '12rem' }}>
                        <div
                          className="action-buttons d-flex items-center justify-content-center"
                          style={{ gap: '1rem' }}
                        >
                          <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip>View Job Details</Tooltip>}
                          >
                            <Link
                              to={`/job-view/${job._id}`}
                              className="d-flex align-items-center"
                            >
                              <FaEye size={18} />
                            </Link>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip>Edit Job</Tooltip>}
                          >
                            <Link
                              to={'#'}
                              className="d-flex align-items-center"
                              onClick={(e) => {
                                e.preventDefault();
                                handleEditClick(job._id);
                              }}
                            >
                              <FaPencilAlt size={16} />
                            </Link>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip>View Applications</Tooltip>}
                          >
                            <Link
                              to={`/job-application/${job._id}`}
                              className="d-flex align-items-center"
                            >
                              <svg
                                version="1.1"
                                stroke-width="0"
                                fill="currentColor"
                                stroke="currentColor"
                                viewBox="0 0 2048 2048"
                                width="18"
                                height="18"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  transform="translate(254)"
                                  d="m0 0h1217l4 1 24 8 19 10 16 13 9 9 11 15 8 15 7 21 2 11 1 11v1500l-2 17-6 21-8 16-7 11-11 13-12 11-14 9-14 7-19 6-12 2-11 1h-1186l-19-2-17-5-21-10-12-9-11-9-11-13-10-16-7-16-5-18-2-18v-748l2-11 5-10 9-10 8-5 12-3h10l13 4 10 7 8 11 3 9 1 6 1 746 3 14 4 8 6 7 10 6 12 3h1183l12-3 10-6 7-8 4-9 2-10v-1492l-2-10-4-9-6-8-10-6-11-3-7-1h-1173l-13 2-12 6-7 7-5 10-3 16-1 409-3 12-5 9-8 8-9 5-12 3h-8l-12-3-10-7-7-8-5-10-2-11v-413l2-18 4-15 5-13 8-15 9-12 9-10 10-9 14-9 17-8 20-6z"
                                />
                                <path
                                  transform="translate(1845,320)"
                                  d="m0 0h14l10 3 9 6 8 9 4 8 2 7 1 17v1548l-1 22-2 14-7 21-8 16-10 14-10 11-10 8-16 10-16 7-16 5-7 2h-1288l2-2-9-4-10-9-6-9-3-10v-11l4-13 6-9 7-6 12-6 13-2 1258-1 12-3 9-6 5-5 4-5 3-9 1-5 1-26v-1518l1-35 4-12 6-9 8-7 10-5z"
                                />
                                <path
                                  transform="translate(1687,160)"
                                  d="m0 0h9l9 2 10 5 7 6 6 9 3 8 1 5v1582l-2 16-6 20-8 16-7 11-13 15-11 9-14 9-15 7-16 5-10 2-10 1-22 1h-1072l-15-1-12-3-10-7-7-8-5-11-1-4v-13l4-13 7-9 9-7 10-4 6-1 1063-1 42-1 11-3 9-6 5-5 5-8 3-12 1-1571 2-12 7-14 7-7 14-7z"
                                />
                                <path
                                  transform="translate(423,295)"
                                  d="m0 0h375l17 2 12 6 6 5 7 10 3 8 1 6v391l-3 12-5 9-5 5-9 7-10 3-7 1h-389l-13-3-10-7-7-8-5-10-2-12v-386l2-10 6-12 5-5 6-5 10-5zm38 81-1 1v302l4 1 299-1v-303z"
                                />
                                <path
                                  transform="translate(866,995)"
                                  d="m0 0h458l18 2 12 6 9 9 6 12 2 12-3 15-7 11-9 8-9 4-11 2h-472l-13-3-10-7-7-8-5-10-1-4v-16l4-11 8-11 11-7 10-3z"
                                />
                                <path
                                  transform="translate(866,1299)"
                                  d="m0 0h458l18 2 12 6 9 9 6 12 2 12-3 15-7 11-9 8-9 4-11 2h-472l-13-3-10-7-7-8-5-10-1-4v-16l4-11 8-11 11-7 5-2z"
                                />
                                <path
                                  transform="translate(644,929)"
                                  d="m0 0 10 1 10 3 11 8 7 9 4 10 1 3v13l-3 10-9 13-135 135-13 8-8 2h-14l-8-2-10-6-12-11-69-69-7-10-4-9-1-5v-10l4-13 8-11 9-7 11-4 10-1 13 3 12 7 13 12v2l4 2v2l4 2v2h2v2h2v2h2v2h2v2l4 2v2h2v2h2v2l4 2 9 9 4-1 1-2h2l2-4 15-15h2l2-4h2l2-4h2l2-4 16-16h2l2-4 6-5 6-7 6-5 6-7 6-5 6-7h2l2-4 5-5h2v-2l12-9 8-4z"
                                />
                                <path
                                  transform="translate(642,1235)"
                                  d="m0 0h10l13 4 9 7 7 8 4 8 2 7v12l-3 10-7 11-14 15-120 120-10 7-8 4-11 3-7-1-11-3-12-8-79-79-7-11-3-7-1-5v-9l3-12 8-12 11-8 7-3 6-1h11l13 4 12 9 45 45 3 1 104-104 13-9z"
                                />
                                <path
                                  transform="translate(1030,295)"
                                  d="m0 0h273l16 2 12 6 7 6 7 11 3 11v9l-3 12-8 12-8 6-9 4-11 2h-285l-13-3-10-7-7-8-5-10-1-4v-16l4-11 7-10 10-7 7-3z"
                                />
                                <path
                                  transform="translate(1031,487)"
                                  d="m0 0h269l19 2 12 6 7 6 7 11 3 11v10l-4 13-7 10-8 6-9 4-11 2h-285l-13-3-10-7-7-8-5-10-1-4v-16l4-11 8-11 11-7 5-2z"
                                />
                                <path
                                  transform="translate(1031,679)"
                                  d="m0 0h269l19 2 12 6 7 6 7 11 3 11v10l-4 13-7 9-8 7-9 4-11 2h-285l-13-3-10-7-7-8-5-10-1-4v-16l4-11 8-11 11-7 5-2z"
                                />
                                <path
                                  transform="translate(189,667)"
                                  d="m0 0h13l10 3 11 7 7 9 4 8 2 8v10l-4 13-6 8-5 5-9 6-11 3h-11l-13-4-9-7-6-7-5-10-1-4v-16l4-11 8-11 11-7z"
                                />
                                <path
                                  transform="translate(351,1808)"
                                  d="m0 0h10l13 4 11 8 7 10 4 12v12l-3 10-7 11-7 6-9 5-9 2h-10l-12-3-10-7-7-8-5-11-1-4v-13l3-10 6-10 9-8 11-5z"
                                />
                                <path transform="translate(1477)" d="m0 0" />
                                <path transform="translate(251)" d="m0 0" />
                              </svg>
                            </Link>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip>Clone Job</Tooltip>}
                          >
                            <Link
                              to={'#'}
                              className="d-flex align-items-center"
                              onClick={(e) => {
                                e.preventDefault();
                                handleCloneJob(job._id);
                              }}
                            >
                              <FaCopy size={18} />
                            </Link>
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip>Delete Job</Tooltip>}
                          >
                            <Link
                              to={'#'}
                              className="d-flex align-items-center"
                            >
                              <FaRegTrashCan size={18} color="red" />
                            </Link>
                          </OverlayTrigger>
                        </div>
                      </td>
                    </tr>
                  ))}

                {/* Adding empty filler rows to keep the height of table same */}
                {loading === 0 &&
                  jobList.length > 0 &&
                  [...Array(Math.max(0, jobsPerPage - jobList.length))].map(
                    (_, i) => <tr key={i} style={{ height: '4.5rem' }}></tr>
                  )}
              </tbody>
            </table>
            {loading === 1 && (
              <div className="d-flex flex-column align-items-center justify-content-center gap-5 mt-5 pt-5">
                <div className="mt-5 mb-4 pt-1"></div>
                <Spinner animation="border mb-3" style={{ color: '#858585' }} />
              </div>
            )}
            {loading === 2 && (
              <div className="d-flex flex-column align-items-center mt-5 pt-5">
                <MdOutlineWifiTetheringErrorRounded
                  size={120}
                  color="#878787B7"
                />
                <h2>Error fetching data</h2>
                <h3 style={{ color: '#828282' }}>
                  Check your network and try again
                </h3>
              </div>
            )}
            {loading === 0 && jobList.length === 0 && (
              <div className="d-flex flex-column align-items-center mt-5 pt-5">
                <IoSearch size={120} color="#878787B7" />
                <h2>No results found</h2>
                <h3 style={{ color: '#828282' }}>
                  Adjust your filters and try again
                </h3>
              </div>
            )}
          </div>
        </div>
        {loading === 0 && jobList.length !== 0 && (
          <div className="d-md-flex d-block align-items-center justify-content-between text-center  flex-wrap mt-md-0 mt-3">
            <div className="mb-md-0 mb-2">
              <p className="dataTables_info">{`Showing ${
                jobList.length !== 0 ? jobsPerPage * (pageVal - 1) + 1 : 0
              } to ${
                jobList ? jobsPerPage * (pageVal - 1) + jobList.length : 0
              } of ${totalJobs} entries`}</p>
            </div>
            <nav>
              <ul className="pagination pagination-circle justify-content-center">
                <li className="page-item page-indicator">
                  <button
                    onClick={handlePreviousPageClick}
                    disabled={pageVal === 1}
                    className={' page-link'}
                    style={{ backgroundColor: pageVal === 1 && '#ECECEC',
                      cursor: pageVal === 1 && 'not-allowed',
                     }}
                  >
                    <FaAngleLeft
                      style={{ color: pageVal === 1 && '#616161' }}
                    />
                  </button>
                </li>
                {pgItem1 > 1 ? (
                  <li className="page-item d-flex align-items-end">
                    <label className="fs-4">...</label>
                  </li>
                ) : (
                  <div style={{ width: '15px' }} />
                )}
                <li
                  className={`page-item ${pgItem1 === pageVal && 'active'}`}
                  onClick={() => {
                    setPageVal(pgItem1);
                  }}
                >
                  <Button className="page-link">{pgItem1}</Button>
                </li>
                {totalPages > 1 && (
                  <li
                    className={`page-item ${pgItem2 === pageVal && 'active'}`}
                    onClick={() => {
                      setPageVal(pgItem2);
                    }}
                  >
                    <Button className="page-link">{pgItem2}</Button>
                  </li>
                )}
                {totalPages > 2 && (
                  <li
                    className={`page-item ${pgItem3 === pageVal && 'active'}`}
                    onClick={() => {
                      setPageVal(pgItem3);
                    }}
                  >
                    <Button className="page-link">{pgItem3}</Button>
                  </li>
                )}
                {pgItem3 < totalPages ? (
                  <li className="page-item d-flex align-items-end">
                    <label className="fs-4">...</label>
                  </li>
                ) : (
                  <div style={{ width: '15px' }} />
                )}
                <li className="page-item page-indicator">
                  <button
                    onClick={handleNextPageClick}
                    disabled={pageVal >= totalPages}
                    className="page-link"
                    style={{
                      backgroundColor: pageVal >= totalPages && '#ECECEC',
                      cursor: pageVal >= totalPages && 'not-allowed',
                    }}
                  >
                    <FaAngleRight
                      style={{ color: pageVal >= totalPages && '#616161' }}
                    />
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        )}
      </div>
    </>
  );
};
export default JobLists;
