import React, { useEffect, useRef, useState } from 'react';
import { formatToCurrency } from '../../utils/CommonFunctions';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  Spinner,
  Alert,
  Dropdown,
  Accordion,
  Modal,
  Button
} from 'react-bootstrap';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import ApplicationStatusProgress from '../Jobick/Jobs/ui/jobApplicationView/applicationProgress';
import { SlUser, SlUserFemale } from 'react-icons/sl';
import { TbEdit } from 'react-icons/tb';
import { MdKeyboardArrowDown, MdClose } from 'react-icons/md';
import currencySymbols from 'currency-symbol-map';
import Rating from './rating';
import Select from 'react-select';
import html2pdf from 'html2pdf.js';
import {
  FaBuilding,
  FaClock,
  FaEye,
  FaMapMarkedAlt,
  FaPhoneAlt,
  FaTimes
} from 'react-icons/fa';
import avatarImg from '../../../images/avatar.webp';
import {
  BsArrowClockwise,
  BsDownload,
  BsEnvelope,
  BsEnvelopeFill,
  BsMapFill,
  BsMarkerTip
} from 'react-icons/bs';

const statusOptions = [
  { value: 'INTERVIEW_PROCESS_STARTED', label: 'Interview Process Started' },
  { value: 'INTERVIEWS_COMPLETED', label: 'Interviews Completed' },
  { value: 'REJECTED', label: 'Rejected' },
  { value: 'OFFER_EXTENDED', label: 'Offer Extended' },
  { value: 'HIRED', label: 'Hired' }
];

// Set up the worker for react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const JobApplicationView = () => {
  const { jobId, appId } = useParams() || {};
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [jobDetails, setJobDetails] = useState(null);
  const [applicationDetails, setApplicationDetails] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [loading, setLoading] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [resumeLink, setResumeLink] = useState(null);
  const [isResumeModalOpen, setIsResumeModalOpen] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [
    showApplicationReattemptLoader,
    setShowApplicationReattemptLoaderFlag
  ] = useState(false);
  const [showPDFDownloadLoader, setShowPDFDownloadLoaderFlag] = useState(false);
  const [applicationStatus, setApplicationStatus] = useState(null);
  const [updateApplicationModal, setUpdateApplicationModal] = useState(false);
  const [applicationReason, setApplicationReason] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const orgId = userDetails?.user?.organizationId;
  const [openSections, setOpenSections] = useState([]);
  const [currencySymbol, setCurrencySymbol] = useState('₹');
  let storedCurrency = localStorage.getItem('currency');
  const pdfContentRef = useRef(null);

  useEffect(() => {
    storedCurrency = localStorage.getItem('currency');

    if (storedCurrency) {
      const symbol = currencySymbols(storedCurrency) || '₹';
      setCurrencySymbol(symbol);
    }
  }, []);
  const toggleSection = (section) => {
    if (openSections.includes(section)) {
      setOpenSections(openSections.filter((sec) => sec !== section));
    } else {
      if (openSections.length < 2) {
        setOpenSections([...openSections, section]);
      } else {
        // If already two sections are open, replace the oldest open one
        setOpenSections([openSections[1], section]);
      }
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setIsResumeModalOpen(false);
      }
    };

    if (isResumeModalOpen) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isResumeModalOpen]);

  const accessToken = localStorage.getItem('userDetails')
    ? JSON.parse(localStorage.getItem('userDetails')).session.accessToken
    : null;

  if (!accessToken) {
    throw new Error('Access token not found in local storage');
  }

  const fetchJobDetails = async () => {
    const URL = `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/job/${jobId}`;
    try {
      setLoading(0);
      const response = await fetch(URL);
      if (!response.ok) {
        navigate('/page-error-404');
        setLoading(2);
        return;
      }
      const body = await response.json();
      const data = body.data;
      const responsibilitiesArray = data.responsibilities
        .split('. ')
        .map((item) => item.trim())
        .filter((item) => item);

      data['responsibilities'] = responsibilitiesArray;
      setJobDetails(data);
      setLoading(1);
    } catch (error) {
      navigate('/page-error-404');
      console.log('Error fetching job details', error);
      setLoading(2);
    }
  };

  const fetchApplicationDetails = async () => {
    try {
      setLoading(0);
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/job/${jobId}/application/${appId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      if (!response.ok) {
        setLoading(2);
        navigate('/page-error-404');
        return;
      }

      const body = await response.json();
      const applicationData = body.data.application;
      const userDetail = body.data.userDetails;
      setApplicationDetails(applicationData);
      setApplicationStatus(applicationData.status);
      setUserDetail(userDetail);
      setLoading(1);
    } catch (error) {
      console.log('Error fetching application details', error);
      navigate('/page-error-404');
      setLoading(2);
    }
  };

  const sendReAttemptRequest = async () => {
    try {
      setShowApplicationReattemptLoaderFlag(true);
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/job/${jobId}/application/${appId}/reattempt`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      const body = await response.json();
      if (response.ok) {
        toast.success('Application submitted for reattempt.');
      } else {
        toast.error('Something went wrong.');
      }
    } catch (error) {
      console.log('Error while trying to reattempt application', error);
    } finally {
      setShowApplicationReattemptLoaderFlag(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 940);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const calculateWidth = () => {
    if (isMobile) {
      return '100%';
    }
    return '65%';
  };

  const divStyle = {
    width: calculateWidth(),
    padding: isMobile ? '0' : 'initial',
    margin: isMobile ? '0' : 'initial'
  };

  const colStyle = {
    padding: isMobile ? '0' : 'initial',
    margin: isMobile ? '0' : 'initial',
    borderRadius: '0.5rem'
  };

  useEffect(() => {
    fetchJobDetails();
    fetchApplicationDetails();
  }, [jobId, appId]);

  const formatStringForFrontend = (str) => {
    if (!str) {
      return 'N/A';
    }
    const words = str.split('_');

    const formatWord = (word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    };

    const formattedWords = words.map((word) => {
      if (word.includes(' ')) {
        return word
          .split(' ')
          .map((w) => {
            return formatWord(w);
          })
          .join(' ');
      } else {
        return formatWord(word);
      }
    });

    return formattedWords.join(' ');
  };

  const timeAgo = (pastDate) => {
    if (!pastDate) {
      return 'N/A';
    }
    const currentDate = new Date();
    const pastDateTime = new Date(pastDate);

    const diffInMs = currentDate - pastDateTime;

    const seconds = Math.floor(diffInMs / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (years > 0) {
      return years === 1 ? '1 year ago' : `${years} years ago`;
    } else if (months > 0) {
      return months === 1 ? '1 month ago' : `${months} months ago`;
    } else if (weeks > 0) {
      return weeks === 1 ? '1 week ago' : `${weeks} weeks ago`;
    } else if (days > 0) {
      return days === 1 ? '1 day ago' : `${days} days ago`;
    } else if (hours > 0) {
      return hours === 1 ? '1 hour ago' : `${hours} hours ago`;
    } else if (minutes > 0) {
      return minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`;
    } else {
      return seconds === 1 ? '1 second ago' : `${seconds} seconds ago`;
    }
  };

  const handleUpdateApplication = () => {
    setUpdateApplicationModal(true);
    setApplicationReason('');
  };

  const handleViewResume = async () => {
    try {
      const objectKey = applicationDetails.resume;
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/v1/media/signed-url`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          body: JSON.stringify({ objectKey })
        }
      );

      const data = await response.json();
      if (response.ok && data.data.signedUrl) {
        setResumeLink(data.data.signedUrl);
        setIsResumeModalOpen(true);
      } else {
        console.error('Fetch error: ', data.message);
        throw new Error(data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const openLoader = () => {
    const loaderEvent = new CustomEvent('toggleLoader', {
      detail: { action: 'open', title: 'Loading your PDF....' }
    });
    window.dispatchEvent(loaderEvent);
  };

  const closeLoader = () => {
    const loaderEvent = new CustomEvent('toggleLoader', {
      detail: { action: 'close' }
    });
    window.dispatchEvent(loaderEvent);
  };

  const handlePDFDownload = async () => {
    try {
      setShowPDFDownloadLoaderFlag(true);
      const element = pdfContentRef.current;
      openLoader();

      // Step 1: Store the current state of open sections
      const previousOpenSections = openSections;

      // Step 2: Open the specific sections temporarily
      setOpenSections(['skills', 'resume', 'status']);
      // Step 3: Modify the style directly on the ref (add padding)
      element.style.padding = '30px'; // Example of adding padding

      const options = {
        filename: 'Candidate_Analysis.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: {
          scale: 2,
          logging: true,
          dpi: 192,
          letterRendering: true
        },
        jsPDF: { unit: 'mm', format: [400, 397], orientation: 'portrait' },
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
      };
      await html2pdf().set(options).from(element).save();
      // Step 4: Reset the style after generating PDF
      element.style.padding = ''; // Remove the padding after PDF generation
      setOpenSections(previousOpenSections);
      toast.success('PDF generated successfully!');
    } catch (error) {
      console.error(error);
    } finally {
      closeLoader();
      setShowPDFDownloadLoaderFlag(false);
    }
  };

  const updateApplication = async () => {
    try {
      if (!applicationStatus) {
        toast.error('Please select an application status');
        return;
      }

      if (!applicationReason) {
        toast.error('Please provide a reason for rejection');
        return;
      }

      setIsSubmitting(true);
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/job/${jobId}/application/${appId}/status`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          body: JSON.stringify({
            status: applicationStatus,
            reason: applicationReason
          })
        }
      );

      console.log('response', response);

      if (!response.ok) {
        throw new Error(response.message);
      }
      setIsSubmitting(false);
      setUpdateApplicationModal(false);
      setApplicationReason('');
      fetchApplicationDetails();
      toast.success('Application status updated successfully!');
    } catch (error) {
      setIsSubmitting(false);
      console.error(error);
      toast.error('Failed to update application status');
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => changePage(-1);

  const nextPage = () => changePage(1);

  useEffect(() => {
    if (isResumeModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isResumeModalOpen]);

  function getProgressBarClass(profileMatchPercentage) {
    if (profileMatchPercentage <= 50) {
      return 'bg-danger';
    } else if (profileMatchPercentage <= 75) {
      return 'bg-warning';
    } else {
      return 'bg-success';
    }
  }

  const renderExperienceField = (min, max) => {
    if (min || min === 0) {
      if (max) {
        return `${min} - ${max} Years`;
      } else {
        return `${min} Years`;
      }
    } else {
      return '';
    }
  };
  const renderExperienceLabel = (min, max) => {
    if (min || min === 0) {
      if (max) {
        return 'Experience';
      } else {
        return 'Minimum Experience';
      }
    } else {
      return '';
    }
  };

  const ratingColorMap = {
    BEGINNER: 'bg-warning',
    BASIC: 'bg-secondary',
    INTERMEDIATE: 'bg-primary',
    ADVANCED: 'bg-info',
    EXPERT: 'bg-success'
  };

  return (
    <>
      <style>
        {`
        .modal-dialog{
           max-width: max-content !important;
        }

        .accordion-button:focus {
          z-index: auto;
          border-color: var(--headerbg);
        }
        tr:last-child{
          border-bottom:0px !important;
        }
        `}
      </style>
      <div
        className={`d-flex mb-4 flex-wrap gap-3 ${
          isMobile
            ? 'flex-column align-items-center'
            : 'justify-content-between align-items-center'
        }`}
      >
        <h3
          className="m-0"
          style={{
            fontSize: '1.875rem',
            lineHeight: '2.25rem',
            color: '#1f2937',
            fontWeight: '700'
          }}
        >
          Job Application View
        </h3>

        <div className={`d-flex ${isMobile ? 'flex-row gap-2' : 'gap-3'}`}>
          {applicationDetails && applicationDetails.status === 'REJECTED' && (
            <Button
            variant="outline-primary"
            disabled={showApplicationReattemptLoader}
            style={{
              width: isMobile ? 'auto' : '210px',
              backgroundColor: showApplicationReattemptLoader ? 'var(--primary-hover)' : '' 
            }}
            onClick={sendReAttemptRequest}
            className="d-flex justify-content-center align-items-center gap-2"
          >
            {showApplicationReattemptLoader ? (
              <>
                Updating..{' '}
                <Spinner style={{ width: '1rem', height: '1rem' }} />
              </>
            ) : (
              <>
                Allow Reattempt <BsArrowClockwise className="text-primary" />
              </>
            )}
          </Button>
          )}
          {applicationDetails && <Button
            variant="primary"
            disabled={showPDFDownloadLoader}
            style={isMobile ? { width: 'auto' } : { width: '190px' }}
            onClick={handlePDFDownload}
            className="d-flex justify-content-center align-items-center gap-2"
          >
            {showPDFDownloadLoader ? (
              <>
                Downloading{' '}
                <Spinner style={{ width: '1rem', height: '1rem' }} />
              </>
            ) : (
              <>
                Download PDF <BsDownload />
              </>
            )}
          </Button>}

          { applicationDetails && applicationDetails?.status !== 'REJECTED' && (
            <Button
              variant="primary"
              style={isMobile ? { width: 'auto' } : {}}
              onClick={handleUpdateApplication}
              className="d-flex justify-content-center align-items-center gap-2"
            >
              Update Application <TbEdit />
            </Button>
          )}
        </div>
      </div>
      {loading === 0 && (
        <div className="d-flex flex-column align-items-center justify-content-center gap-5 mt-5 pt-5">
          <div className="mt-5 mb-5 pt-5 pb-4">
            <div className="mt-2"></div>
          </div>
          <Spinner animation="border mb-3" style={{ color: '#858585' }} />
        </div>
      )}
      {loading === 2 && (
        <Alert variant="danger">Failed to load data. Please try again.</Alert>
      )}
      {loading === 1 && jobDetails && applicationDetails && (
        <>
          <div class="container py-8" style={{ maxWidth: 'fit-content' }}>
            <div ref={pdfContentRef}>
              {/* <!-- Applicant Overview --> */}
              <div
                style={{
                  backgroundColor: 'white',
                  borderRadius: '1rem',
                  boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
                  // padding: '24px',
                  marginBottom: '1.5rem',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  position: 'relative',
                  // maxWidth: '600px',
                  // margin: '0 auto',
                  overflow: 'hidden'
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    inset: 0,
                    background: 'linear-gradient(to right, #4299e1, #a855f7)',
                    opacity: 0.2
                  }}
                  className="shadow-xl"
                ></div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    // borderRadius:"1rem",
                    padding: isMobile ? '20px' : '2rem',
                    width: '100%',
                    justifyContent: isMobile ? 'center' : 'space-between',
                    flexDirection: isMobile ? 'column' : 'row'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: isMobile ? 'column' : 'row',
                      gap: '2rem'
                    }}
                  >
                    <div
                      style={{
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <div
                        style={{
                          width: '100px',
                          height: '100px',
                          borderRadius: '50%',
                          overflow: 'hidden',
                          border: '4px solid white',
                          boxShadow: '0 5px 15px rgba(0, 0, 0, 0.1)',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <img
                          src={avatarImg}
                          alt="Applicant Photo"
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover'
                          }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        position: 'relative',
                        textAlign: 'center',
                        display: 'flex',
                        gap: '1rem',
                        flexDirection: 'column',
                        justifyContent: 'center'
                      }}
                    >
                      <h2
                        style={{
                          fontSize: '1.75rem',
                          fontWeight: 'bold',
                          display: 'flex',
                          justifyContent: isMobile ? 'center' : 'left',
                          color: '#2d3748'
                        }}
                      >
                        {userDetail.name ||
                          applicationDetails.resumeAnalysis.personal_info
                            .fullName ||
                          applicationDetails.resumeAnalysis.fullName ||
                          'N/A'}
                      </h2>
                      <div
                        style={{
                          display: 'flex',
                          overflowWrap: isMobile ? 'anywhere' : '',
                          flexDirection: isMobile ? 'column' : 'row',
                          width: isMobile ? '100%' : '',
                          justifyContent: isMobile ? 'center' : ' '
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            // flexDirection:"column",
                            alignItems: 'center',
                            color: 'black',
                            fontSize: '1.125rem',
                            width: isMobile ? '100%' : '',
                            gap: '.5rem',
                            // marginBottom: "8px",
                            marginRight: '1rem',
                            marginBottom: isMobile ? '0.5rem' : '0rem'
                          }}
                        >
<BsEnvelopeFill 
  style={{ minHeight: "18px", minWidth: "18px" }} 
  className="mr-1 text-primary h4 m-0" 
/>
                          {userDetail.email ||
                            applicationDetails.resumeAnalysis.personal_info
                              .email ||
                            applicationDetails.resumeAnalysis.email}
                        </div>
                        <div
                          style={{
                            color: 'black',
                            display: 'flex',
                            alignItems: 'center',
                            // flexDirection:"column",
                            fontSize: '1.125rem',
                            marginRight: '1rem',
                            gap: '.5rem',
                            marginBottom: isMobile ? '0.5rem' : '0rem'
                            // marginBottom: "8px",
                          }}
                        >
                          <FaPhoneAlt   style={{ minHeight: "18px", minWidth: "18px" }} 
className=" text-primary h4 m-0" />
                          {userDetail.phone ||
                            applicationDetails.resumeAnalysis?.phoneNumber ||
                            'N/A'}
                        </div>
                        <div
                          style={{
                            color: 'black',
                            display: 'flex',
                            alignItems: isMobile ? 'flex-start' : 'center', // Aligns icon and text to the top on mobile
                            fontSize: '1.125rem',
                            width: isMobile ? '90%' : 'max-content',
                            marginBottom: isMobile ? '0.5rem' : '0rem',
                            wordBreak: 'break-word', // Handles long words
                            maxWidth: '100%', // Ensures the div is responsive
                            textAlign: 'left',
                            gap: '.5rem',
                            maxWidth: '400px'
                            // whiteSpace: "nowrap",
                            // textOverflow: "ellipsis",
                            // overflow: "hidden",
                          }}
                        >
                          <FaMapMarkedAlt   style={{ minHeight: "18px", minWidth: "18px" }} 
className=" text-primary h4 mb-0" />
                          {(
                            userDetail.address ||
                            applicationDetails.resumeAnalysis.address
                          )
                            .replace(/\s+/g, ' ') // Removes extra spaces
                            .replace(/([a-zA-Z])(\d)/g, '$1 $2') // Adds space between letters and numbers
                            .replace(/(\d)([a-zA-Z])/g, '$1 $2') // Adds space between numbers and letters
                            .replace(/,([^\s])/g, ', $1') // Adds space after commas
                            .replace(/(\d{5})([^\d])/g, '$1 $2') // Adds space after postal code
                            .trim() || 'N/A'}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      backgroundColor: (() => {
                        const percentage =
                          (applicationDetails.finalCandidateAnalysis &&
                            applicationDetails.finalCandidateAnalysis
                              .profileMatchPercentage) ||
                          (applicationDetails.candidateAnalysis &&
                            applicationDetails.candidateAnalysis
                              .profileMatchPercentage) ||
                          0;

                        if (percentage <= 40) {
                          return '#f56565';
                        } // red
                        if (percentage <= 70) {
                          return '#ed8936';
                        } // orange
                        return 'rgb(34 197 94)'; // green
                      })(),
                      color: 'white',
                      fontWeight: 'bold',
                      fontSize: '2rem',
                      color: 'white',
                      borderRadius: '50%',
                      width: '90px',
                      height: '90px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      boxShadow: '0 5px 15px rgba(0, 0, 0, 0.1)'
                    }}
                  >
                    {(applicationDetails.finalCandidateAnalysis &&
                      applicationDetails.finalCandidateAnalysis
                        .profileMatchPercentage) ||
                      (applicationDetails.candidateAnalysis &&
                        applicationDetails.candidateAnalysis
                          .profileMatchPercentage) ||
                      'N/A'}
                    %
                  </div>
                </div>
              </div>

              {/* <!-- Application Details --> */}
              <div
                style={{
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row'
                }}
              >
                <div class="" style={{ width: isMobile ? '100%' : '70%' }}>
                  {/* <!-- Left Column --> */}
                  <div class="lg:col-span-2">
                    {/* <!-- Description Section --> */}

                    <div
                      class="bg-white rounded-xl shadow-xl  mb-8 relative overflow-hidden"
                      style={{
                        borderRadius: '1rem',
                        marginBottom: '1.5rem',
                        padding: isMobile ? '2.2rem' : '2rem',
                        boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)'
                      }}
                    >
                      <div class="relative z-10">
                        <h3
                          class=" font-bold text-gray-800 mb-6"
                          style={{
                            fontSize: '1.875rem',
                            lineHeight: '2.25rem',
                            color: '#1f2937',
                            fontWeight: '700',
                            marginBottom: '1.5rem'
                          }}
                        >
                          Description
                        </h3>
                        <div className="w-full">
                          <div className="d-flex flex-column">
                            {applicationDetails.resumeAnalysis
                              ?.workExperience[0]?.role && (
                              <div className="d-flex border-bottom gap-3">
                                <div
                                  className="text-left py-3 text-gray-600 text-lg font-weight-bold d-flex justify-content-start align-items-center"
                                  style={{
                                    color: '#4b5563',
                                    fontSize: '1.125rem',
                                    lineHeight: '1.75rem',
                                    width: '50%',
                                    padding: '1rem 0'
                                  }}
                                >
                                  Current Designation
                                </div>
                                <div
                                  className="text-gray-800 text-lg w-50 d-flex justify-content-start align-items-center py-3"
                                  style={{
                                    fontSize: '1.125rem',
                                    lineHeight: '1.75rem',
                                    color: '#1f2937'
                                  }}
                                >
                                  {applicationDetails.resumeAnalysis
                                    ?.workExperience[0]?.role || 'N/A'}
                                </div>
                              </div>
                            )}

                            {applicationDetails.resumeAnalysis
                              ?.workExperience[0]?.company && (
                              <div className="d-flex border-bottom gap-3">
                                <div
                                  className="text-left py-3 text-gray-600 text-lg font-weight-bold d-flex justify-content-start align-items-center"
                                  style={{
                                    color: '#4b5563',
                                    fontSize: '1.125rem',
                                    lineHeight: '1.75rem',
                                    width: '50%',
                                    padding: '1rem 0'
                                  }}
                                >
                                  Current Company
                                </div>
                                <div
                                  className="text-gray-800 text-lg w-50 d-flex justify-content-start align-items-center py-3"
                                  style={{
                                    fontSize: '1.125rem',
                                    lineHeight: '1.75rem',
                                    color: '#1f2937'
                                  }}
                                >
                                  {applicationDetails.resumeAnalysis
                                    ?.workExperience[0]?.company || 'N/A'}
                                </div>
                              </div>
                            )}

                            <div className="d-flex border-bottom gap-3">
                              <div
                                className="text-left py-3 text-gray-600 text-lg font-weight-bold d-flex justify-content-start align-items-center"
                                style={{
                                  color: '#4b5563',
                                  fontSize: '1.125rem',
                                  lineHeight: '1.75rem',
                                  width: '50%',
                                  padding: '1rem 0'
                                }}
                              >
                                Total Experience
                              </div>
                              <div
                                className="text-gray-800 text-lg w-50 d-flex justify-content-start align-items-center py-3"
                                style={{
                                  fontSize: '1.125rem',
                                  lineHeight: '1.75rem',
                                  color: '#1f2937'
                                }}
                              >
                                {userDetail.experience ||
                                  applicationDetails.resumeAnalysis
                                    ?.totalExperience ||
                                  'N/A'}
                                {userDetail.experience ||
                                applicationDetails.resumeAnalysis
                                  ?.totalExperience
                                  ? ' Years'
                                  : ''}
                              </div>
                            </div>

                            {userDetail.currentCTC && (
                              <div className="d-flex border-bottom gap-3">
                                <div
                                  className="text-left py-3 text-gray-600 text-lg font-weight-bold d-flex justify-content-start align-items-center"
                                  style={{
                                    color: '#4b5563',
                                    fontSize: '1.125rem',
                                    lineHeight: '1.75rem',
                                    width: '50%',
                                    padding: '1rem 0'
                                  }}
                                >
                                  Current CTC ({storedCurrency})
                                </div>
                                <div
                                  className="text-gray-800 text-lg w-50 d-flex justify-content-start align-items-center py-3"
                                  style={{
                                    fontSize: '1.125rem',
                                    lineHeight: '1.75rem',
                                    color: '#1f2937'
                                  }}
                                >
                                  {formatToCurrency(userDetail.currentCTC) ||
                                    'N/A'}
                                </div>
                              </div>
                            )}

                            <div className="d-flex border-bottom gap-3">
                              <div
                                className="text-left py-3 text-gray-600 text-lg font-weight-bold d-flex justify-content-start align-items-center"
                                style={{
                                  color: '#4b5563',
                                  fontSize: '1.125rem',
                                  lineHeight: '1.75rem',
                                  width: '50%',
                                  padding: '1rem 0'
                                }}
                              >
                                Application Status
                              </div>
                              <div
                                className="text-gray-800 text-lg w-50 d-flex justify-content-start align-items-center py-3"
                                style={{
                                  fontSize: '1.125rem',
                                  lineHeight: '1.75rem',
                                  color: '#1f2937'
                                }}
                              >
                                {formatStringForFrontend(
                                  applicationDetails.status
                                )}
                              </div>
                            </div>

                            {applicationDetails.applicationDate && (
                              <div className="d-flex border-bottom gap-3">
                                <div
                                  className="text-left py-3 text-gray-600 text-lg font-weight-bold d-flex justify-content-start align-items-center"
                                  style={{
                                    color: '#4b5563',
                                    fontSize: '1.125rem',
                                    lineHeight: '1.75rem',
                                    width: '50%',
                                    padding: '1rem 0'
                                  }}
                                >
                                  Application Date
                                </div>
                                <div
                                  className="text-gray-800 text-lg w-50 d-flex justify-content-start align-items-center py-3"
                                  style={{
                                    fontSize: '1.125rem',
                                    lineHeight: '1.75rem',
                                    color: '#1f2937'
                                  }}
                                >
                                  {applicationDetails.applicationDate
                                    ? new Intl.DateTimeFormat('en-GB', {
                                        day: 'numeric',
                                        month: 'long',
                                        year: 'numeric'
                                      }).format(
                                        new Date(
                                          applicationDetails.applicationDate
                                        )
                                      )
                                    : 'N/A'}
                                </div>
                              </div>
                            )}

                            <div className="d-flex border-bottom gap-3">
                              <div
                                className="text-left py-3 text-gray-600 text-lg font-weight-bold d-flex justify-content-start align-items-center"
                                style={{
                                  color: '#4b5563',
                                  fontSize: '1.125rem',
                                  lineHeight: '1.75rem',
                                  width: '50%',
                                  padding: '1rem 0'
                                }}
                              >
                                Date of Birth
                              </div>
                              <div
                                className="text-gray-800 text-lg w-50 d-flex justify-content-start align-items-center py-3"
                                style={{
                                  fontSize: '1.125rem',
                                  lineHeight: '1.75rem',
                                  color: '#1f2937'
                                }}
                              >
                                {userDetail.dob
                                  ? new Intl.DateTimeFormat('en-GB', {
                                      day: 'numeric',
                                      month: 'long',
                                      year: 'numeric'
                                    }).format(new Date(userDetail.dob))
                                  : 'N/A'}
                              </div>
                            </div>

                            <div className="d-flex border-bottom gap-3">
                              <div
                                className="text-left py-3 text-gray-600 text-lg font-weight-bold d-flex justify-content-start align-items-center"
                                style={{
                                  color: '#4b5563',
                                  fontSize: '1.125rem',
                                  lineHeight: '1.75rem',
                                  width: '50%',
                                  padding: '1rem 0'
                                }}
                              >
                                Highest Qualification
                              </div>
                              <div
                                className="text-gray-800 text-lg w-50 d-flex justify-content-start align-items-center py-3"
                                style={{
                                  fontSize: '1.125rem',
                                  lineHeight: '1.75rem',
                                  color: '#1f2937'
                                }}
                              >
                                {applicationDetails.resumeAnalysis
                                  ?.highestEducation || 'N/A'}
                              </div>
                            </div>

                            <div className="d-flex border-bottom gap-3">
                              <div
                                className="text-left py-3 text-gray-600 text-lg font-weight-bold d-flex justify-content-start align-items-center"
                                style={{
                                  color: '#4b5563',
                                  fontSize: '1.125rem',
                                  lineHeight: '1.75rem',
                                  width: '50%',
                                  padding: '1rem 0'
                                }}
                              >
                                University
                              </div>
                              <div
                                className="text-gray-800 text-lg w-50 d-flex justify-content-start align-items-center py-3"
                                style={{
                                  fontSize: '1.125rem',
                                  lineHeight: '1.75rem',
                                  color: '#1f2937'
                                }}
                              >
                                {applicationDetails.resumeAnalysis
                                  ?.university || 'N/A'}
                              </div>
                            </div>

                            <div className="d-flex border-bottom gap-3">
                              <div
                                className="text-left py-3 text-gray-600 text-lg font-weight-bold d-flex justify-content-start align-items-center"
                                style={{
                                  color: '#4b5563',
                                  fontSize: '1.125rem',
                                  lineHeight: '1.75rem',
                                  width: '50%',
                                  padding: '1rem 0'
                                }}
                              >
                                Technical Skills
                              </div>
                              <div className="d-flex flex-wrap gap-2 w-50 py-3">
                                {jobDetails.skills.map((skill) => (
                                  <span
                                    key={skill}
                                    title={skill || 'N/A'}
                                    style={{
                                      backgroundColor: '#d4edda',
                                      color: '#155724',
                                      padding: '0.25em 0.6em',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      borderRadius: '10rem',
                                      fontSize: '0.75rem',
                                      whiteSpace: 'nowrap',
                                      display: 'inline-block',
                                      margin: '0.25rem 0'
                                    }}
                                  >
                                    {skill || 'N/A'}
                                  </span>
                                ))}
                              </div>
                            </div>

                            {applicationDetails.resumeAnalysis
                              ?.nonTechnicalSkills.length ? (
                              <div className="d-flex gap-3">
                                <div
                                  className="text-left py-3 text-gray-600 text-lg font-weight-bold d-flex justify-content-start align-items-center"
                                  style={{
                                    color: '#4b5563',
                                    fontSize: '1.125rem',
                                    lineHeight: '1.75rem',
                                    width: '50%',
                                    padding: '1rem 0'
                                  }}
                                >
                                  Soft Skills
                                </div>
                                <div className="d-flex flex-wrap gap-2 w-50 py-3">
                                  {applicationDetails.resumeAnalysis?.nonTechnicalSkills.map(
                                    (skill) => (
                                      <span
                                        key={skill}
                                        title={skill || 'N/A'}
                                        style={{
                                          backgroundColor: '#d4edda',
                                          color: '#155724',
                                          padding: '0.25em 0.6em',
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                          borderRadius: '10rem',
                                          fontSize: '0.75rem',
                                          whiteSpace: 'nowrap',
                                          display: 'inline-block',
                                          margin: '0.25rem 0'
                                        }}
                                      >
                                        {skill || 'N/A'}
                                      </span>
                                    )
                                  )}
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    {/* <!-- Work Experience Section --> */}
                    <div
                      class="bg-white  mb-8 relative overflow-hidden"
                      style={{
                        borderRadius: '1rem',
                        marginBottom: '1.5rem',
                        padding: isMobile ? '2.2rem' : '2rem',
                        boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)'
                      }}
                    >
                      <div class="relative z-10">
                        <h3
                          class="text-2xl md:text-3xl font-bold text-gray-800 mb-6"
                          style={{
                            fontSize: '1.875rem',
                            lineHeight: '2.25rem',
                            color: '#1f2937',
                            fontWeight: '700',
                            marginBottom: '1.5rem'
                          }}
                        >
                          Work Experience
                        </h3>
                        <div class="space-y-6">
                          {applicationDetails.resumeAnalysis.workExperience.map(
                            (exp, index) => (
                              <div
                                key={index}
                                style={{
                                  backgroundColor: 'white',
                                  borderRadius: '0.5rem',
                                  padding: '1.5rem',
                                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                  border: '1px solid #e5e7eb',
                                  marginTop: '24px'
                                }}
                              >
                                <h4
                                  style={{
                                    fontSize: '1.5rem',
                                    lineHeight: '2rem',
                                    fontWeight: '600',
                                    color: '#1f2937'
                                  }}
                                >
                                  {exp.role || 'N/A'} at {exp.company || 'N/A'}
                                </h4>
                                <p
                                  style={{ color: '#4b5563', margin: '1rem 0' }}
                                >
                                  <strong>Duration:</strong>{' '}
                                  {exp.duration || 'N/A'}
                                </p>
                                <div
                                  style={{
                                    color: '#1f2937',
                                    borderRadius: '0.5rem'
                                  }}
                                >
                                  <h5
                                    style={{
                                      fontWeight: '700',
                                      fontSize: '1.125rem',
                                      lineHeight: '1.75rem'
                                    }}
                                  >
                                    Responsibilities:
                                  </h5>
                                  <ul
                                    style={{
                                      listStyleType: 'disc',
                                      paddingInlineStart: '1.5rem',
                                      marginTop: '0.5rem'
                                    }}
                                  >
                                    {exp.responsibilities.map(
                                      (responsibility, respIndex) => (
                                        <li
                                          key={respIndex}
                                          style={{
                                            marginBottom: '0.5rem',
                                            listStyleType: 'disc'
                                          }}
                                        >
                                          {responsibility}
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                    {/* <!-- Final Candidate Analysis --> */}
                    <div
                      class="bg-white mb-8 relative overflow-hidden"
                      style={{
                        borderRadius: '1rem',
                        marginBottom: '1.5rem',
                        padding: isMobile ? '2.2rem' : '2rem',
                        boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)'
                      }}
                    >
                      <div class="relative z-10">
                        <h3
                          class="text-2xl md:text-3xl font-bold text-gray-800 mb-6"
                          style={{
                            fontSize: '1.875rem',
                            lineHeight: '2.25rem',
                            color: '#1f2937',
                            fontWeight: '700',
                            marginBottom: '1rem'
                          }}
                        >
                          {applicationDetails.finalCandidateAnalysis
                            ? 'Final Candidate Analysis'
                            : applicationDetails.candidateAnalysis
                              ? 'Candidate Analysis'
                              : 'Analysis'}
                        </h3>
                        <div class="space-y-8">
                          {applicationDetails.finalCandidateAnalysis ? (
                            <>
                              <div
                                style={{
                                  marginBottom: '1rem',
                                  padding: '1rem',
                                  backgroundColor: '#f0fdf4', // Equivalent to bg-green-50
                                  borderLeft: '4px solid #22c55e', // Equivalent to border-green-500
                                  borderRadius: '0.5rem',
                                  boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)' // Equivalent to shadow-sm
                                }}
                              >
                                <h4
                                  style={{
                                    fontSize: '1.25rem',
                                    lineHeight: '1.75rem',
                                    fontWeight: '700',
                                    color: '#15803d'
                                  }}
                                >
                                  Pros
                                </h4>
                                <ul
                                  style={{
                                    listStyleType: 'disc',
                                    padding: '1rem',
                                    listStylePosition: 'inside',
                                    color: '#1f2937'
                                  }}
                                >
                                  {applicationDetails.finalCandidateAnalysis.pros.map(
                                    (pro, index) => (
                                      <li
                                        key={index}
                                        style={{
                                          marginBottom: '1rem',
                                          listStyleType: 'disc',
                                          color: '#1f2937'
                                        }}
                                      >
                                        {pro || 'N/A'}
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                              <div
                                style={{
                                  padding: '1rem',
                                  backgroundColor: '#fef2f2',
                                  borderLeft: '4px solid #ef4444',
                                  borderRadius: '0.5rem',
                                  boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)'
                                }}
                              >
                                <h4
                                  style={{
                                    fontSize: '1.25rem',
                                    fontWeight: 'bold',
                                    color: '#b91c1c' // Equivalent to text-red-700
                                    // marginBottom: "1rem",
                                  }}
                                >
                                  Cons
                                </h4>
                                <ul
                                  style={{
                                    listStyleType: 'disc',
                                    listStylePosition: 'inside',
                                    padding: '1rem',
                                    color: '#1f2937'
                                  }}
                                >
                                  {applicationDetails.finalCandidateAnalysis.cons.map(
                                    (con, index) => (
                                      <li
                                        key={index}
                                        style={{
                                          marginBottom: '1rem',
                                          color: '##1f2937',
                                          listStyleType: 'disc'
                                        }}
                                      >
                                        {con || 'N/A'}
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            </>
                          ) : applicationDetails.candidateAnalysis ? (
                            <>
                              <div
                                class="p-4 bg-green-50 border-l-4 border-green-500 rounded-lg shadow-sm"
                                style={{
                                  marginBottom: '1rem',
                                  padding: '1rem',
                                  backgroundColor: '#f0fdf4',
                                  borderLeft: '4px solid #22c55e',
                                  borderRadius: '1rem',
                                  boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)'
                                }}
                              >
                                <h4
                                  class="text-xl font-bold text-green-700 "
                                  style={{
                                    marginBottom: '0.5rem',
                                    fontSize: '1.25rem',
                                    lineHeight: '1.75rem',
                                    fontWeight: '700',
                                    color: '#15803d'
                                  }}
                                >
                                  Pros
                                </h4>
                                <ul
                                  class="list-disc list-inside text-gray-800 space-y-2"
                                  style={{
                                    listStyleType: 'disc',
                                    padding: '1rem',
                                    listStylePosition: 'inside',
                                    color: '#1f2937'
                                  }}
                                >
                                  {applicationDetails.candidateAnalysis.pros.map(
                                    (pro, index) => (
                                      <li
                                        key={index}
                                        style={{
                                          marginBottom: '0.5rem',
                                          listStyleType: 'disc',
                                          color: '#1f2937'
                                        }}
                                      >
                                        {pro || 'N/A'}
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                              <div
                                class="p-4 bg-red-50 border-l-4 border-red-500 rounded-lg shadow-sm"
                                style={{
                                  padding: '1rem',
                                  backgroundColor: '#fef2f2',
                                  borderLeft: '4px solid #ef4444',
                                  borderRadius: '0.5rem',
                                  boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)'
                                }}
                              >
                                <h4
                                  class="text-xl font-bold text-red-700 mb-4"
                                  style={{
                                    fontSize: '1.25rem',
                                    fontWeight: 'bold',
                                    color: '#b91c1c'
                                  }}
                                >
                                  Cons
                                </h4>
                                <ul
                                  class="list-disc list-inside text-gray-800 space-y-2"
                                  style={{
                                    listStyleType: 'disc',
                                    listStylePosition: 'inside',
                                    padding: '1rem',
                                    color: '#1f2937'
                                  }}
                                >
                                  {applicationDetails.candidateAnalysis.cons.map(
                                    (con, index) => (
                                      <li
                                        key={index}
                                        style={{
                                          marginBottom: '0.5rem',
                                          color: '#1f2937',
                                          listStyleType: 'disc'
                                        }}
                                      >
                                        {con || 'N/A'}
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            </>
                          ) : (
                            <p>No analysis available</p>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* <!-- Education Section --> */}
                    <div
                      class="bg-white p-6 mb-8"
                      style={{
                        marginBottom: '1.5rem',
                        borderRadius: '1rem',
                        boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)'
                      }}
                    >
                      <h3
                        class="text-xl font-bold text-gray-800 mb-4"
                        style={{
                          fontSize: '1.875rem',
                          lineHeight: '2.25rem',
                          color: '#1f2937',
                          fontWeight: '700',
                          marginBottom: '1.5rem'
                        }}
                      >
                        Education
                      </h3>
                      <div class="space-y-4">
                        {applicationDetails.resumeAnalysis.education.map(
                          (edu, index) => (
                            <div
                              class="border hover:shadow-lg transition-shadow"
                              onMouseEnter={() => setIsHovered(true)}
                              onMouseLeave={() => setIsHovered(false)}
                              style={{
                                marginTop: '1rem',
                                borderRadius: '0.5rem',
                                padding: '1rem',
                                border: '1px solid #e5e7eb', // Equivalent to border class
                                transition: 'box-shadow 0.3s ease-in-out',
                                boxShadow: isHovered
                                  ? '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)'
                                  : 'none'
                              }}
                            >
                              <p class="text-gray-800">
                                <p
                                  style={{
                                    fontWeight: 'bolder',
                                    color: '#1f2937'
                                  }}
                                >
                                  {edu.degree}
                                </p>
                              </p>
                              <p class="text-gray-600">
                                {edu.institution || 'N/A'} (
                                {edu.attended || 'N/A'})
                              </p>
                            </div>
                          )
                        )}

                        {applicationDetails.resumeAnalysis &&
                          applicationDetails.resumeAnalysis.otherDetails &&
                          Object.keys(
                            applicationDetails.resumeAnalysis.otherDetails
                          ).length > 0 && (
                            <div class="border p-4 rounded-lg hover:shadow-lg transition-shadow">
                              {Object.entries(
                                applicationDetails.resumeAnalysis.otherDetails
                              ).map(([key, value], index) => (
                                <p key={index} className="text-gray-600">
                                  {key.charAt(0).toUpperCase() + key.slice(1)}:
                                  {key === 'linkedIn' ||
                                  key === 'github' ||
                                  key === 'portfolio' ? (
                                    <a
                                      href={`https://${value}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {value || 'N/A'}
                                    </a>
                                  ) : (
                                    ` ${value || 'N/A'}`
                                  )}
                                </p>
                              ))}
                            </div>
                          )}
                      </div>
                    </div>

                    {/* <!-- Accordions Section --> */}
                    {(applicationDetails.skillRatings ||
                      applicationDetails.resumeAnswers ||
                      applicationDetails.statusHistory) && (
                      <div
                        className="bg-white p-6 mb-8"
                        style={{
                          borderRadius: '1rem',
                          boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
                          marginBottom: '1.5rem',
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '1rem'
                        }}
                      >
                        {/* Skills Rating Section */}
                        {/* {applicationDetails.skillRatings && (
                        <div style={{ width: "100%" }}>
                          <button
                            onClick={() => toggleSection("skills")}
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              textAlign: "left",
                              backgroundColor: "#dbeafe",
                              padding: "1rem",
                              fontSize: "1.25rem",
                              fontWeight: "bold",
                              color: "#1f2937",
                              border: "none",
                              borderRadius: "0.5rem 0.5rem 0 0",
                              cursor: "pointer",
                            }}
                          >
                            Skills Rating
                            {openSections.includes("skills") ? (
                              <MdClose />
                            ) : (
                              <MdKeyboardArrowDown className="ml-2" />
                            )}
                          </button>
                          {openSections.includes("skills") && (
                            <div
                              style={{
                                backgroundColor: "#f9fafb", // Light gray background color
                                padding: "1rem",
                                borderRadius: "0 0 8px 8px", // Rounded bottom corners
                              }}
                            >
                              <div
                                className="grid grid-cols-1 md:grid-cols-2"
                                style={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: "5%",
                                }}
                              >
                                {applicationDetails.skillRatings.map(
                                  (skill, index) => (
                                    <div
                                      key={index}
                                      className="flex items-center justify-between bg-white"
                                      style={{
                                        width: "45%",
                                        display: "flex",
                                        padding: isMobile ? "1rem" : "1.5rem",
                                        borderRadius:"0.5rem",
                                        justifyContent: isMobile
                                          ? "center"
                                          : "space-between",
                                        marginBottom: "0.5rem",
                                        marginTop: "1%",
                                        flexDirection: isMobile
                                          ? "column"
                                          : "row",
                                      }}
                                    >
                                      <span className="font-bold text-gray-800">
                                        {skill.skill || "N/A"}
                                      </span>
                                      <span
                                        className={`text-white text-sm rounded-full px-3 py-1 ${
                                          ratingColorMap[skill.rating] ||
                                          "bg-secondary"
                                        }`}
                                        style={{
                                          width: "fit-content",
                                          height: "fit-content",
                                          marginTop: isMobile ? "1rem" : "0",
                                          borderRadius: "0.5rem",
                                        }}
                                      >
                                        {formatStringForFrontend(skill.rating)}
                                      </span>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      )} */}
                        {applicationDetails.skillRatings && (
                          <div style={{ width: '100%' }}>
                            <button
                              onClick={() => toggleSection('skills')}
                              style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                textAlign: 'left',
                                backgroundColor: '#dbeafe',
                                padding: '1rem',
                                fontSize: '1.25rem',
                                fontWeight: 'bold',
                                color: '#1f2937',
                                border: 'none',
                                borderRadius: !openSections.includes('skills')
                                  ? '0.5rem'
                                  : '0.5rem 0.5rem 0 0',
                                cursor: 'pointer'
                              }}
                            >
                              Skills Rating
                              {openSections.includes('skills') ? (
                                <MdClose />
                              ) : (
                                <MdKeyboardArrowDown className="ml-2" />
                              )}
                            </button>
                            {openSections.includes('skills') && (
                              <div
                                style={{
                                  backgroundColor: '#f9fafb',
                                  padding: '1rem',
                                  borderRadius: '0 0 8px 8px'
                                }}
                              >
                                <div
                                  className="row"
                                  style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: '5%'
                                  }}
                                >
                                  {applicationDetails.skillRatings.map(
                                    (skill, index) => (
                                      <div
                                        key={index}
                                        className="col-md-6 mb-3"
                                        style={{
                                          backgroundColor: '#fff',
                                          margin: '0 2.5%',
                                          width: '95%',
                                          padding: '1.5rem',
                                          borderRadius: '0.5rem',
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          alignItems: 'center',
                                          marginBottom: '0.5rem'
                                        }}
                                      >
                                        <Rating
                                          disableEvents={true}
                                          skill={skill.skill}
                                          rating={skill.rating}
                                          index={index + 1}
                                          onRatingChange={() => {}}
                                        />
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        )}

                        {/* Resume Answers Section */}
                        {applicationDetails.resumeAnswers && (
                          <div>
                            <button
                              onClick={() => toggleSection('resume')}
                              style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                textAlign: 'left',
                                backgroundColor: '#dbeafe', // Light blue background color
                                padding: '1rem',
                                fontSize: '1.25rem',
                                fontWeight: 'bold',
                                color: '#1f2937', // Darker text color
                                border: 'none',
                                borderRadius: !openSections.includes('resume')
                                  ? '0.5rem'
                                  : '0.5rem 0.5rem 0 0',
                                cursor: 'pointer'
                              }}
                            >
                              Resume Answers
                              {openSections.includes('resume') ? (
                                <MdClose />
                              ) : (
                                <MdKeyboardArrowDown className="ml-2" />
                              )}
                            </button>
                            {openSections.includes('resume') && (
                              <div
                                style={{
                                  backgroundColor: '#f9fafb', // Light gray background color
                                  padding: '1rem',
                                  borderRadius: '0 0 8px 8px' // Rounded bottom corners
                                }}
                              >
                                <div className="space-y-4">
                                  {applicationDetails.resumeAnswers.map(
                                    (qa, index) => (
                                      <div key={index}>
                                        <h4 className="font-bold text-gray-800">
                                          Q: {qa.question || 'N/A'}
                                        </h4>
                                        <p className="text-gray-600">
                                          A: {qa.answer || 'N/A'}
                                        </p>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        )}

                        {/* Application Status Progress Section */}
                        {applicationDetails.statusHistory && (
                          <>
                            <button
                              onClick={() => toggleSection('status')}
                              style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                textAlign: 'left',
                                backgroundColor: '#dbeafe',
                                padding: '1rem',
                                fontSize: '1.25rem',
                                fontWeight: 'bold',
                                color: '#1f2937',
                                border: 'none',
                                borderRadius: !openSections.includes('resume')
                                  ? '0.5rem'
                                  : '0.5rem 0.5rem 0 0',
                                cursor: 'pointer'
                              }}
                            >
                              Application Status Progress
                              {openSections.includes('status') ? (
                                <MdClose />
                              ) : (
                                <MdKeyboardArrowDown className="ml-2" />
                              )}
                            </button>
                            {openSections.includes('status') && (
                              <div
                                style={{
                                  backgroundColor: '#f9fafb', // Light gray background color
                                  padding: '1rem',
                                  borderRadius: '0 0 8px 8px' // Rounded bottom corners
                                }}
                              >
                                <div className="card-body d-block">
                                  <ApplicationStatusProgress
                                    statusHistory={
                                      applicationDetails.statusHistory
                                    }
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    )}

                    {/* <!-- Footer Action Bar --> */}
                  </div>
                  {!isMobile && (
                    <div
                      class="bg-white p-6 flex justify-between items-center"
                      style={{
                        borderRadius: '1rem',
                        boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
                        marginBottom: '1.5rem',
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%'
                      }}
                    >
                      <div>
                        <p class="text-gray-600">
                          <FaBuilding className="text-blue-500 mr-2" />
                          Currently Working at{' '}
                          <strong>
                            {' '}
                            {applicationDetails?.resumeAnalysis
                              ?.workExperience[0]?.company || 'N/A'}
                          </strong>
                        </p>
                        <p class="text-gray-600">
                          <FaClock className="text-blue-500 mr-2" />
                          {userDetail.experience ||
                            applicationDetails?.resumeAnalysis
                              ?.totalExperience ||
                            'N/A'}{' '}
                          Years of Working Experience
                        </p>
                      </div>
                      <div>
                        <button
                          onClick={handleViewResume}
                          class="bg-primary text-white font-bold px-6 py-2 hover:bg-primary-600 transition duration-300"
                          style={{
                            padding: '0.5rem 1.5rem',
                            borderRadius: '0.5rem',
                            border: 'none'
                          }}
                        >
                          <FaEye size={18} class="mr-2" />
                          View Resume
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    width: isMobile ? '100%' : '28%',
                    marginLeft: isMobile ? '0%' : '2%',
                    marginBottom: isMobile ? '5%' : '0%'
                  }}
                >
                  {/* <!-- Job Details Section --> */}
                  <div
                    class="bg-white md:p-8 mb-8 relative overflow-hidden"
                    style={{
                      borderRadius: '1rem',
                      padding: isMobile ? '2.2rem' : '2rem',
                      boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)'
                    }}
                  >
                    <div class="relative z-10">
                      <h3
                        class="text-2xl md:text-3xl font-bold text-gray-800 mb-6"
                        style={{
                          fontSize: '1.875rem',
                          lineHeight: '2.25rem',
                          color: '#1f2937',
                          fontWeight: '700',
                          marginBottom: '1.5rem'
                        }}
                      >
                        Job Details
                      </h3>
                      <div class="grid grid-cols-1 md:grid-cols-1 gap-8">
                        {/* <!-- Middle Section (Job Summary, Requirements, Skills, etc.) --> */}
                        <div class="space-y-6">
                          {/* <!-- Job Summary --> */}
                          <div
                            className="shadow-sm"
                            style={{
                              padding: '1rem',
                              background: '#f9fafb',
                              borderRadius: '0.5rem',
                              marginBottom: '1.5rem'
                              // border: "1px solid #E5E7EB",
                            }}
                          >
                            <h4
                              className=""
                              style={{
                                fontWeight: '600',
                                color: '#1f2937',
                                fontSize: '1.25rem',
                                lineHeight: '1.75rem'
                              }}
                            >
                              Summary
                            </h4>
                            <p
                              className="text-gray-700"
                              style={{ lineHeight: '1.5rem', color: '#374151' }}
                            >
                              {jobDetails.description}
                            </p>
                          </div>

                          {/* <!-- Job Requirements --> */}
                          <div
                            class="bg-gray-50 p-4 rounded-lg shadow-sm"
                            style={{
                              padding: '1rem',
                              background: '#f9fafb',
                              borderRadius: '0.5rem',
                              marginBottom: '1.5rem'
                              // border: "1px solid #E5E7EB",
                            }}
                          >
                            <h4
                              class=""
                              style={{
                                fontWeight: '600',
                                color: '#1f2937',
                                fontSize: '1.25rem',
                                lineHeight: '1.75rem'
                              }}
                            >
                              Requirements
                            </h4>
                            <ul
                              class="list-disc list-inside text-gray-700 space-y-2 mt-2"
                              style={{ padding: '1rem' }}
                            >
                              {jobDetails.responsibilities.map((item) => (
                                <li
                                  style={{
                                    marginBottom: '0.5rem',
                                    listStyleType: 'disc',
                                    color: '#374151'
                                  }}
                                >
                                  {item}
                                </li>
                              ))}
                            </ul>
                          </div>

                          <div
                            class="bg-gray-50 p-4 shadow-sm"
                            style={{
                              padding: '1rem',
                              background: '#f9fafb',
                              borderRadius: '0.5rem',
                              marginBottom: '1.5rem'
                              // border: "1px solid #E5E7EB",
                            }}
                          >
                            <h4
                              class=""
                              style={{
                                fontWeight: '600',
                                color: '#1f2937',
                                fontSize: '1.25rem',
                                lineHeight: '1.75rem'
                              }}
                            >
                              Qualifications
                            </h4>
                            <ul
                              class="list-disc list-inside text-gray-700 space-y-2 mt-2"
                              style={{ padding: '1rem' }}
                            >
                              {jobDetails.qualifications
                                .split('. ')
                                .map((item) => (
                                  <li
                                    style={{
                                      marginBottom: '0.5rem',
                                      listStyleType: 'disc',
                                      color: '#374151'
                                    }}
                                  >
                                    {item}
                                  </li>
                                ))}
                            </ul>
                          </div>
                          {/* <!-- Skills & Experience --> */}
                          <div
                            className="bg-gray-50 p-4 rounded-lg shadow-sm"
                            style={{
                              padding: '1rem',
                              overflow: 'hidden',
                              background: '#f9fafb',
                              borderRadius: '0.5rem',
                              marginBottom: '1.5rem',
                              border: '1px solid #E5E7EB'
                            }}
                          >
                            <h4
                              className="text-xl font-semibold text-gray-800 mb-2"
                              style={{
                                fontWeight: '600',
                                color: '#1f2937',
                                fontSize: '1.25rem',
                                lineHeight: '1.75rem'
                              }}
                            >
                              Skills & Experience
                            </h4>
                            <div
                              style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '0.5rem',
                                maxWidth: '100%'
                              }}
                            >
                              {jobDetails.skills.map((skill) => (
                                <span
                                  key={skill}
                                  style={{
                                    backgroundColor: '#d4edda',
                                    color: '#155724',
                                    padding: '0.25em 0.6em',
                                    borderRadius: '10rem',
                                    fontSize: '0.75rem',
                                    whiteSpace: 'nowrap',
                                    display: 'inline-block',
                                    margin: '0.25rem 0'
                                  }}
                                >
                                  {skill || 'N/A'}
                                </span>
                              ))}
                            </div>
                          </div>
                          {/* <!-- Job Type, Location, Experience, etc. --> */}
                          <div
                            class="bg-gray-50 p-4 rounded-lg shadow-sm"
                            style={{
                              padding: '1rem',
                              overflow: 'hidden',
                              background: '#f9fafb',
                              borderRadius: '0.5rem',
                              marginBottom: '1.5rem',
                              border: '1px solid #E5E7EB'
                            }}
                          >
                            <h4
                              class="text-xl font-semibold text-gray-800"
                              style={{
                                fontWeight: '600',
                                color: '#1f2937',
                                fontSize: '1.25rem',
                                lineHeight: '1.75rem'
                              }}
                            >
                              Job Details
                            </h4>
                            <div class="text-gray-700 space-y-2 mt-2">
                              <p style={{ color: '#374151' }}>
                                <strong style={{ color: '#374151' }}>
                                  Work Location Type:
                                </strong>{' '}
                                {formatStringForFrontend(
                                  jobDetails.workLocationType
                                )}
                              </p>
                              <p style={{ color: '#374151' }}>
                                <strong style={{ color: '#374151' }}>
                                  {renderExperienceLabel(
                                    jobDetails.minimumExperience,
                                    jobDetails.maximumExperience
                                  )}
                                  :
                                </strong>{' '}
                                {renderExperienceField(
                                  jobDetails.minimumExperience,
                                  jobDetails.maximumExperience
                                )}
                              </p>
                              <p style={{ color: '#374151' }}>
                                <strong style={{ color: '#374151' }}>
                                  Salary:
                                </strong>{' '}
                                {jobDetails.minimumSalary
                                  ? `${
                                      formatToCurrency(
                                        jobDetails.minimumSalary
                                      ) || 0
                                    } - ${
                                      formatToCurrency(
                                        jobDetails.maximumSalary
                                      ) || 0
                                    }`
                                  : jobDetails.maximumSalary
                                    ? `${currencySymbol} ${
                                        formatToCurrency(
                                          jobDetails.maximumSalary
                                        ) || 0
                                      }`
                                    : 'NA'}
                              </p>
                              <p style={{ color: '#374151' }}>
                                <strong style={{ color: '#374151' }}>
                                  Location:
                                </strong>{' '}
                                {jobDetails.location || 'N/A'}
                              </p>
                              <p style={{ color: '#374151' }}>
                                <strong style={{ color: '#374151' }}>
                                  Job Type:
                                </strong>{' '}
                                {`${formatStringForFrontend(
                                  jobDetails.jobType
                                )} (${formatStringForFrontend(
                                  jobDetails.workHours
                                )})`}
                              </p>
                              <p style={{ color: '#374151' }}>
                                <strong style={{ color: '#374151' }}>
                                  Posted Date:
                                </strong>{' '}
                                {timeAgo(jobDetails.createdAt)}
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* <!-- Right Section (Detailed Job Description - Hidden) --> */}
                        <div class="hidden"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isMobile && (
              <div
                class="bg-white p-6 flex justify-between items-center"
                style={{
                  borderRadius: '1rem',
                  boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
                  marginBottom: '1.5rem',
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: isMobile ? '100%' : '70%'
                }}
              >
                <div>
                  <p class="text-gray-600">
                    <FaBuilding className="text-blue-500 mr-2" />
                    Currently Working at{' '}
                    <strong>
                      {' '}
                      {applicationDetails?.resumeAnalysis?.workExperience[0]
                        ?.company || 'N/A'}
                    </strong>
                  </p>
                  <p class="text-gray-600">
                    <FaClock className="text-blue-500 mr-2" />
                    {userDetail.experience ||
                      applicationDetails?.resumeAnalysis?.totalExperience ||
                      'N/A'}{' '}
                    Years of Working Experience
                  </p>
                </div>
                <div>
                  <button
                    onClick={handleViewResume}
                    class="bg-primary text-white font-bold px-6 py-2 hover:bg-primary-600 transition duration-300"
                    style={{
                      padding: '0.5rem 1.5rem',
                      borderRadius: '0.5rem',
                      border: 'none'
                    }}
                  >
                    <FaEye size={18} class="mr-2" />
                    View Resume
                  </button>
                </div>
              </div>
            )}
          </div>

          <Modal
            show={updateApplicationModal}
            onHide={() => setUpdateApplicationModal(false)}
            centered
          >
            <style>
              {`
                  .modal-content {
        min-width: 50vw;
        }`}
            </style>
            <Modal.Header closeButton={false}>
              <Modal.Title>Update Application Status</Modal.Title>
              <FaTimes
                className="h4 cursor-pointer"
                onClick={() => setUpdateApplicationModal(false)}
              />
            </Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <label className="mb-2">
                  Select the new status and provide a reason for this update:
                </label>
                <Select
                  options={statusOptions}
                  value={statusOptions.find(
                    (option) => option.value === applicationStatus
                  )}
                  onChange={(selectedOption) =>
                    setApplicationStatus(selectedOption.value)
                  }
                  placeholder="Select Application Status"
                  isSearchable={false}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      fontSize: '1rem',
                      padding: '6px',
                      borderRadius: '0.25rem',
                      borderColor: '#ced4da'
                    })
                  }}
                />
              </div>
              <div className="form-group mt-4">
                <label htmlFor="applicationReason" className="mb-2">
                  Reason
                </label>
                <textarea
                  className="form-control"
                  placeholder="Enter the reason for the status change..."
                  value={applicationReason}
                  onChange={(e) => setApplicationReason(e.target.value)}
                  rows="4"
                  style={{
                    borderColor: '#ced4da',
                    borderRadius: '0.25rem',
                    fontSize: '1rem'
                  }}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setUpdateApplicationModal(false)}
                style={{
                  borderRadius: '0.25rem',
                  padding: '10px 20px'
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={updateApplication}
                disabled={isSubmitting}
                style={{
                  borderRadius: '0.25rem',
                  padding: '10px 20px'
                }}
              >
                {isSubmitting ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  'Update'
                )}
              </Button>
            </Modal.Footer>
          </Modal>

          {isResumeModalOpen && (
            <div
              className="modal fade show d-block"
              style={
                isMobile
                  ? { backgroundColor: 'rgba(0, 0, 0, 0.5)', width: '100vw' }
                  : { backgroundColor: 'rgba(0, 0, 0, 0.5)' }
              }
              tabIndex="-1"
            >
              <style>
                {`
                  .modal-content {
        top: 20px;
        width: 90vw;
        height: 80vh;
        }`}
              </style>
              <div
                className={isMobile ? 'modal-xl ' : 'modal-dialog modal-xl '}
                style={
                  isMobile
                    ? {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: 0
                      }
                    : {}
                }
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Resume</h5>
                    <FaTimes
                      className="h4 cursor-pointer"
                      aria-label="close"
                      onClick={() => setIsResumeModalOpen(false)}
                    />
                  </div>
                  <div
                    className="modal-body"
                    style={{
                      height: '75vh',
                      overflowY: 'auto',
                      overflowX: 'auto'
                    }}
                  >
                    <div className="d-flex justify-content-center">
                      <Document
                        file={resumeLink}
                        onLoadSuccess={onDocumentLoadSuccess}
                        className="d-flex flex-column justify-content-center"
                      >
                        <Page
                          pageNumber={pageNumber}
                          className="border border-secondary shadow-sm"
                          width={Math.min(window.innerWidth * 0.9, 800)}
                        />
                      </Document>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <div className="d-flex flex-column flex-md-row justify-content-between align-items-center gap-3 w-100 mb-3 mb-md-0 me-md-auto">
                      <div></div>
                      {numPages > 1 ? (
                        <div className="d-flex justify-content-center align-items-center gap-3">
                          <button
                            disabled={pageNumber <= 1}
                            onClick={previousPage}
                            style={{cursor: pageNumber <= 1 && 'not-allowed' }}
                            className={`btn ${
                              pageNumber <= 1
                                ? 'btn-primary'
                                : 'btn-outline-light'
                            }`}
                          >
                            Previous
                          </button>
                          <span className="border rounded p-2">
                            Page {pageNumber} of {numPages}
                          </span>
                          <button
                            style={{cursor:pageNumber >= numPages && 'not-allowed' }}
                            disabled={pageNumber >= numPages}
                            onClick={nextPage}
                            className={`btn ${
                              pageNumber >= numPages
                                ? 'btn-primary'
                                : 'btn-outline-light'
                            }`}
                          >
                            Next
                          </button>
                        </div>
                      ) : (
                        <div></div>
                      )}

                      <a
                        href={resumeLink}
                        download
                        className="btn btn-success text-center w-100 d-md-none"
                      >
                        Download
                      </a>
                      <a
                        href={resumeLink}
                        download
                        className="btn btn-success text-center d-none d-md-block"
                      >
                        Download
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default JobApplicationView;
