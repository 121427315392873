import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Search = ({ placeHolder, searchVal, setSearchVal, styles = {} }) => {
  const [localSearchVal, setLocalSearchVal] = useState(searchVal);
  const [searchTimeOut, setSearchTimeOut] = useState(null);
  const regexSpecialChars = [
    '.',
    '^',
    '$',
    '*',
    '+',
    '?',
    '{',
    '}',
    '[',
    ']',
    '\\',
    '|',
    '(',
    ')'
  ];

  const startSearchDebounce = (text) => {
    clearTimeout(searchTimeOut);
    setSearchTimeOut(
      setTimeout(() => {
        setSearchVal(encodeURIComponent(text));
      }, 1000)
    );
  };
  const handleSearch = () => {
    setSearchVal(encodeURIComponent(localSearchVal));
  };
  const handleSearchKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSearch();
    }
  };
  return (
    <div
      className="input-group d-flex align-items-center gap-2 justify-content-between search-area border form-control active"
      style={{ width: '29rem', ...styles }}
    >
      <input
        type="text"
        className={'flex-grow-1  border-0'}
        style={{ color: '#8E8E8E', background: 'transparent' }}
        placeholder={placeHolder || 'Search here'}
        value={localSearchVal}
        onChange={(e) => {
          const text = e.target.value;
          if (regexSpecialChars.find((char) => text.includes(char))) {
            return;
          }
          setLocalSearchVal(text);
          startSearchDebounce(text);
        }}
        onKeyDown={handleSearchKeyDown}
      />
      <span onClick={handleSearch}>
        <Link className="border-0" onClick={handleSearch}>
          <i className="fs-4 flaticon-381-search-2"></i>
        </Link>
      </span>
    </div>
  );
};

export default Search;
