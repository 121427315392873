import React, { useEffect, useState } from 'react';
import { Spinner, Tab } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import ChartLabel from './chartLabel';
import ChartSelector from './chartSelector';

const ApplicationStatusBarChart = () => {
  const [timePeriod, setTimePeriod] = useState('daily');
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const selectorData = [
    { title: 'Daily', value: 'daily' },
    { title: 'Weekly', value: 'weekly' },
    { title: 'Monthly', value: 'monthly' }
  ];
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const orgId = userDetails?.user?.organizationId;

  const fetchData = async () => {
    setIsLoading(true);
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const accessToken = userDetails?.session.accessToken || null;
    if (!accessToken) {
      throw new Error('Access token not found');
    }
    const URL = `${process.env.REACT_APP_DEV_API}/v1/organization/
		${orgId}/analytics/applications-status-count/${timePeriod}`;

    try {
      const response = await fetch(URL, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      });
      if (!response.ok) {
        throw new Error('Error getting reponse');
      }
      const body = await response.json();
      const data = body.data;
      setChartData(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log('Could not fetch chart data', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [timePeriod]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    if (timePeriod == 'weekly') {
      return 'week ' + dateString.split('-')[1];
    } else if (timePeriod === 'monthly') {
      return month;
    }

    return `${day} ${month}`;
  }

  const labels = [...new Set(chartData.map((item) => item.period))];

  const rejectedData = labels.map(
    (label) =>
      chartData.find(
        (item) => item.period === label && item.status === 'REJECTED'
      )?.count || 0
  );

  const acceptedData = labels.map(
    (label) =>
      chartData.find(
        (item) => item.period === label && item.status === 'ACCEPTED'
      )?.count || 0
  );

  const data = {
    labels: labels.map((label) => formatDate(label)),
    datasets: [
      {
        label: 'Rejected',
        data: rejectedData,
        backgroundColor: 'rgb(245, 79, 82)',
        hoverBackgroundColor: 'rgb(234, 75, 77)',
        borderSkipped: false
      },
      {
        label: 'Accepted',
        data: acceptedData,
        backgroundColor: 'rgb(62, 193, 92)',
        hoverBackgroundColor: 'rgb(56, 184, 86)',
        borderSkipped: false
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    barThickness: 'flex',
    maxBarThickness: 30,
    plugins: {
      legend: false
    },
    scales: {
      x: {
        grid: {
          display: true,
          color: 'rgba(89, 59, 219,0.1)',
          drawBorder: true
        }
      },
      y: {
        beginAtZero: true,
        grid: {
          color: 'rgba(89, 59, 219,0.1)',
          drawBorder: true,
          display: false
        }
      }
    }
  };

  return (
    <Tab.Container defaultActiveKey={selectorData[0].value}>
      <div className="card">
        <div className="card-header border-0 pb-0 flex-wrap">
          <h4 className="fs-20 font-w500 mb-1">Application Status Count</h4>
          <div className="card-action coin-tabs mt-3 mt-sm-0">
            <ChartSelector
              setSelector={setTimePeriod}
              selectorData={selectorData}
            />
          </div>
        </div>
        <div className="card-body">
          <div className="pb-4 d-flex flex-wrap">
            <ChartLabel title={'Accepted'} color={'#3EC15C'} />
            <ChartLabel title={'Rejected'} color={'#F54F52'} />
          </div>
          <Tab.Content>
            <div style={{ height: '250px' }}>
              {isLoading ? (
                <div className="h-100 d-flex align-items-center justify-content-center">
                  <Spinner
                    animation="border mb-3"
                    style={{ color: '#858585' }}
                  />
                </div>
              ) : (
                <>
                  {chartData?.length !== 0 ? (
                    <Bar options={options} data={data} />
                  ) : (
                    <h5>No Data Found</h5>
                  )}
                </>
              )}
            </div>
          </Tab.Content>
        </div>
      </div>
    </Tab.Container>
  );
};

export default ApplicationStatusBarChart;
