import React, { useReducer, useContext, useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Collapse, Dropdown } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { MenuList } from './Menu';
import { MenuSuperAdmin } from './MenuSuperAdmin';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { ThemeContext } from '../../../context/ThemeContext';
import LogoutPage from './Logout';

const SideBar = ({ state, setState, handleActive, closeSidebar }) => {
  let d = new Date();
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
    ChangeIconSidebar
  } = useContext(ThemeContext);

  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) {
        setHideOnScroll(isShow);
      }
    },
    [hideOnScroll]
  );

  const handleMenuActive = (status, parent) => {
    setState({ active: parent, activeMenu: status });
    closeSidebar();
  };

  const handleSubmenuActive = (status) => {
    setState({ activeSubmenu: status });
    if (state.activeSubmenu === status) {
      setState({ activeSubmenu: '' });
    }
    closeSidebar();
  };

  let path = window.location.pathname;
  path = path.split('/');
  path = path[path.length - 1];

  const location = useLocation();
  const pathToCheck = location.pathname.split('/')[1];

  // Determine which menu to use based on pathToCheck
  const currentMenu = pathToCheck === 'super-admin' ? MenuSuperAdmin : MenuList;

  useEffect(() => {
    let activeName = '';
    let activeMenuName = '';

    currentMenu.forEach((menuItem) => {
      // Main menu item match
      if (location.pathname === `/${menuItem.to}`) {
        activeName = menuItem.title;
      }

      // Submenu match
      if (menuItem.content) {
        menuItem.content.forEach((submenuItem) => {
          if (location.pathname.includes(submenuItem.to)) {
            activeName = menuItem.title;
            activeMenuName = submenuItem.title;
          }
        });
      }
    });

    // Default to Dashboard if no match is found
    if (location.pathname === '/' || (!activeName && !activeMenuName)) {
      activeName = 'Dashboard';
    }
    console.log('activeName: ', activeName, activeMenuName);

    const billingIcon = document.getElementById('billing-icon');
    if (billingIcon) {
      if (activeName === 'Billing') {
        billingIcon.style.color = '';
      } else {
        billingIcon.style.color = '#96a0af';
      }
    }

    // Update active state
    handleActive(activeName, activeMenuName);
  }, [location, currentMenu]);

  return (
    <div
      onMouseEnter={() => ChangeIconSidebar(true)}
      onMouseLeave={() => ChangeIconSidebar(false)}
      className={`dlabnav ${iconHover} ${
        sidebarposition.value === 'fixed' &&
        sidebarLayout.value === 'horizontal' &&
        headerposition.value === 'static'
          ? hideOnScroll > 120
            ? 'fixed'
            : ''
          : ''
      }`}
    >
      <PerfectScrollbar
        className="dlabnav-scroll"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <ul className="metismenu" id="menu" style={{ minHeight: '66vh' }}>
          {currentMenu.map((data, index) => {
            let menuClass = data.classsChange;
            if (menuClass === 'menu-title') {
              return (
                <li className={menuClass} key={index}>
                  {data.title}
                </li>
              );
            } else {
              return (
                <li
                  className={` ${
                    state.active === data.title ? 'mm-active' : ''
                  }`}
                  key={index}
                  onClick={() => {
                    (!data.content ||
                      (data.content && data.content.length === 0)) &&
                      handleActive(data.title);
                    closeSidebar();
                  }}
                >
                  {data.content && data.content.length > 0 ? (
                    <>
                      <Link
                        to={data.content[0].to}
                        // className="has-arrow" // uncomment code to show the arrow on the main Nav
                        onClick={() => {
                          handleActive(data.title, data.content[0].title);
                          closeSidebar();
                        }}
                      >
                        {data.iconStyle}
                        <span className="nav-text">{data.title}</span>
                        <span className="badge badge-xs style-1 badge-danger">
                          {data.update}
                        </span>
                      </Link>
                      {/* <Collapse in={state.active === data.title ? true :false}> // uncomment the code to make main nav a dropdown */}
                      <ul
                        className={`${
                          menuClass === 'mm-collapse' ? 'mm-show' : ''
                        }`}
                        style={{ top: '0px', bottom: 'auto' }}
                      >
                        {data.content &&
                          data.content.map((subData, index) => {
                            return (
                              <li
                                key={index}
                                className={`${
                                  state.activeSubmenu === subData.title
                                    ? 'mm-active'
                                    : ''
                                }`}
                              >
                                {subData.content &&
                                subData.content.length > 0 ? (
                                  <>
                                    <Link
                                      to={subData.to}
                                      className={
                                        subData.hasMenu ? 'has-arrow' : ''
                                      }
                                      onClick={() => {
                                        handleSubmenuActive(subData.title);
                                        closeSidebar();
                                      }}
                                    >
                                      {subData.title}
                                    </Link>
                                    <Collapse
                                      in={
                                        state.activeSubmenu === subData.title
                                          ? true
                                          : false
                                      }
                                    >
                                      <ul
                                        className={`${
                                          menuClass === 'mm-collapse'
                                            ? 'mm-show'
                                            : ''
                                        }`}
                                      >
                                        {subData.content &&
                                          subData.content.map((d, index) => {
                                            return (
                                              <>
                                                <li key={index}>
                                                  <Link
                                                    className={`${
                                                      path === d.to
                                                        ? 'mm-active'
                                                        : ''
                                                    }`}
                                                    to={d.to}
                                                  >
                                                    {d.title}
                                                  </Link>
                                                </li>
                                              </>
                                            );
                                          })}
                                      </ul>
                                    </Collapse>
                                  </>
                                ) : (
                                  <Link
                                    className={`${
                                      state.activeMenu === subData.title
                                        ? 'mm-active'
                                        : ''
                                    }`}
                                    to={subData.to}
                                    onClick={() => {
                                      handleMenuActive(
                                        subData.title,
                                        data.title
                                      );
                                      closeSidebar();
                                    }}
                                  >
                                    {subData.title}
                                  </Link>
                                )}
                              </li>
                            );
                          })}
                      </ul>
                      {/* </Collapse> */}
                    </>
                  ) : (
                    <Link to={data.to}>
                      {data.iconStyle}
                      <span className="nav-text">{data.title}</span>
                    </Link>
                  )}
                </li>
              );
            }
          })}
        </ul>

        <div className="copyright text-center">
          <p>
            <strong>TalentSwap</strong> © {d.getFullYear()} All Rights Reserved
          </p>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
