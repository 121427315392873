import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Spinner,
  Form
} from 'react-bootstrap';
import { fetchLogos } from '../../../services/AuthService';
import { useNavigate } from 'react-router-dom';
import ToggleSwitch from '../bootstrap/Toggle';
import { FaEye, FaPencilAlt, FaImage } from 'react-icons/fa';
import { FaAngleLeft, FaAngleRight, FaRegTrashCan } from 'react-icons/fa6';
import { IoSearch } from 'react-icons/io5';
import { MdOutlineWifiTetheringErrorRounded } from 'react-icons/md';
import Search from '../Jobick/Jobs/ui/search';
import { toast } from 'react-toastify';
import { getItemsPerPage } from '../../utils/CommonFunctions';

const ListOrganisation = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState(''); // for status filter
  const [pageVal, setPageVal] = useState(1); // for value of pagination
  const [totalOrganizations, setTotalOrganizations] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(1);
  const [organisationList, setOrganisationList] = useState(null);
  const [searchVal, setSearchVal] = useState('');
  const organisationPerPage = 7;

  const handlePreviousPageClick = () => {
    if (pageVal === 1) {
      return;
    }
    setPageVal(pageVal - 1);
  };

  const handleNextPageClick = () => {
    if (pageVal >= totalPages) {
      return;
    }
    setPageVal(pageVal + 1);
  };

  const fetchOrganisationList = async () => {
    !organisationList && setLoading(1);
    const URL = `${
      process.env.REACT_APP_DEV_API
    }/v1/organization?search=${searchVal}&page=${pageVal}&limit=${getItemsPerPage()}`;
    try {
      const superAdminUser = JSON.parse(localStorage.getItem('superAdminUser'));
      const accessToken = superAdminUser?.session?.accessToken || null;
      if (!accessToken) {
        throw new Error('Access token not found');
      }

      const response = await fetch(URL, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      });

      if (!response.ok) {
        throw new Error('Fetching failed');
      }
      const body = await response.json();
      const { data, total, totalPages } = body;
      setOrganisationList(data);
      setTotalOrganizations(total);
      setTotalPages(totalPages);
      setLoading(0);
    } catch (error) {
      setLoading(2);
      console.error('Error fetching organization list', error);
    }
  };

  useEffect(() => {
    fetchOrganisationList();
  }, [pageVal]);

  useEffect(() => {
    if (pageVal === 1) {
      fetchOrganisationList();
    } else {
      setPageVal(1);
    }
  }, [searchVal, status]);

  const handleStatusToggle = async (orgId, isActive) => {
    const superAdminUser = JSON.parse(localStorage.getItem('superAdminUser'));
    const accessToken = superAdminUser?.session?.accessToken || null;
    if (!accessToken) {
      throw new Error('Access token not found');
    }

    const action = isActive ? 'deactivate' : 'activate';
    const URL = `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/${action}`;

    try {
      const response = await fetch(URL, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      });

      if (!response.ok) {
        throw new Error(`Failed to ${action} organization`);
      }

      fetchOrganisationList();
    } catch (error) {
      console.error(`Error ${action}ing organization:`, error);
    }
  };

  const handleUpdateLogo = (orgId) => {
    navigate(`/super-admin/logo-customisation/${orgId}`);
  };

  const handleMockAdmin = async (orgId) => {
    const superAdminUser = JSON.parse(localStorage.getItem('superAdminUser'));
    const accessToken = superAdminUser?.session?.accessToken || null;
    if (!accessToken) {
      throw new Error('Access token not found');
    }

    const URL = `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/impersonate-admin`;

    try {
      const response = await fetch(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to impersonate admin');
      }

      const responseData = await response.json();

      localStorage.setItem('userDetails', JSON.stringify(responseData.data));
      await fetchLogos();
      window.open('/dashboard', '_blank');
    } catch (error) {
      console.error('Error impersonating admin:', error);
      toast.error('Failed to Impersonate Admin');
    }
  };

  const handleEditClick = (orgId) => {
    navigate(`/super-admin/edit-organisation/${orgId}`);
  };

  const handleCreateOrganisation = () => {
    navigate('/super-admin/create-organisation');
  };

  const pgItem1 =
    pageVal === 1
      ? 1
      : pageVal >= totalPages && totalPages > 2
        ? totalPages - 2
        : pageVal - 1;
  const pgItem2 =
    pageVal === 1
      ? 2
      : pageVal >= totalPages && totalPages > 2
        ? totalPages - 1
        : pageVal;
  const pgItem3 =
    pageVal === 1
      ? 3
      : pageVal >= totalPages && totalPages > 2
        ? totalPages
        : pageVal + 1;

  return (
    <>
      <style>
        {`
    .custom-dropdown-toggle,
    .custom-dropdown-toggle:hover,
    .custom-dropdown-toggle:focus,
    .custom-dropdown-toggle:active {
      border: 1px solid #d3d3d3 !important;
      outline: none !important;
      box-shadow: none !important;
      
    }
    .custom-dropdown-toggle::after {
      display: none !important;
    }
  `}
      </style>
      <div className="d-flex gap-2 align-items-center mb-4 flex-wrap">
        <h3 className="mb-0 me-auto">Organisations List</h3>

        <Search searchVal={searchVal} setSearchVal={setSearchVal} />

        <div>
          <Button
            className="btn btn-primary btn-sm"
            onClick={handleCreateOrganisation}
          >
            <i className="fas fa-plus me-2"></i>Create Organisation
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className=" mb-4 table-responsive">
            <table
              id="job-table"
              className="table table-fixed display dataTablesCard mb-4 job-table card-table dataTable no-footer"
            >
              <thead>
                <tr className="user-select-none ">
                  <th style={{ width: '2rem' }}>No</th>
                  <th style={{ width: '12rem' }}>Name </th>
                  <th style={{ width: '12rem' }}>Email</th>
                  <th style={{ width: '6rem' }}>Phone</th>
                  <th style={{ width: '6rem' }}>Admin name</th>
                  <th style={{ width: '10rem' }}>Status</th>
                  <th style={{ width: '10rem' }} className="text-center">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading === 0 &&
                  organisationList.map((org, index) => (
                    <tr key={index} style={{ height: '4.5rem' }}>
                      <td
                        style={{ maxWidth: '2rem' }}
                        className="text-truncate"
                      >
                        {(pageVal - 1) * organisationPerPage + index + 1}.
                      </td>
                      <td
                        style={{ maxWidth: '12rem' }}
                        className="text-truncate"
                      >
                        {org.name}
                      </td>
                      <td
                        style={{ maxWidth: '12rem' }}
                        className="text-truncate wspace-no"
                      >
                        {org.email}
                      </td>
                      <td
                        style={{ maxWidth: '6rem' }}
                        className="text-truncate"
                      >
                        {org.phone}
                      </td>
                      <td
                        style={{ maxWidth: '6rem' }}
                        className="text-truncate"
                      >
                        {org.admins && org.admins.length > 0
                          ? org.admins[0].name
                          : 'NA'}
                      </td>
                      <td className="" style={{ maxWidth: '10rem' }}>
                        <div className="d-flex">
                          <ToggleSwitch
                            isChecked={org.isActive}
                            handleToggle={() => {
                              handleStatusToggle(org._id, org.isActive);
                            }}
                          />
                          <span
                            className={`badge badge-lg light badge-${
                              org.isActive ? 'success' : 'danger'
                            }`}
                          >
                            {org.isActive ? 'ACTIVE' : 'INACTIVE'}
                          </span>
                        </div>
                      </td>
                      <td style={{ maxWidth: '10rem' }}>
                        <div className="action-buttons d-flex gap-4 items-center justify-content-center">
                          <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip>Access Dashboard</Tooltip>}
                          >
                            <Link
                              to={'#'}
                              className="d-flex align-items-center"
                              onClick={(e) => {
                                e.preventDefault();
                                handleMockAdmin(org._id);
                              }}
                            >
                              <FaEye size={18} />
                            </Link>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip>Edit Organisation</Tooltip>}
                          >
                            <Link
                              to={'#'}
                              className="d-flex align-items-center"
                              onClick={(e) => {
                                e.preventDefault();
                                handleEditClick(org._id);
                              }}
                            >
                              <FaPencilAlt size={16} />
                            </Link>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip>Update Logo</Tooltip>}
                          >
                            <Link
                              to={'#'}
                              className="d-flex align-items-center"
                              onClick={(e) => {
                                e.preventDefault();
                                handleUpdateLogo(org._id);
                              }}
                            >
                              <FaImage size={18} />
                            </Link>
                          </OverlayTrigger>
                        </div>
                      </td>
                    </tr>
                  ))}

                {/* Adding empty filler rows to keep the height of table same */}
                {loading === 0 &&
                  organisationList.length > 0 &&
                  [
                    ...Array(
                      Math.max(0, organisationPerPage - organisationList.length)
                    )
                  ].map((_, i) => (
                    <tr key={i} style={{ height: '4.5rem' }}></tr>
                  ))}
              </tbody>
            </table>
            {loading === 1 && (
              <div className="d-flex align-items-center justify-content-center gap-4 mt-5 pt-5">
                <Spinner animation="border mb-3" style={{ color: '#858585' }} />
              </div>
            )}
            {loading === 2 && (
              <div className="d-flex flex-column align-items-center mt-5 pt-5">
                <MdOutlineWifiTetheringErrorRounded
                  size={120}
                  color="#878787B7"
                />
                <h2>Error fetching data</h2>
                <h3 style={{ color: '#828282' }}>
                  Check your network and try again
                </h3>
              </div>
            )}
            {loading === 0 && organisationList.length === 0 && (
              <div className="d-flex flex-column align-items-center mt-5 pt-5">
                <IoSearch size={120} color="#878787B7" />
                <h2>No results found</h2>
                <h3 style={{ color: '#828282' }}>
                  Adjust your filters and try again
                </h3>
              </div>
            )}
          </div>
        </div>
        {loading === 0 && organisationList.length !== 0 && (
          <div className="d-md-flex d-block align-items-center justify-content-between text-center  flex-wrap mt-md-0 mt-3">
            <div className="mb-md-0 mb-2">
              <p className="dataTables_info">{`Showing ${
                organisationList.length !== 0
                  ? organisationPerPage * (pageVal - 1) + 1
                  : 0
              } to ${
                organisationList
                  ? organisationPerPage * (pageVal - 1) +
                    organisationList.length
                  : 0
              } of ${totalOrganizations} entries`}</p>
            </div>
            <nav>
              <ul className="pagination pagination-circle justify-content-center">
                <li className="page-item page-indicator">
                  <button
                    onClick={handlePreviousPageClick}
                    disabled={pageVal === 1}
                    className={' page-link'}
                    style={{ backgroundColor: pageVal === 1 && '#ECECEC',
                      cursor: pageVal === 1 && 'not-allowed'
                     }}
                  >
                    <FaAngleLeft
                      style={{ color: pageVal === 1 && '#616161' }}
                    />
                  </button>
                </li>
                {pgItem1 > 1 ? (
                  <li className="page-item d-flex align-items-end">
                    <label className="fs-4">...</label>
                  </li>
                ) : (
                  <div style={{ width: '15px' }} />
                )}
                <li
                  className={`page-item ${pgItem1 === pageVal && 'active'}`}
                  onClick={() => {
                    setPageVal(pgItem1);
                  }}
                >
                  <Button className="page-link">{pgItem1}</Button>
                </li>
                {totalPages > 1 && (
                  <li
                    className={`page-item ${pgItem2 === pageVal && 'active'}`}
                    onClick={() => {
                      setPageVal(pgItem2);
                    }}
                  >
                    <Button className="page-link">{pgItem2}</Button>
                  </li>
                )}
                {totalPages > 2 && (
                  <li
                    className={`page-item ${pgItem3 === pageVal && 'active'}`}
                    onClick={() => {
                      setPageVal(pgItem3);
                    }}
                  >
                    <Button className="page-link">{pgItem3}</Button>
                  </li>
                )}
                {pgItem3 < totalPages ? (
                  <li className="page-item d-flex align-items-end">
                    <label className="fs-4">...</label>
                  </li>
                ) : (
                  <div style={{ width: '15px' }} />
                )}
                <li className="page-item page-indicator">
                  <button
                    onClick={handleNextPageClick}
                    disabled={pageVal >= totalPages}
                    className="page-link"
                    style={{
                      backgroundColor: pageVal >= totalPages && '#ECECEC',
                      cursor: pageVal >= totalPages && 'not-allowed'
                    }}
                  >
                    <FaAngleRight
                      style={{ color: pageVal >= totalPages && '#616161' }}
                    />
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        )}
      </div>
    </>
  );
};
export default ListOrganisation;
