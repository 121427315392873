import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import JobApplicationBlog from "../Jobick/Jobs/JobApplicationBlog";
import axios from "axios";
import Search from "../Jobick/Jobs/ui/search";
import { getItemsPerPage } from "../../utils/CommonFunctions";
import { FaFilter } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const JobApplication = () => {
  const { jobId } = useParams();
  const navigate = useNavigate();

  // State variables
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [selectedJobId, setSelectedJobId] = useState(jobId || "");
  const [jobList, setJobList] = useState([]);
  const [applicationData, setApplicationData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalEntries, setTotalEntries] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // Get user details and organization ID from local storage
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const orgId = userDetails?.user?.organizationId;
  const accessToken = userDetails?.session.accessToken || null;

  // Effect for handling mobile view
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Effect for fetching job list
  useEffect(() => {
    fetchJobList();
  }, []);

  // Effect for fetching application data when dependencies change
  useEffect(() => {
    if (selectedJobId) {
      fetchApplicationData(selectedJobId, 1);
    }
  }, [selectedJobId, searchVal, status, startDate, endDate]);

  // Function to fetch job list
  const fetchJobList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/applications?limit=7`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setJobList(response.data.jobList);
      if (response.data.jobList.length > 0 && !selectedJobId) {
        setSelectedJobId(response.data.jobList[0]._id);
      }
    } catch (error) {
      console.error("Error fetching job list:", error);
    }
  };

  // Function to format date for API
  const formatDateForAPI = (date) => {
    if (!date) {
      return null;
    }

    // Get year, month, and day in the local timezone
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");

    // Format as YYYY-MM-DD
    const formattedDate = `${year}-${month}-${day}`;

    console.log("Original date:", date);
    console.log("Formatted date:", formattedDate);

    return formattedDate;
  };

  // Function to fetch application data
  const fetchApplicationData = useCallback(
    async (jobId, page = 1) => {
      try {
        setLoading(1);

        let queryString = `?limit=${getItemsPerPage()}&jobId=${jobId}&page=${page}`;

        if (status) {
          queryString += `&status=${status}`;
        }
        if (searchVal) {
          queryString += `&search=${searchVal}`;
        }
        if (startDate) {
          queryString += `&startDate=${formatDateForAPI(startDate)}`;
        }
        if (endDate) {
          queryString += `&endDate=${formatDateForAPI(endDate)}`;
        }

        const response = await axios.get(
          `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/applications${queryString}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        setLoading(0);
        setApplicationData(response.data.data);
        setTotalEntries(response.data.total);
        setTotalPages(response.data.totalPages);
        setCurrentPage(response.data.currentPage);
      } catch (error) {
        setLoading(2);
        navigate("/page-error-404");
        console.error("Error fetching application data:", error);
      }
    },
    [orgId, accessToken, status, searchVal, startDate, endDate, navigate]
  );

  // Function to handle page change
  const handlePageChange = (newPage) => {
    if (newPage !== currentPage) {
      fetchApplicationData(selectedJobId, newPage);
    }
  };

  // Function to handle job selection
  const handleJobSelection = (jobId) => {
    if (jobId !== selectedJobId) {
      setSelectedJobId(jobId);
      setApplicationData([]);
      fetchApplicationData(jobId);
    }
  };

  // Function to handle start date change
  const handleStartDateChange = (date) => {
    setStartDate(date);
    if (!date) {
      setEndDate(null);
    } else if (endDate && date > endDate) {
      setEndDate(null);
    }
  };

  // Function to handle end date change
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  // Function to get status label
  const getStatusLabel = (value) => {
    const option = statusOptions.find((opt) => opt.value === value);
    return option ? option.label : "Select Status";
  };

  // Status options for dropdown
  const statusOptions = [
    { value: "", label: "Default" },
    { value: "APPLICATION_SUBMITTED", label: "Application Submitted" },
    { value: "PROFILE_REVIEW_COMPLETED", label: "Profile Review Completed" },
    { value: "AI_REVIEW_COMPLETED", label: "AI Review Completed" },
    { value: "ASSESSMENTS_IN_PROGRESS", label: "Assessments in Progress" },
    { value: "ASSESSMENTS_COMPLETED", label: "Assessments Completed" },
    { value: "INTERVIEW_PROCESS_STARTED", label: "Interview Process Started" },
    { value: "INTERVIEWS_COMPLETED", label: "Interviews Completed" },
    { value: "CANDIDATE_WITHDRAWN", label: "Candidate Withdrawn" },
    { value: "REJECTED", label: "Rejected" },
    { value: "OFFER_EXTENDED", label: "Offer Extended" },
    { value: "HIRED", label: "Hired" },
  ];

  const selectedJob = jobList.find((job) => job._id === selectedJobId);

  return (
    <>
      <style>
        {`
          .custom-dropdown-menu {
            transform: translate3d(0px, 30px, 0px) !important;
            min-width: 100% !important;
            max-height: 200px;
            overflow-y: auto;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            padding: 0;
            margin: 0;
          }
          .custom-dropdown-menu::-webkit-scrollbar {
            width: 8px;
          }
          .custom-dropdown-menu::-webkit-scrollbar-track {
            background: #f1f1f1;
          }
          .custom-dropdown-menu::-webkit-scrollbar-thumb {
            background: #888;
          }
          .custom-dropdown-menu::-webkit-scrollbar-thumb:hover {
            background: #555;
          }
          .custom-dropdown-toggle {
            width: 100% !important;
          }
          .dropdown-item.active,
          .dropdown-item:active {
            background-color: #f8f9fa;
          }
          .date-picker-container {
            display: flex;
            flex-direction: column;
            gap: 10px;
          }
          .date-picker-wrapper {
            display: flex;
            align-items: center;
            gap: 10px;
            justify-content: space-between;
          }
          .date-picker {
            flex: 1;
            padding: 0.4rem 0.75rem;
            border: 1px solid #ced4da;
            border-radius: 4px;
            background-color: #fff;
            box-shadow: none;
            width: 18rem;
          }
          .react-datepicker__input-container {
            display: flex;
            align-items: center;
          }
          .custom-dropdown-item:hover {
            background-color: #e3f2fd !important;
            color: #0d6efd; 
            font-weight: 500;
            cursor: pointer;
            transition: background-color 0.3s, color 0.3s;
          }

        `}
      </style>
      <div className="d-flex align-items-center justify-content-center mb-4 flex-wrap">
        <h3 className="mb-0 me-3">Job Application</h3>
        <div className="col-xl-3 col-xxl-3 search-dropdown d-flex align-items-center me-auto">
          <Dropdown
            className="form-control border-0 style-1 h-auto"
            title={selectedJob ? selectedJob.title : "Select Job Id"}
            style={{ width: isMobile ? "22rem" : "" }}
          >
            <Dropdown.Toggle
              as="div"
              className="fs-16 font-w500 text-primary d-flex justify-content-between align-items-center custom-dropdown-toggle text-truncate"
            >
              {selectedJob ? (
                <span className="text-truncate">{selectedJob.title}</span>
              ) : (
                <span className="text-truncate">Select Job Id</span>
              )}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-end mt-1 custom-dropdown-menu">
              {jobList.map((job) => (
                <Dropdown.Item
                  key={job._id}
                  className={`text-primary ${job._id === selectedJobId ? "active" : ""}`}
                  onClick={() => handleJobSelection(job._id)}
                >
                  {job.title}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div
          className="d-flex align-items-center gap-2"
          style={{ maxWidth: "100%" }}
        >
          <Search searchVal={searchVal} setSearchVal={setSearchVal} />
          <Dropdown as="li" className="nav-item notification_dropdown">
            <Dropdown.Toggle
              variant=""
              as="div"
              className="nav-link text-primary justify-content-between align-items-center ai-icon i-false c-pointer"
              role="button"
              data-toggle="dropdown"
            >
              <button className="btn btn-secondary btn-sm">
                <FaFilter size="13" />
              </button>
            </Dropdown.Toggle>
            <Dropdown.Menu
              align="right"
              className="p-3 dropdown-menu dropdown-menu-end"
              style={{ overflow: "visible", width: "28rem" }}
            >
              <h4 style={{ paddingLeft: "0.5rem", color: "#6c757d" }}>
                Filters
              </h4>
              <hr style={{ margin: "0.5rem 0" }} />

              {/* Status Filter */}
              <div className="p-2">
                <div className="d-flex align-items-center gap-3 justify-content-between">
                  <label className="text-black fs-5 mb-0">Status</label>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="custom"
                      id="status-dropdown"
                      className="status-filter-select"
                      style={{
                        color: "#4885ed",
                        width: "18rem",
                        padding: "0.4rem 0.75rem",
                        borderRadius: "4px",
                        border: "1px solid #ced4da",
                        backgroundColor: "#fff",
                        fontWeight: "500",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      {getStatusLabel(status)}
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="status-dropdown-menu"
                      style={{ width: "18rem" }}
                    >
                      {statusOptions.map((option) => (
                        <Dropdown.Item
                          key={option.value}
                          onClick={() => setStatus(option.value)}
                          className="custom-dropdown-item"
                          style={{
                            color: "#4885ed",
                            backgroundColor:
                              status === option.value
                                ? "#ecf3fd"
                                : "transparent",
                            padding: "0.4rem 0.75rem",
                          }}
                        >
                          {option.label}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>

              {/* Date Picker Filter */}
              <div className="p-2">
                <div className="date-picker-container">
                  <div className="date-picker-wrapper">
                    <label className="text-black fs-5 mb-0">Start Date:</label>
                    <div>
                      <DatePicker
                        selected={startDate}
                        onChange={handleStartDateChange}
                        dateFormat="dd-MM-yyyy"
                        startDate={startDate}
                        endDate={endDate}
                        selectsStart
                        maxDate={new Date()}
                        placeholderText="DD-MM-YYYY"
                        isClearable
                        showIcon
                        toggleCalendarOnIconClick
                        className="date-picker"
                        onChangeRaw={(e) => {
                          if (/[^0-9/-]/g.test(e.target.value)) {
                            e.preventDefault(); // Block input
                          }
                        }}
                        onBlur={(e) => {
                          const isValidDate = /^\d{2}-\d{2}-\d{4}$/.test(
                            e.target.value
                          );
                          if (!isValidDate && e.target.value !== "") {
                            setStartDate(null);
                          }
                        }}
                        style={{ width: "18rem" }}
                      />
                    </div>
                  </div>
                  {startDate && (
                    <div className="date-picker-wrapper">
                      <label className="text-black fs-5 mb-0">End Date:</label>
                      <div>
                        <DatePicker
                          selected={endDate}
                          disabled={!startDate}
                          onChange={handleEndDateChange}
                          dateFormat="dd-MM-yyyy"
                          startDate={startDate}
                          endDate={endDate}
                          selectsEnd
                          minDate={startDate}
                          maxDate={new Date()}
                          placeholderText="DD-MM-YYYY"
                          isClearable
                          showIcon
                          toggleCalendarOnIconClick
                          className="date-picker"
                          onChangeRaw={(e) => {
                            if (/[^0-9/-]/g.test(e.target.value)) {
                              e.preventDefault(); // Block input
                            }
                          }}
                          onBlur={(e) => {
                            const isValidDate = /^\d{2}-\d{2}-\d{4}$/.test(
                              e.target.value
                            );
                            if (!isValidDate && e.target.value !== "") {
                              setStartDate(null);
                            }
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <JobApplicationBlog
            applicationData={applicationData}
            totalEntries={totalEntries}
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            loading={loading}
          />
        </div>
      </div>
    </>
  );
};

export default JobApplication;
