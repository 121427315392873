const originalFetch = fetch;

window.fetch = async (...args) => {
  try {
    const response = await originalFetch(...args);

    if (response.status === 401) {
      console.error('Unauthorized! Redirecting to login...');
      localStorage.clear();
      window.location.href = '/login';
    }

    return response;
  } catch (error) {
    console.error('Fetch error:', error);
  }
};
