import React from 'react';

const SuperAdmin = () => {
  return (
    <div>
      <h3 className="mb-0 me-auto">Super Admin User Page</h3>
    </div>
  );
};

export default SuperAdmin;
