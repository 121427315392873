import React, { useEffect, useRef, useState } from "react";
import {
  HiOutlineArrowLongLeft,
  HiOutlineArrowLongRight,
  HiOutlineArrowLongDown,
} from "react-icons/hi2";

const ApplicationStatusProgress = ({ statusHistory }) => {
  const ref = useRef(null);
  const resizeTimeoutRef = useRef(null);

  const [itemsPerRow, setItemsPerRow] = useState(3);
  const stepWidth = 230;

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const allPossibleSteps = [
    { title: "Application Submitted", value: "APPLICATION_SUBMITTED" },
    { title: "Profile Review Completed", value: "PROFILE_REVIEW_COMPLETED" },
    { title: "AI Review Completed", value: "AI_REVIEW_COMPLETED" },
    { title: "AI Answers Submitted", value: "AI_ANSWERS_SUBMITTED" },
    { title: "Assessment In Progress", value: "ASSESSMENTS_IN_PROGRESS" },
    { title: "Assessment Completed", value: "ASSESSMENTS_COMPLETED" },
    { title: "Interview's Started", value: "INTERVIEW_PROCESS_STARTED" },
    { title: "Interview's Completed", value: "INTERVIEWS_COMPLETED" },
    { title: "Offer Extended", value: "OFFER_EXTENDED" },
    { title: "Hired", value: "HIRED" },
    { title: "Rejected", value: "REJECTED" },
    { title: "Interview Failed", value: "INTERVIEW_FAILED" },
    { title: "Candidate Withdrawn", value: "CANDIDATE_WITHDRAWN" },
  ];

  // Create steps array, mapping status history while allowing duplicates
  const steps = statusHistory
    .map((status, index) => {
      const matchedStep = allPossibleSteps.find(
        (step) => step.value === status.currentStatus
      );
      return matchedStep
        ? { ...matchedStep, changedAt: status.changedAt }
        : null;
    })
    .filter(Boolean);

  const lastIndex = steps.length;

  const effectiveProgressArray = [];
  for (let i = 0; i < steps.length; i += itemsPerRow) {
    effectiveProgressArray.push(steps.slice(i, i + itemsPerRow));
  }

  const verticalLineStyleLeft = {
    width: `${stepWidth}px`,
    height: "2rem",
  };
  const verticalLineStyleRight = {
    width: `${stepWidth}px`,
    height: "2rem",
  };

  const updateItemsPerRow = () => {
    const width = ref.current.offsetWidth;
    setItemsPerRow(parseInt(width / (stepWidth + 32)));
  };

  useEffect(() => {
    updateItemsPerRow();
    const handleResize = () => {
      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
      }

      resizeTimeoutRef.current = setTimeout(() => {
        setIsMobile(window.innerWidth <= 768);
        updateItemsPerRow();
      }, 100);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
      }
    };
  }, []);

  const divStyle = {
    width: isMobile ? 'fit-content' : 'min-content'
    // margin: "0 auto",
  };

  const getFormattedDateTime = (date) => {
    const dateObject = new Date(date);
    const formattedDate = dateObject.toLocaleDateString("en-GB");
    const formattedTime = dateObject.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return { date: formattedDate, time: formattedTime };
  };

  const DownArrowEnd = ({ isRowVisited }) => (
    <div
      style={verticalLineStyleRight}
      className="align-self-end d-flex justify-content-center"
    >
      <HiOutlineArrowLongDown
        size={30}
        color={isRowVisited ? "#4885ED" : "#C8C8C8"}
      />
    </div>
  );

  const DownArrowStart = ({ isRowVisited }) => (
    <div
      style={verticalLineStyleLeft}
      className="d-flex justify-content-center"
    >
      <HiOutlineArrowLongDown
        size={30}
        color={isRowVisited ? "#4885ED" : "#C8C8C8"}
      />
    </div>
  );

  const HorizontalArrow = ({ currentStepIndex, colIndex, isEvenRow }) => {
    const isLineVisited = currentStepIndex < lastIndex - 1;
    const isShowArrow =
      currentStepIndex !== steps.length - 1 && colIndex !== itemsPerRow - 1;

    return isShowArrow ? (
      <div style={{ width: "2rem" }} className="d-flex align-items-center">
        {isEvenRow ? (
          <HiOutlineArrowLongRight
            size={30}
            color={isLineVisited ? "#4885ED" : "#C8C8C8"}
          />
        ) : (
          <HiOutlineArrowLongLeft
            size={30}
            color={isLineVisited ? "#4885ED" : "#C8C8C8"}
          />
        )}
      </div>
    ) : null;
  };

  const Step = ({ rowIndex, colIndex, step, isEvenRow }) => {
    const currentStepIndex = itemsPerRow * rowIndex + colIndex;
    const isStepVisited = currentStepIndex < lastIndex;
    const isInterruptingStep = [
      "REJECTED",
      "INTERVIEW_FAILED",
      "CANDIDATE_WITHDRAWN",
    ].includes(step.value);

    return (
      <>
        <div
          key={colIndex}
          style={{ width: stepWidth }}
          className={`p-4 rounded background ${
            isStepVisited
              ? isInterruptingStep
                ? "bg-danger text-white"
                : "bg-primary text-white"
              : "border border-light"
          }`}
        >
          {step.title}
          <div className="d-flex justify-content-between">
            <p className="m-0 p-0 text-start fs-12 text-primary-light">
              {getFormattedDateTime(step.changedAt).date}
            </p>
            <p className="m-0 p-0 text-start fs-12 text-primary-light">
              {getFormattedDateTime(step.changedAt).time}
            </p>
          </div>
        </div>
        <HorizontalArrow
          isEvenRow={isEvenRow}
          colIndex={colIndex}
          currentStepIndex={currentStepIndex}
        />
      </>
    );
  };

  const Rows = ({ row, rowIndex }) => {
    const isEven = rowIndex % 2 === 0;
    const isRowVisited = itemsPerRow * (rowIndex + 1) < lastIndex;
    return (
      <>
        <div className={`d-flex ${isEven ? "flex-row" : "flex-row-reverse"}`}>
          {row.map((step, j) => (
            <Step
              key={j}
              rowIndex={rowIndex}
              colIndex={j}
              step={step}
              isEvenRow={isEven}
            />
          ))}
        </div>
        {rowIndex < effectiveProgressArray.length - 1 && (
          <div
            className={`d-flex ${
              isEven ? "justify-content-end" : "justify-content-start"
            }`}
          >
            {isEven ? (
              <DownArrowEnd isRowVisited={isRowVisited} />
            ) : (
              <DownArrowStart isRowVisited={isRowVisited} />
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {statusHistory.length > 0 ? (
        <div className="p-2 d-flex justify-content-center" ref={ref}>
          <div className="" style={divStyle}>
          {effectiveProgressArray.map((row, i) => (
              <Rows key={i} row={row} rowIndex={i} />
            ))}
          </div>
        </div>
      ) : (
        <h3>No application status history found</h3>
      )}
    </>
  );
};

export default ApplicationStatusProgress;