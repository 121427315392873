import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import Accordion from 'react-bootstrap/Accordion';
import { Button, Spinner } from 'react-bootstrap';
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import { FaTimes } from 'react-icons/fa';
import './personality-test.css';
import { FaAngleDown } from 'react-icons/fa6';
const PersonalityTest = () => {
  const [email, setEmail] = useState('');
  const [emails, setEmails] = useState([]);
  const [disc, setDisc] = useState(true);
  const [fetchUsersLoading, setFetchUsersLoading] = useState(false);
  const [motivators, setMotivators] = useState(true);
  const [hvpPlus, setHvpPlus] = useState(true);
  const [invitedUsers, setInvitedUsers] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(10);
  const [reInvLoading, setReInvLoading] = useState(-1); //Resend invitation loader item index
  const [invLoading, setInvLoading] = useState(false); //Send invitation loader flag
  const [sortBy, setSortBy] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('desc');
  const [status, setStatus] = useState('');
  const [jobId, setJobId] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const accessToken = localStorage.getItem('userDetails')
    ? JSON.parse(localStorage.getItem('userDetails')).session.accessToken
    : null;
  const orgId = userDetails?.user?.organizationId;
  const [activeKey, setActiveKey] = useState(
    localStorage.getItem('accordionKey') || '0'
  );

  const fetchInvitedUsersList = async () => {
    let url = `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/personality-test`;
    let params = '?';
    if (currentPage) {
      params += `page=${currentPage}&`;
    }
    if (limit) {
      params += `limit=${limit}&`;
    }
    if (search) {
      params += `search=${search}&`;
    }
    if (sortBy) {
      params += `sortBy=${sortBy}&`;
    }
    if (sortOrder) {
      params += `sortOrder=${sortOrder}&`;
    }
    if (status && status.trim() != '') {
      params += `status=${status}&`;
    }
    if (jobId && jobId.trim() != '') {
      params += `jobId=${jobId}&`;
    }
    if (email && email.trim() != '') {
      params += `email=${email}&`;
    }

    try {
      setFetchUsersLoading(true);
      const response = await axios.get(`${url}${params}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      });
      setFetchUsersLoading(false);
      if (response.data.statusCode === 200) {
        setCurrentPage(response.data.currentPage);
        setTotalResults(response.data.total);
        setTotalPages(response.data.totalPages);
        setInvitedUsers(response.data.data);
      }
    } catch (error) {
      setFetchUsersLoading(false);
      console.log(error);
    }
  };

  const inviteUser = async () => {
    try {
      setInvLoading(true);
      let testTypes = [];
      if (disc) {
        testTypes.push('DISC');
      }
      if (motivators) {
        testTypes.push('MOTIVATORS');
      }
      if (hvpPlus) {
        testTypes.push('HVP_PLUS');
      }
      if (testTypes.length === 0) {
        toast.error('Please select atleast one Personality test');
        return;
      }
      const payload = {
        emails: emails,
        testTypes: testTypes
      };
      const response = await axios.post(
        `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/personality-test/invite`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        }
      );
      if (response.data.statusCode === 200) {
        if (response.data.message === 'INVITATIONS_SENT_SUCCESSFULLY') {
          toast.success('Invitation sent successfully');
          setEmails([]);
          fetchInvitedUsersList();
        }
      }
      setInvLoading(false);
    } catch (error) {
      if (error.response.status === 400) {
        toast.error(error.response.data.details);
      }
      console.log(error);
      setInvLoading(false);
    }
  };

  const resendInvite = async (testId) => {
    try {
      const payload = {
        testId
      };
      const response = await axios.post(
        `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/personality-test/resend-invite`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        }
      );
      if (response.data.statusCode === 200) {
        if (response.data.message === 'INVITATION_RESENT_SUCCESSFULLY') {
          toast.success('Invitation resent successfully');
        }
      }
      setReInvLoading(-1);
    } catch (error) {
      console.log(error);
      setReInvLoading(-1);
    }
  };

  const handleToggle = (key) => {
    setActiveKey(activeKey === key ? '-1' : key);
  };

  const formatText = (text) => {
    //replace underscore with space
    return text.replace(/_/g, ' ');
  };

  useEffect(() => {
    localStorage.setItem('accordionKey', activeKey);
  }, [activeKey]);

  useEffect(() => {
    fetchInvitedUsersList();
  }, [currentPage]);

  const handleOpenReport = (e, user) => {
    e.preventDefault();
    e.stopPropagation();
    if (user?.discTest?.status && user.discTest.status === 'COMPLETED') {
      window.open(
        `${window.location.origin}/job-disc-view/${user._id}`,
        '_blank'
      );
    }
    if (
      user?.motivatorsTest?.status &&
      user.motivatorsTest.status === 'COMPLETED'
    ) {
      window.open(
        `${window.location.origin}/motivators-report/${user._id}`,
        '_blank'
      );
    }
    if (user?.hvpPlusTest?.status && user.hvpPlusTest.status === 'COMPLETED') {
      window.open(
        `${window.location.origin}/job-hvp-view/${user._id}`,
        '_blank'
      );
    }
  };

  const isPreviousButtonDisabled =
    !totalResults || fetchUsersLoading || currentPage === 1;
  const isNextButtonDisabled =
    !totalResults || fetchUsersLoading || currentPage === totalPages;

  return (
    <div className="container text-black">
      <h1 className="text-center fw-bold mb-5">
        Invite Users for Personality Tests
      </h1>
      <Accordion
        defaultActiveKey={activeKey}
        className="mb-5"
        id="personality-test"
      >
        <Accordion.Item eventKey="0">
          <Accordion.Header
            className="fw-bold bg-primary rounded text-white"
            onClick={() => handleToggle('0')}
          >
            <div className="w-100 d-flex justify-content-between">
              <span className="fw-bold">Why Personality Tests Matter</span>
              {activeKey === '0' ? (
                <FaTimes
                  className="h4 cursor-pointer m-0 text-white "
                  onClick={() => handleToggle('0')}
                />
              ) : (
                <FaAngleDown
                  className="h4 cursor-pointer m-0 text-white "
                  onClick={() => handleToggle('0')}
                />
              )}
            </div>
          </Accordion.Header>
          <Accordion.Body className="bg-white rounded shadow-sm">
            <p className="mb-4 text-muted">
              Personality tests provide deep insights into a candidate's
              behavioral tendencies, leadership potential, teamwork
              capabilities, and overall fit within your organization.
              Understanding these aspects can help you make more informed hiring
              decisions, ensuring that candidates not only have the right skills
              but also the right personality traits to thrive in your team.
            </p>
            <div className="mb-4">
              <h3 className="h4 fw-semibold">DISC</h3>
              <p className="text-muted">
                Assesses a candidate's dominant behavioral style, helping you
                understand how they interact with others, handle conflict, and
                approach tasks. Great for evaluating leadership potential and
                communication styles.
              </p>
            </div>
            <div className="mb-4">
              <h3 className="h4 fw-semibold">Motivators</h3>
              <p className="text-muted">
                Identifies what drives a candidate, revealing their key
                motivators and whether they align with your company culture.
                This is crucial for ensuring long-term job satisfaction and
                engagement.
              </p>
            </div>
            <div>
              <h3 className="h4 fw-semibold">HVP Plus</h3>
              <p className="text-muted">
                Measures a candidate's values and decision-making processes,
                providing insights into their ethical stance, problem-solving
                approach, and alignment with organizational values. Ideal for
                assessing integrity and moral reasoning.
              </p>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {/* Invite New User Section */}
      <div className="card shadow-sm mb-5">
        <div className="card-body">
          <h2 className="h4 fw-bold mb-4">Invite New User</h2>
          <div className="mb-4">
            <label htmlFor="email" className="form-label fw-semibold">
              User Email
            </label>
            <ReactMultiEmail
              placeholder="Enter user email"
              emails={emails}
              onChange={(_emails) => {
                setEmails(_emails);
              }}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={index}>
                    {email}
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                    </span>
                  </div>
                );
              }}
            />
            <small className="form-text text-grey mt-2">
              Enter the email address where the personality test invitation will
              be sent. Ensure the email is correct to avoid any delivery issues.
            </small>
          </div>
          <div className="mb-4">
            {disc}
            <label
              htmlFor="tests"
              className="form-label fw-bold text-black w-100 text-center"
            >
              Select Personality Tests
            </label>
            <div className="d-flex gap-4 align-items-center justify-content-around m-4">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="DISC"
                  checked={disc}
                  id="disc-test"
                  onChange={(e) => {
                    setDisc(e.target.checked);
                  }}
                />
                <label className="form-check-label" htmlFor="disc-test">
                  DISC
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="Motivators"
                  id="motivators-test"
                  checked={motivators}
                  onChange={(e) => {
                    setMotivators(e.target.checked);
                  }}
                />
                <label className="form-check-label" htmlFor="motivators-test">
                  Motivators
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="HVP"
                  id="hvp-test"
                  checked={hvpPlus}
                  onChange={(e) => {
                    setHvpPlus(e.target.checked);
                  }}
                />
                <label className="form-check-label" htmlFor="hvp-test">
                  HVP Plus
                </label>
              </div>
            </div>
            <small className="form-text text-muted mt-2">
              By default, all three tests are selected. You can uncheck any test
              that you do not wish to include in the assessment. Each test
              provides unique insights into different aspects of the candidate's
              personality.
            </small>
          </div>
          <div className="text-center w-100">
            <button
              className="btn btn-primary"
              onClick={() => {
                if (emails.length === 0) {
                  toast.error('Email can not be blank');
                  return;
                }
                if (!disc && !motivators && !hvpPlus) {
                  toast.error('Please select atleast one Personality test');
                  return;
                }
                inviteUser();
              }}
            >
              {invLoading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-2"
                  />
                  Send Invitation
                </>
              ) : (
                'Send Invitation'
              )}
            </button>
          </div>
        </div>
      </div>

      {/* List of Invited Users */}
      <div className="card shadow-sm">
        <div className="card-body">
          <h2 className="h4 fw-bold mb-4">Invited Users</h2>
          <div className="table-responsive">
            <table className="table table-striped table-layout-custom">
              <thead>
                <tr>
                  <th scope="col" style={{ width: '20%' }}>
                    Email
                  </th>
                  <th scope="col" style={{ width: '20%' }}>
                    Date Invited
                  </th>
                  <th scope="col" style={{ width: '20%' }}>
                    Status
                  </th>
                  <th scope="col" style={{ width: '20%' }}>
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {invitedUsers.map((user, index) => {
                  return (
                    <tr key={user.email + index}>
                      <td style={{ width: '20%' }} className="text-truncate">
                        {user.email}
                      </td>
                      <td style={{ width: '20%' }}>
                        {moment(user.invitedAt).format('MMMM D, YYYY')}
                      </td>
                      <td style={{ width: '20%' }}>
                        <span
                          style={{ minWidth: 85 }}
                          className={`badge ${
                            user.overallStatus === 'COMPLETED'
                              ? 'bg-success'
                              : user.overallStatus === 'NOT_STARTED'
                                ? 'bg-warning'
                                : user.overallStatus === 'IN_PROGRESS'
                                  ? 'bg-primary'
                                  : ''
                          }`}
                        >
                          {formatText(user.overallStatus)}
                        </span>
                      </td>
                      <td>
                        {user.overallStatus === 'COMPLETED' && (
                          <a
                            href="#"
                            className="text-primary"
                            style={{ whiteSpace: 'nowrap' }}
                            onClick={(e) => handleOpenReport(e, user)}
                          >
                            View Report
                          </a>
                        )}
                        {user.overallStatus === 'NOT_STARTED' && (
                          <a
                            href="#"
                            className="text-primary ms-3"
                            style={{ whiteSpace: 'nowrap' }}
                            onClick={() => {
                              let testTypes = [];
                              if (user?.discTest?.status) {
                                testTypes.push('DISC');
                              }
                              if (user?.motivatorsTest?.status) {
                                testTypes.push('MOTIVATORS');
                              }
                              if (user?.hvpPlusTest?.status) {
                                testTypes.push('HVP_PLUS');
                              }
                              setReInvLoading(index);
                              resendInvite(user._id);
                            }}
                          >
                            {reInvLoading === index ? (
                              <>
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  className="me-2"
                                />
                                Resend Invite
                              </>
                            ) : (
                              'Resend Invite'
                            )}
                          </a>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mt-4">
            <span className="mb-2 mb-md-0">
              Showing {currentPage} of {totalPages} pages (Total {totalResults}{' '}
              results)
            </span>
            <div>
              <button
                className={`btn btn-outline-primary me-2 ${
                  isPreviousButtonDisabled ? 'custom-disabled' : ''
                }`}
                onClick={() => {
                  setCurrentPage(currentPage - 1);
                }}
                disabled={isPreviousButtonDisabled}
              >
                Previous
              </button>
              <button
                className={`btn btn-outline-primary ${
                  isNextButtonDisabled ? 'custom-disabled' : ''
                }`}
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                }}
                disabled={isNextButtonDisabled}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalityTest;
