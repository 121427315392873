import React from 'react';
import { Nav } from 'react-bootstrap';

const ChartSelector = ({ setSelector, selectorData }) => {
  return (
    <Nav as="ul" className="nav-tabs" role="tablist">
      {selectorData.map(({ title, value }) => (
        <Nav.Item as="li" className="">
          <Nav.Link
            key={value}
            onClick={() => {
              setSelector(value);
            }}
            className=""
            eventKey={value}
          >
            {title}
          </Nav.Link>
        </Nav.Item>
      ))}
    </Nav>
  );
};

export default ChartSelector;
