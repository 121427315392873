import React from 'react';
import { OverlayTrigger, Spinner } from 'react-bootstrap';
import { IoIosInformationCircle } from 'react-icons/io';

const WindowCard = ({ data, isLoading }) => {
  const { value1, title1, value2, title2, value3, title3, value4, title4 } =
    data;
  const { info1, info2, info3, info4 } = data;

  const classNames = [
    'pb-4',
    'pb-4 pt-4 pt-sm-0',
    'pt-4 pb-sm-0 pb-4 pe-3',
    'pt-4'
  ];
  const colors = ['#4885ED', '#145650', '#3385D6', '#B723AD'];

  const CardItem = ({ value, title, info, index }) => {
    return (
      <div className={`col-sm-6 ${classNames[index]}`}>
        <div className="job-icon d-flex justify-content-between">
          <div>
            <div className="d-flex align-items-center mb-1">
              <h2
                className="mb-0 lh-1"
                style={{ height: '2rem', color: colors[index] }}
              >
                {isLoading ? (
                  <Spinner
                    style={{ borderWidth: '0.1rem', scale: '1.2' }}
                    className="mb-3 ms-1"
                    variant="secondary"
                    size="sm"
                  />
                ) : value === undefined ? (
                  'N/A'
                ) : (
                  value
                )}
              </h2>
            </div>
            <span className="fs-14 d-block mb-2">{title}</span>
          </div>
          <OverlayTrigger
            overlay={
              <div
                style={{ maxWidth: '15rem' }}
                className=" background border mt-2 rounded shadow-sm p-2"
              >
                {info}
              </div>
            }
            placement="bottom"
          >
            <div
              className="cursor-pointer me-2 "
              style={{ height: 'fit-content' }}
            >
              <IoIosInformationCircle size={20} color="#769BC7" />
            </div>
          </OverlayTrigger>
        </div>
      </div>
    );
  };
  return (
    <div className="card">
      <div className="card-body">
        <div className="row separate-row">
          <CardItem value={value1} title={title1} index={0} info={info1} />
          <CardItem value={value2} title={title2} index={1} info={info2} />
          <CardItem value={value3} title={title3} index={2} info={info3} />
          <CardItem value={value4} title={title4} index={3} info={info4} />
        </div>
      </div>
    </div>
  );
};

export default WindowCard;
