import React, { Fragment, useContext, useState, useEffect } from 'react';
import logoDark from '../../../images/logo-dark.jpg';
import logoIcon from '../../../images/logo.ico';

import { Link, useLocation } from 'react-router-dom';
import { ThemeContext } from '../../../context/ThemeContext';
import { fetchLogos } from '../../../services/AuthService';

function updateMainWrapperClass() {
  setTimeout(() => {
    console.log('>>>>>> updateMainWrapperClass: 0');
    let mainwrapper = document.querySelector('#main-wrapper');
    if (mainwrapper.classList.contains('menu-toggle')) {
      console.log('>>>>>> updateMainWrapperClass: 1 remove');
      mainwrapper.classList.remove('menu-toggle');
    } else {
      console.log('>>>>>> updateMainWrapperClass: 2 add');
      mainwrapper.classList.add('menu-toggle');
    }
  }, 200);
}

const NavHader = ({ handleActive }) => {
  const location = useLocation();
  const path = window.location.pathname.split('/')[1];
  const superAdminUser =
    path === 'super-admin' && !!localStorage.getItem('superAdminUser');
  const [toggle, setToggle] = useState(false);
  const [showHorizontalLogo, setShowHorizontalLogo] = useState(true);
  const [showSquareLogo, setShowSquareLogo] = useState(false);
  const [horizontalLogo, setHorizontalLogo] = useState(
    superAdminUser
      ? logoDark
      : localStorage.getItem('horizontalLogo') || logoDark
  );
  const [squareLogo, setSquareLogo] = useState(
    superAdminUser ? logoIcon : localStorage.getItem('squareLogo') || logoIcon
  );
  const { navigationHader, openMenuToggle, background } =
    useContext(ThemeContext);
  const logoSrc = logoDark;

  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const orgId = userDetails?.user?.organizationId;

  const toggleLogoImages = () => {
    if (showHorizontalLogo) {
      setShowSquareLogo(true);
      setShowHorizontalLogo(false);
    } else if (showSquareLogo) {
      setShowSquareLogo(false);
      setShowHorizontalLogo(true);
    }
  };

  useEffect(() => {
    if (!window.location.pathname.includes('logo-customisation')) {
      fetchLogos();
    }
  }, []);

  useEffect(() => {
    let mainWrapper = document.querySelector('#main-wrapper');
    if (!mainWrapper.classList.contains('menu-toggle')) {
      if (showSquareLogo) {
        toggleLogoImages();
      }
    }
  }, [location]);

  useEffect(() => {
    const handleLogoUpdate = () => {
      const isSuperAdminRoute = location.pathname.includes('super-admin');
      const superAdminUser = !!localStorage.getItem('superAdminUser');

      setHorizontalLogo(
        isSuperAdminRoute && superAdminUser
          ? logoDark
          : localStorage.getItem('horizontalLogo') || logoDark
      );
      setSquareLogo(
        isSuperAdminRoute && superAdminUser
          ? logoIcon
          : localStorage.getItem('squareLogo') || logoIcon
      );
    };

    window.addEventListener('logoUpdated', handleLogoUpdate);

    return () => {
      window.removeEventListener('logoUpdated', handleLogoUpdate);
    };
  }, [location]);

  useEffect(() => {
    if (path === 'super-admin') {
      window.dispatchEvent(new Event('logoUpdated'));
    }
  }, [path]);

  return (
    <>
      <style>
        {`
          @media (max-width: 767px) {
            .logo-horizontal {
              display: none !important;
            }
            .logo-square {
              display: block !important;
            }
          }
        `}
      </style>
      <div className="nav-header" style={{ zIndex: '9999' }}>
        <Link
          to={
            location.pathname.includes('super-admin')
              ? '/super-admin'
              : '/dashboard'
          }
          className="brand-logo"
          style={{ justifyContent: 'center' }}
          onClick={() => handleActive('Dashboard')}
        >
          <img
            className={`h-50 logo-horizontal ${
              !showHorizontalLogo ? 'd-none' : ''
            }`}
            src={horizontalLogo}
            alt="Horizontal Logo"
          />
          <img
            className={`h-50 logo-square ${showSquareLogo ? '' : 'd-none'}`}
            src={squareLogo}
            alt="square Logo"
          />
        </Link>
        <div
          className="nav-control"
          onClick={() => {
            setToggle(!toggle);
            toggleLogoImages();
            updateMainWrapperClass();
          }}
        >
          <div className={`hamburger ${toggle ? 'is-active' : ''}`}>
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavHader;
