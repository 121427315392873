import React, { useEffect, useState } from 'react';
import { Spinner, Tab } from 'react-bootstrap';
import ChartLabel from './chartLabel';
import ChartSelector from './chartSelector';
import { Line } from 'react-chartjs-2';

const ApplicationTrendsChart = () => {
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [timePeriod, setTimePeriod] = useState('daily');

  const selectorData = [
    { title: 'Daily', value: 'daily' },
    { title: 'Weekly', value: 'weekly' },
    { title: 'Monthly', value: 'monthly' }
  ];

  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const orgId = userDetails?.user?.organizationId;

  const fetchData = async () => {
    setIsLoading(true);
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const accessToken = userDetails?.session.accessToken || null;
    if (!accessToken) {
      throw new Error('Access token not found');
    }
    const URL = `${process.env.REACT_APP_DEV_API}/v1/organization/
		${orgId}/analytics/applications-count/${timePeriod}`;

    try {
      const response = await fetch(URL, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      });
      if (!response.ok) {
        throw new Error('Error getting reponse');
      }
      const body = await response.json();
      const data = body.data;
      setChartData(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log('Could not fetch chart data', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [timePeriod]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    if (timePeriod == 'weekly') {
      return 'week ' + dateString.split('-')[1];
    } else if (timePeriod === 'monthly') {
      return month;
    }

    return `${day} ${month}`;
  }

  const countData = chartData.map(({ count }) => count);
  const timeData = chartData.map(({ period }) => formatDate(period));

  const data = {
    defaultFontFamily: 'Poppins',
    labels: timeData,
    datasets: [
      {
        label: ' Applications',
        backgroundColor: 'rgba(82, 177, 65, 0)',
        borderColor: '#4885ED',
        data: countData,
        borderWidth: 6,
        tension: 0.4
      }
    ]
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      point: {
        radius: 2
      }
    },
    plugins: {
      legend: false
    },

    scales: {
      y: {
        grid: {
          color: 'rgba(89, 59, 219,0.1)',
          drawBorder: true
        },
        ticks: {
          fontSize: 14,
          fontColor: '#6E6E6E',
          fontFamily: 'Poppins'
        }
      },
      x: {
        //FontSize: 40,
        grid: {
          display: false,
          zeroLineColor: 'transparent'
        },
        ticks: {
          fontSize: 14,
          stepSize: 5,
          fontColor: '#6E6E6E',
          fontFamily: 'Poppins'
        }
      }
    },
    tooltips: {
      enabled: false,
      mode: 'index',
      intersect: false,
      titleFontColor: '#888',
      bodyFontColor: '#555',
      titleFontSize: 12,
      bodyFontSize: 15,
      backgroundColor: 'rgba(256,256,256,0.95)',
      displayColors: true,
      xPadding: 10,
      yPadding: 7,
      borderColor: 'rgba(220, 220, 220, 0.9)',
      borderWidth: 2,
      caretSize: 6,
      caretPadding: 10
    }
  };

  return (
    <>
      <Tab.Container defaultActiveKey={selectorData[0].value}>
        <div className="card">
          <div className="card-header border-0 pb-0 flex-wrap">
            <h4 className="fs-20 font-w500 mb-1">Application Trends</h4>
            <div className="card-action coin-tabs mt-3 mt-sm-0">
              <ChartSelector
                setSelector={setTimePeriod}
                selectorData={selectorData}
              />
            </div>
          </div>
          <div className="card-body">
            <div className="pb-4 d-flex flex-wrap">
              <ChartLabel title={'Applications'} color={'#4885ED'} />
            </div>
            <Tab.Content>
              <div style={{ height: '250px' }}>
                {isLoading ? (
                  <div className="h-100 d-flex align-items-center justify-content-center">
                    <Spinner
                      animation="border mb-3"
                      style={{ color: '#858585' }}
                    />
                  </div>
                ) : (
                  <>
                    {chartData?.length !== 0 ? (
                      <Line options={options} data={data} />
                    ) : (
                      <h5>No Data Found</h5>
                    )}
                  </>
                )}
              </div>
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
    </>
  );
};
export default ApplicationTrendsChart;
