import {
  LOADING_TOGGLE_ACTION,
  LOGIN_CONFIRMED_ACTION,
  LOGIN_FAILED_ACTION,
  LOGOUT_ACTION,
  SIGNUP_CONFIRMED_ACTION,
  SIGNUP_FAILED_ACTION,
  GENERATE_OTP_ACTION,
  PASSWORD_RESET_ACTION,
  PASSWORD_RESET_FALSE,
  GENERATE_OTP_FAILED_ACTION,
  PASSWORD_RESET_FAILED_ACTION,
  PASSWORD_CHANGE_ACTION,
  PASSWORD_CHANGE_FAILED_ACTION
} from '../actions/AuthActions';

const initialState = {
  auth: {
    email: '',
    idToken: '',
    localId: '',
    expiresIn: '',
    refreshToken: ''
  },
  passChanged: false,
  passReset: false,
  otpGenerated: false,
  errorMessage: '',
  successMessage: '',
  showLoading: false
};

export function AuthReducer(state = initialState, action) {
  console.log('action', action);
  if (action.type === SIGNUP_CONFIRMED_ACTION) {
    return {
      ...state,
      auth: action.payload,
      errorMessage: '',
      successMessage: 'Signup Successfully Completed',
      showLoading: false
    };
  }
  if (action.type === LOGIN_CONFIRMED_ACTION) {
    console.log('reducer', 'LOGIN_CONFIRMED_ACTION', action.payload);
    return {
      ...state,
      auth: {
        email: action.payload?.user?.email,
        localId: action.payload?.user?._id,
        idToken: action.payload?.session?.accessToken,
        refreshToken: action.payload?.session?.refreshToken
      },
      errorMessage: '',
      successMessage: 'Login Successfully Completed',
      showLoading: false
    };
  }
  if (action.type === GENERATE_OTP_ACTION) {
    console.log('reducer', 'GENERATE_OTP_ACTION', action.payload);
    return {
      ...state,
      otpGenerated: action.payload,
      errorMessage: '',
      successMessage: 'OTP Generated Successfully',
      showLoading: false
    };
  }
  if (action.type === PASSWORD_RESET_ACTION) {
    console.log('reducer', 'PASSWORD_RESET_ACTION', action.payload);
    return {
      ...state,
      passReset: action.payload,
      errorMessage: '',
      successMessage: 'Password Change Successfull',
      showLoading: false
    };
  }

  if (action.type === PASSWORD_RESET_FALSE) {
    console.log('reducer', 'PASSWORD_RESET_FALSE');
    return {
      ...state,
      passReset: false,
      otpGenerated: false
    };
  }

  if (action.type === PASSWORD_CHANGE_ACTION) {
    console.log('reducer', 'PASSWORD_CHANGE_ACTION', action.payload);
    return {
      ...state,
      errorMessage: '',
      passChanged: action.payload,
      successMessage: 'Password Change Successfull',
      showLoading: false
    };
  }

  if (action.type === LOGOUT_ACTION) {
    return {
      ...state,
      errorMessage: '',
      successMessage: '',
      auth: {
        email: '',
        idToken: '',
        localId: '',
        expiresIn: '',
        refreshToken: ''
      }
    };
  }

  if (
    action.type === SIGNUP_FAILED_ACTION ||
    action.type === LOGIN_FAILED_ACTION ||
    action.type === GENERATE_OTP_FAILED_ACTION ||
    action.type === PASSWORD_RESET_FAILED_ACTION ||
    action.type === PASSWORD_CHANGE_FAILED_ACTION
  ) {
    console.log('reducer failed error', action.payload);
    return {
      ...state,
      errorMessage: action.payload,
      successMessage: '',
      showLoading: false
    };
  }
  console.log(action, action);

  if (action.type === LOADING_TOGGLE_ACTION) {
    console.log('LOADING_TOGGLE_ACTION', action.payload);
    return {
      ...state,
      showLoading: action.payload
    };
  }
  return state;
}
