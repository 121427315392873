import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash, FaTimes } from 'react-icons/fa';

const CreateAdminModal = ({ onAdminCreated, orgId }) => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [role, setRole] = useState('ADMIN');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setShowModal(false);
      }
    };

    if (showModal) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [showModal]);

  const handleValidationError = (errorMessage) => {
    if (errorMessage.includes('name')) {
      return 'Invalid name provided.';
    } else if (errorMessage.includes('email')) {
      return 'Invalid email provided.';
    } else if (errorMessage.includes('password')) {
      return 'Invalid password provided.';
    }
    return 'Validation error occurred.';
  };

  const handleClose = () => {
    if (!loading) {
      setShowModal(false);
    }
  };

  const handleShow = () => {
    setShowModal(true);
  };

  const createAdmin = async () => {
    let hasError = false;

    if (!name.trim()) {
      toast.error("Admin name can't be empty");
      hasError = true;
    } else if (!email.trim()) {
      toast.error("Email can't be empty");
      hasError = true;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      toast.error('Email is invalid');
      hasError = true;
    } else if (!password.trim()) {
      toast.error("Password can't be empty");
      hasError = true;
    } else if (password.length < 6) {
      toast.error('Password must be at least 6 characters long');
      hasError = true;
    }

    if (hasError) {
      return;
    }

    if (!orgId) {
      toast.error('Organization ID is missing');
      return;
    }

    const superAdminUser = JSON.parse(localStorage.getItem('superAdminUser'));
    const accessToken = superAdminUser?.session?.accessToken || null;

    if (!accessToken) {
      throw new Error('Access token not found in local storage');
    }

    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/user`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ name, email, password, role })
        }
      );

      const data = await response.json();

      if (response.ok) {
        // onAdminCreated(data.data);
        setName('');
        setEmail('');
        setPassword('');
        handleClose();
        toast.success('Admin created successfully');
        if (onAdminCreated) {
          onAdminCreated();
        }
        navigate(`/super-admin/edit-organisation/${orgId}`);
      } else {
        if (
          data.message &&
          data.message.includes('E11000 duplicate key error')
        ) {
          if (data.message.includes('name_1 dup key')) {
            toast.error(`An Admin with the name "${name}" already exists.`);
          } else if (data.message.includes('email_1 dup key')) {
            toast.error(`An Admin with the email "${email}" already exists.`);
          } else {
            toast.error('This admin already exists.');
          }
        } else {
          const errorMessage = handleValidationError(data.message);
          toast.error(errorMessage);
        }
      }
    } catch (error) {
      toast.error(error.message || 'An unknown error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        variant="primary"
        onClick={handleShow}
        style={{ marginTop: '2vh' }}
        className="btn-sm"
      >
        Add Admin
      </Button>

      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop={loading ? 'static' : true}
        keyboard={!loading}
        centered
        style={{ marginTop: '3vh' }}
      >
        <div>
          <Modal.Header
            closeButton={false}
            className="border-0 position-relative"
          ></Modal.Header>
        </div>
        <div>
          <Modal.Title
            style={{ paddingLeft: '1.875rem' }}
            className="d-flex justify-content-between align-items-center w-100 mt-0 pe-4"
          >
            Create a New Admin
            <FaTimes
              onClick={handleClose}
              style={{ cursor: 'pointer' }}
            ></FaTimes>
          </Modal.Title>
        </div>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label class="form-label font-w600">
                Name<span className="text-danger scale5 ms-2">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="John Doe"
                value={name}
                onChange={(e) => setName(e.target.value)}
                disabled={loading}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label class="form-label font-w600">
                Email <span className="text-danger scale5 ms-2">*</span>
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="johndoe@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={loading}
              />
            </Form.Group>
            <Form.Group className="mb-3 position-relative">
              <Form.Label class="form-label font-w600">
                Password <span className="text-danger scale5 ms-2">*</span>
              </Form.Label>
              <div>
                <Form.Control
                  style={{ paddingRight: '2.2rem' }}
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Enter password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  disabled={loading}
                ></Form.Control>
                <div
                  className="position-absolute ms-3 mt-1 d-flex align-items-center justify-content-center fa-eye-icon"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <FaEye size="20px" />
                  ) : (
                    <FaEyeSlash size="21px" />
                  )}
                </div>
              </div>
            </Form.Group>
            {/* <Form.Group className="mb-3">
              <Form.Label>Role</Form.Label>
              <Form.Control
                type="text"
                placeholder="Admin"
                value={role}
                onChange={(e) => setRole(e.target.value)}
                disabled={loading}
              />
            </Form.Group> */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-danger light me-3"
            onClick={handleClose}
            disabled={loading}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={createAdmin}
            disabled={loading}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '150px'
            }}
          >
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="ms-2">Creating...</span>
              </>
            ) : (
              'Create Admin'
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateAdminModal;
