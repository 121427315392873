import React, { useState, useContext, useEffect } from 'react';
import { ThemeContext } from '../../../context/ThemeContext';
import axios from 'axios';
import WindowCard from './ui/windowCard';
import ApplicationLineChart from './ui/charts/LineChartApplication.js';
import CanvasPieChartTab from './ui/charts/PieChartApplicationDistribution.js';
import ApplicationStatusBarChart from './ui/charts/BarChartApplicationStatus.js';
import PreAssesmentStatusChart from './ui/charts/PreAssesmentDistribution.js';
import AverageStageDurationChart from './ui/charts/AverageStageDurationChart.js';
import AverageStageAppCountChart from './ui/charts/AverageStageAppCountChart.js';
import currencySymbols from 'currency-symbol-map';
import { formatToCurrency } from '../../utils/CommonFunctions.js';

const Home = () => {
  const { changeBackground } = useContext(ThemeContext);
  const [jobPostingCount, setJobPostingCount] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const orgId = userDetails?.user?.organizationId;
  const [currencySymbol, setCurrencySymbol] = useState('₹');

  useEffect(() => {
    const storedCurrency = localStorage.getItem('currency');
    if (storedCurrency) {
      const symbol = currencySymbols(storedCurrency) || '₹';
      setCurrencySymbol(symbol);
    }
  }, []);

  useEffect(() => {
    changeBackground({ value: 'light', label: 'Light' });
    fetchJobPostingsCount();
  }, []);

  const accessToken =
    JSON.parse(localStorage.getItem('userDetails'))?.session.accessToken ||
    null;

  const fetchJobPostingsCount = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/analytics/job-postings-count`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        }
      );
      setJobPostingCount(response.data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching JobPostingsCount:', error);
    }
  };

  const WindowCardData1 = {
    title1: 'Total Jobs',
    value1: jobPostingCount.totalJobsCount,
    info1: 'The total number of job postings created by your organization.',

    title2: 'Active Jobs',
    value2: jobPostingCount.activeJobsCount,
    info2:
      'The number of job postings currently active and accepting applications.',

    title3: 'Accepted Applications Per Job',
    value3: jobPostingCount.acceptedApplicationsPerJob,
    info3: 'The average number of accepted applications per job posting.',

    title4: 'Application per Job',
    value4: jobPostingCount.applicationsPerJob,
    info4: 'The average number of applications received per job posting.'
  };

  const WindowCardData2 = {
    title1: 'Total Applications',
    value1: jobPostingCount.totalApplicationsCount,
    info1: 'The total number of applications received across all job postings.',

    title2: 'In process Applications',
    value2:
      jobPostingCount.totalApplicationsCount -
      jobPostingCount.acceptedApplicationsCount -
      jobPostingCount.rejectedApplicationsCount,
    info2: 'The number of applications currently being processed.',

    title3: 'Accepted Applications',
    value3: jobPostingCount.acceptedApplicationsCount,
    info3:
      'The total number of applications accepted for further recruitment stages.',

    title4: 'Rejected Applications',
    value4: jobPostingCount.rejectedApplicationsCount,
    info4: 'The total number of applications that have been rejected.'
  };

  const WindowCardData3 = {
    title1: 'Pre Assesment Pass Rate',
    value1: jobPostingCount.preAssessmentPassRate,
    info1: 'The percentage of candidates who passed the pre-assessment stage.',

    title2: 'Candidate Drop off Rate',
    value2: jobPostingCount.candidateDropOffRate,
    info2:
      'The percentage of candidates who dropped off during the recruitment process.',

    title3: 'Amount Saved',
    value3:
      jobPostingCount.amountSaved &&
      `${formatToCurrency(jobPostingCount.amountSaved)}`,
    info3:
      'The total cost saved by automating initial recruitment steps (Rs. 250 per applicant).',

    title4: 'Total Jobs Views',
    value4: jobPostingCount.totalViews,
    info4: 'The total number of views across all job postings.'
  };

  const WindowCardData4 = {
    title1: 'Male Applicants',
    value1: jobPostingCount.genderDiversity?.male,
    info1: 'The total number of male candidates who applied for jobs.',

    title2: 'Female Applicants',
    value2: jobPostingCount.genderDiversity?.female,
    info2: 'The total number of female candidates who applied for jobs.',

    title3: 'Views Per Job Posting',
    value3: jobPostingCount.viewsPerJobPosting,
    info3: 'The average number of views each job posting receives.',

    title4: 'Application Per View',
    value4: jobPostingCount.applicationsPerView,
    info4:
      'The ratio of applications received to the number of job posting views.'
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-6">
          <WindowCard data={WindowCardData1} isLoading={isLoading} />
        </div>
        <div className="col-xl-6">
          <WindowCard data={WindowCardData2} isLoading={isLoading} />
        </div>

        <div className="col-xl-12">
          <ApplicationLineChart />
        </div>

        <div className="col-xl-6">
          <WindowCard data={WindowCardData3} isLoading={isLoading} />
        </div>
        <div className="col-xl-6">
          <WindowCard data={WindowCardData4} isLoading={isLoading} />
        </div>

        <div className="col-xl-12">
          <ApplicationStatusBarChart />
        </div>

        <div className="col-xl-6">
          <div className="col-xl-12">
            <CanvasPieChartTab />
          </div>
          <div className="col-xl-12">
            <AverageStageDurationChart
              chartData={jobPostingCount.averageStageDurations}
              isLoading={isLoading}
            />
          </div>
        </div>

        <div className="col-xl-6">
          <div className="col-xl-12">
            <PreAssesmentStatusChart
              chartData={jobPostingCount.preAssessmentDistribution}
              isLoading={isLoading}
            />
          </div>
          <div className="col-xl-12">
            <AverageStageAppCountChart chartData={{}} isLoading={isLoading} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
