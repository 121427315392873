import React, { useEffect, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
// import { Chart } from "react-chartjs-2";
import { useNavigate, useParams } from 'react-router-dom';
import { Chart, registerables } from 'chart.js';
import {
  BsPaletteFill,
  BsCurrencyDollar,
  BsPersonFill,
  BsShieldFill,
  BsHeartFill,
  BsBoxSeam,
  BsLightbulbFill
} from 'react-icons/bs';
import html2pdf from 'html2pdf.js';
import './motivators-report.css';
import PDFDownloadButton from '../AppsMenu/CustomComponents/PDFDownloadButton';

const rankingTitles = {
  1: 'Top Motivator',
  2: '2nd Place',
  3: '3rd Place',
  4: '4th Place',
  5: '5th Place',
  6: '6th Place',
  7: '7th Place',
  8: '8th Place',
  9: '9th Place',
  10: '10th Place'
};

const capitalizeFirstLetter = (string) => {
  if (typeof string !== 'string' || string.length === 0) {
    return string;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const MotivatorsReport = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [analysis, setAnalysis] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  // References to the canvas elements
  const contentRef = useRef();
  const { testId } = useParams();

  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const orgId = userDetails?.user?.organizationId;

  useEffect(() => {
    console.log('pdfLoading ', pdfLoading);
  }, [pdfLoading]);

  useEffect(() => {
    fetchAnalysis();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 940);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!analysis) {
      return;
    }

    // Destroy existing chart instances to avoid "Canvas is already in use" errors
    if (window.motivatorsRankingChart instanceof Chart) {
      window.motivatorsRankingChart.destroy();
    }

    if (window.populationScoresChart instanceof Chart) {
      window.populationScoresChart.destroy();
    }

    // Bar Chart for Motivators Ranking
    const motivatorsRankingCtx = document
      .getElementById('motivatorsRankingChart')
      .getContext('2d');
    const gradientMotivators = motivatorsRankingCtx.createLinearGradient(
      0,
      0,
      0,
      400
    );
    gradientMotivators.addColorStop(0, 'rgba(54, 162, 235, 0.6)');
    gradientMotivators.addColorStop(1, 'rgba(153, 102, 255, 0.6)');

    // Save the chart instance to window object for later destruction
    window.motivatorsRankingChart = new Chart(motivatorsRankingCtx, {
      type: 'bar',
      data: {
        labels: [
          ...convertData(analysis?.executive_summary?.scores_rankings).map(
            (item) => item.title
          )
        ],
        datasets: [
          {
            label: 'Motivator Scores',
            data: [
              ...convertData(analysis?.executive_summary?.scores_rankings).map(
                (item) => item.score
              )
            ],
            backgroundColor: gradientMotivators,
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 2,
            borderRadius: 10,
            hoverBackgroundColor: 'rgba(54, 162, 235, 0.8)',
            hoverBorderColor: 'rgba(54, 162, 235, 1)'
          }
        ]
      },
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              font: {
                size: 12
              }
            },
            grid: {
              color: 'rgba(0, 0, 0, 0.05)'
            }
          },
          x: {
            ticks: {
              font: {
                size: isMobile ? 6 : 12
              }
            },
            grid: {
              color: 'rgba(0, 0, 0, 0.05)'
            }
          }
        },
        plugins: {
          legend: {
            display: false
          }
        }
      }
    });

    // Bar Chart for Population Scores
    const populationScoresCtx = document
      .getElementById('populationScoresChart')
      .getContext('2d');
    const gradientPopulation = populationScoresCtx.createLinearGradient(
      0,
      0,
      0,
      400
    );
    gradientPopulation.addColorStop(0, 'rgba(255, 159, 64, 0.6)');
    gradientPopulation.addColorStop(1, 'rgba(255, 99, 132, 0.6)');

    // Save the chart instance to window object for later destruction
    window.populationScoresChart = new Chart(populationScoresCtx, {
      type: 'bar',
      data: {
        labels: [
          ...Object.entries(analysis?.executive_summary?.scores_rankings).map(
            ([key, value]) => key.charAt(0).toUpperCase() + key.slice(1)
          )
        ],
        datasets: [
          {
            label: 'Population Scores',
            data: [
              ...Object.entries(
                analysis?.executive_summary?.scores_rankings
              ).map(([key, value]) => value.score)
            ],
            backgroundColor: gradientPopulation,
            borderColor: 'rgba(255, 159, 64, 1)',
            borderWidth: 2,
            borderRadius: 10,
            hoverBackgroundColor: 'rgba(255, 159, 64, 0.8)',
            hoverBorderColor: 'rgba(255, 159, 64, 1)'
          }
        ]
      },
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              color: 'rgba(0, 0, 0, 0.05)'
            }
          },
          x: {
            grid: {
              color: 'rgba(0, 0, 0, 0.05)'
            }
          }
        },
        plugins: {
          legend: {
            display: false
          }
        }
      }
    });
  }, [analysis]);

  const fetchAnalysis = async () => {
    try {
      const accessToken = userDetails?.session.accessToken || null;
      if (!accessToken) {
        throw new Error('Not Authorized');
      }

      const apiUrl = `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/personality-test/${testId}/response?motivators=true`;
      console.log(`Request URL: ${apiUrl}`);
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      };

      const response = await fetch(apiUrl, options);

      if (!response.ok) {
        navigate('/page-error-404');
        setLoading(false);
        return;
      }

      const data = await response.json();
      console.log('Analysis Data:', data);
      setAnalysis(data.motivatorsTest.analysis);
      setLoading(false);
    } catch (error) {
      navigate('/page-error-404');
      console.log('Error fetching analysis', error);
      setLoading(false);
    }
  };

  const handleDownloadPDF = async () => {
    setPdfLoading(true); // Show the loader before starting the download
    const element = contentRef.current;

    // const options = {
    //   margin: 0,
    //   filename: "motivators-report.pdf",
    //   image: { type: "jpeg", quality: 1.0 },
    //   html2canvas: {
    //     scale: 4,
    //     useCORS: true,
    //     backgroundColor: "#ffffff", // Set to white to avoid any fading or transparency issues
    //     logging: true, // Enable logging to troubleshoot rendering issues
    //     removeContainer: true, // Helps in removing the extra div container created by html2canvas
    //   },
    //   jsPDF: { unit: "in", format: "a4", orientation: "landscape" },
    // };

    const options = {
      margin: 0,
      filename: 'motivators-report.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2, letterRendering: true, logging: true },
      jsPDF: { unit: 'mm', format: [520, 397], orientation: 'portrait' },
      pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
    };
    try {
      await html2pdf().from(element).set(options).save(); // Wait for the PDF to be generated and downloaded
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
      setPdfLoading(false); // Ensure the loader is hidden after the process is complete
    }
  };

  const convertData = (data) => {
    return Object.entries(data)
      .map(([key, value]) => ({
        title: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize the first letter
        score: value.score,
        rankingTitle: rankingTitles[value.ranking] || 'Unknown Rank', // Lookup the rank title
        ranking: value.ranking, // Store the numeric rank for sorting
        description: value.description
      }))
      .sort((a, b) => a.ranking - b.ranking); // Sort by numeric rank
  };

  const iconMapping = {
    aesthetic: (
      <div
        className="motivator-icon bg-secondary text-white rounded-circle me-3 d-flex align-items-center justify-content-center"
        style={
          isMobile
            ? {
                padding: '14px',
                fontSize: '24px'
              }
            : {
                width: '60px',
                height: '60px',
                fontSize: '24px'
              }
        }
      >
        <BsPaletteFill />
      </div>
    ),
    economic: (
      <div
        className="motivator-icon bg-warning text-white rounded-circle me-3 d-flex align-items-center justify-content-center"
        style={
          isMobile
            ? {
                padding: '12px 12px',
                fontSize: '24px'
              }
            : {
                width: '60px',
                height: '60px',
                fontSize: '24px'
              }
        }
      >
        <BsCurrencyDollar />
      </div>
    ),
    individualistic: (
      <div
        className="motivator-icon bg-primary text-white rounded-circle me-3 d-flex align-items-center justify-content-center"
        style={
          isMobile
            ? {
                padding: '14px',
                fontSize: '24px'
              }
            : {
                width: '60px',
                height: '60px',
                fontSize: '24px'
              }
        }
      >
        <BsPersonFill />
      </div>
    ),
    power: (
      <div
        className="motivator-icon bg-danger text-white rounded-circle me-3 d-flex align-items-center justify-content-center"
        style={
          isMobile
            ? {
                padding: '14px',
                fontSize: '24px'
              }
            : {
                width: '60px',
                height: '60px',
                fontSize: '24px'
              }
        }
      >
        <BsShieldFill />
      </div>
    ),
    altruistic: (
      <div
        className="motivator-icon bg-success text-white rounded-circle me-3 d-flex align-items-center justify-content-center"
        style={
          isMobile
            ? {
                padding: '14px',
                fontSize: '24px'
              }
            : {
                width: '60px',
                height: '60px',
                fontSize: '24px'
              }
        }
      >
        <BsHeartFill />
      </div>
    ),
    regulatory: (
      <div
        className="motivator-icon bg-dark text-white rounded-circle me-3 d-flex align-items-center justify-content-center"
        style={
          isMobile
            ? {
                padding: '14px',
                fontSize: '24px'
              }
            : {
                width: '60px',
                height: '60px',
                fontSize: '24px'
              }
        }
      >
        <BsBoxSeam />
      </div>
    ),
    theoretical: (
      <div
        className="motivator-icon bg-info text-white rounded-circle me-3 d-flex align-items-center justify-content-center"
        style={
          isMobile
            ? {
                padding: '14px',
                fontSize: '24px'
              }
            : {
                width: '60px',
                height: '60px',
                fontSize: '24px'
              }
        }
      >
        <BsLightbulbFill />
      </div>
    )
  };

  return (
    <div>
      <style>
        {` .helper-text{
            font-style: italic;
            font-size: 1rem;
            color: #3b82f6;
            margin-top: 0.5rem;
            background-color: rgba(59, 130, 246, 0.1);
            padding: 8px;
            border-radius: 6px;
            text-align: center;
        }
        `}
      </style>

      <div className=" bg-sub-section  text-dark pb-5">
        {loading ? (
          <div className="d-flex flex-column align-items-center justify-content-center gap-5 mt-5 pt-5 ">
            <div className="mt-5 mb-5 pt-5 pb-4">
              <div className="mt-5"></div>
            </div>
            <Spinner animation="border mb-3" style={{ color: '#858585' }} />
            <div className="mt-5 mb-5 pt-5 pb-4">
              <div className="mt-5"></div>
            </div>
          </div>
        ) : (
          <>
            <div className="w-100 d-flex pt-5 align-items-center justify-content-center">
              <button
                onClick={() => navigate('/personality-test')}
                className="btn btn-primary btn-lg shadow-lg animate-fadeIn mb-1 back-button rounded-pill"
              >
                Back
              </button>
            </div>
            <div ref={contentRef} className="container py-5">
              {/* <!-- Header Section --> */}
              <header className="mb-5 text-center">
                <h1 className="display-4 fw-bold text-black animate-fadeIn">
                  MOTIVATORS REPORT
                </h1>

                <h2 className="display-6 text-muted animate-fadeIn mt-3">
                  {analysis?.executive_summary?.motivators_style}
                </h2>
                <p
                  className="lead text-muted animate-fadeIn mt-4 mx-auto"
                  style={{ maxWidth: '700px' }}
                >
                  {analysis?.executive_summary?.motivators_style_description}
                </p>
              </header>

              {/* <!-- Motivator Scores & Rankings Section -->
        <!-- Motivator Scores & Rankings Section --> */}
              <section className="bg-sub-section rounded-3xl shadow-lg p-5 mb-5 animate-fadeIn">
                <h3 className="section-header">
                  Motivator Scores & Rankings
                  <span
                    className="position-absolute"
                    style={{
                      bottom: '-10px',
                      width: '120px',
                      height: '4px',
                      left: 'calc(50% - 60px)',

                      backgroundColor: '#3b82f6',
                      zIndex: 1
                    }}
                  />
                </h3>
                <p class="helper-text text-center">
                  The section provides a snapshot of how your core motivators
                  compare to one another. It helps you understand which drivers
                  are most dominant in your life and how they rank relative to
                  each other.
                </p>
                <div className="row g-4">
                  {/* <!-- Chart Area --> */}
                  <div className="col-md-6 d-flex align-items-center justify-content-center">
                    <div className="bar-chart-container p-4 position-relative d-flex align-items-center justify-content-center">
                      <canvas id="motivatorsRankingChart"></canvas>
                    </div>
                  </div>

                  {/* <!-- Motivator Details Area --> */}
                  <div className="col-md-6">
                    <div className="row g-4">
                      {analysis &&
                        convertData(
                          analysis?.executive_summary?.scores_rankings
                        ).map((item, index) => {
                          // console.log(item);
                          // if (index == 1)
                          return (
                            <div className="col-12" key={index}>
                              <div className="content-card position-relative p-4 bg-white rounded-3 shadow-sm">
                                <div className="d-flex align-items-center">
                                  {iconMapping[item.title.toLowerCase()]}
                                  <div>
                                    <h4 className="content-title mb-1">
                                      {item.rankingTitle}: {item.title}
                                    </h4>
                                    <p className="mb-0 text-muted">
                                      Score: {item.score} | Ranking:{' '}
                                      {item.ranking}
                                    </p>
                                    <p class="helper-text">
                                      {item.description}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                      {/* <div className="col-12">
                      <div className="content-card position-relative p-4 bg-white rounded-3 shadow-sm">
                        <div className="d-flex align-items-center">
                          <div
                            className="motivator-icon bg-warning text-white rounded-circle me-3 d-flex align-items-center justify-content-center"
                            style={{
                              width: "60px",
                              height: "60px",
                              fontSize: "24px",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="currentColor"
                              class="bi bi-currency-dollar"
                              viewBox="0 0 16 16"
                            >
                              <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73z" />
                            </svg>
                          </div>
                          <div>
                            <h4 className="content-title mb-1">
                              2nd Place: Economic
                            </h4>
                            <p className="mb-0 text-muted">
                              Score: 80 | Ranking: 2
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="content-card position-relative p-4 bg-white rounded-3 shadow-sm">
                        <div className="d-flex align-items-center">
                          <div
                            className="motivator-icon bg-danger text-white rounded-circle me-3 d-flex align-items-center justify-content-center"
                            style={{
                              width: "60px",
                              height: "60px",
                              fontSize: "24px",
                            }}
                          >
                            <i className="bi bi-shield-lock-fill"></i>
                          </div>
                          <div>
                            <h4 className="content-title mb-1">
                              3rd Place: Power
                            </h4>
                            <p className="mb-0 text-muted">
                              Score: 75 | Ranking: 3
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="content-card position-relative p-4 bg-white rounded-3 shadow-sm">
                        <div className="d-flex align-items-center">
                          <div
                            className="motivator-icon bg-info text-white rounded-circle me-3 d-flex align-items-center justify-content-center"
                            style={{
                              width: "60px",
                              height: "60px",
                              fontSize: "24px",
                            }}
                          >
                            <i className="bi bi-lightbulb-fill"></i>
                          </div>
                          <div>
                            <h4 className="content-title mb-1">
                              4th Place: Theoretical
                            </h4>
                            <p className="mb-0 text-muted">
                              Score: 70 | Ranking: 4
                            </p>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    </div>
                  </div>
                </div>
              </section>

              {/* <!-- Motivator Descriptions Section -->
        <!-- Motivator Descriptions Section --> */}
              <section
                className={`bg-sub-section rounded-3xl shadow-lg mb-5 animate-fadeIn ${
                  isMobile ? 'py-5' : 'p-5'
                }`}
              >
                <h3 className="section-header">
                  Motivator Descriptions
                  <span
                    className="position-absolute"
                    style={{
                      bottom: '-10px',
                      width: '120px',
                      height: '4px',
                      left: 'calc(50% - 60px)',
                      backgroundColor: '#3b82f6',
                      zIndex: 1
                    }}
                  />
                </h3>
                <p class="helper-text text-center">
                  The section provides a detailed breakdown of each core
                  motivator, helping you understand the unique elements that
                  drive you. Each motivator is accompanied by insights into the
                  strengths it provides, as well as potential paths for learning
                  and growth.
                </p>
                <div className={`row g-4 ${isMobile ? 'p-b pr-5 pl-5' : ''}`}>
                  {/* <!-- Individualistic Motivator --> */}

                  {analysis &&
                    Object.entries(analysis.motivator_descriptions).map(
                      ([key, value]) => {
                        return (
                          <div className="col-md-6">
                            <div className="content-card p-4 position-relative bg-white rounded-3 shadow-sm h-100">
                              <div className="d-flex align-items-center mb-4">
                                {iconMapping[key.toLowerCase()]}
                                <h4 className="content-title mb-0">
                                  {capitalizeFirstLetter(key)}
                                </h4>
                              </div>
                              <p style={{ minHeight: '66.14px' }}>
                                {value.summary}
                              </p>
                              <p class="helper-text">{value.description}</p>
                              <div className="bg-sub-section p-3 rounded-3 mb-3">
                                <h5 className="fw-bold">Universal Assets</h5>
                                <ul className="bullet-list">
                                  {value.universal_assets.map((asset) => (
                                    <li>{asset}</li>
                                  ))}
                                </ul>
                              </div>
                              <div className="bg-sub-section p-3 rounded-3">
                                <h5 className="fw-bold">Learning Paths</h5>
                                <ul className="bullet-list">
                                  {value.learning_paths.map((path) => (
                                    <li>{path}</li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                </div>
              </section>

              {/* <!-- Motivator Word Matrix Section -->
        <!-- Motivator Word Matrix Section --> */}
              <section className="bg-sub-section rounded-3xl shadow-lg p-5 mb-5 animate-fadeIn">
                <h3 className="section-header">
                  Motivator Word Matrix
                  <span
                    className="position-absolute"
                    style={{
                      bottom: '-10px',
                      left: 'calc(50% - 60px)',
                      width: '120px',
                      height: '4px',
                      backgroundColor: '#3b82f6',
                      zIndex: 1
                    }}
                  />
                </h3>
                <p class="helper-text text-center">
                  This matrix helps you quickly understand key words that
                  summarize your core motivators. Each word reflects the driving
                  force behind each motivator in your profile.
                </p>
                <div className="word-matrix">
                  {/* <!-- Individualistic Motivator --> */}
                  {analysis &&
                    Object.entries(analysis.motivator_word_matrix).map(
                      ([key, value]) => {
                        return (
                          <div className="content-card position-relative p-4 bg-white rounded-3 shadow-sm text-center h-100 d-flex flex-column justify-content-center align-items-center">
                            <div className="ms-3 mb-3">
                              {iconMapping[key.toLowerCase()]}
                            </div>

                            <h4 className="content-title mb-2">
                              {capitalizeFirstLetter(key)}
                            </h4>
                            <p className="motivator-word fw-bold display-6 text-black">
                              {value.generalDescriptor}
                            </p>
                            <p class="helper-text">{value.description}</p>
                          </div>
                        );
                      }
                    )}
                </div>
              </section>

              {/* <!-- Population Scores Section -->
        <!-- Population Scores Section --> */}
              <section className="bg-sub-section rounded-3xl shadow-lg p-5 mb-5 animate-fadeIn">
                <h3 className="section-header">
                  Population Scores
                  <span
                    className="position-absolute"
                    style={{
                      bottom: '-10px',
                      width: '120px',
                      left: 'calc(50% - 60px)',
                      height: '4px',
                      backgroundColor: '#3b82f6',
                      zIndex: 1
                    }}
                  />
                </h3>
                <p class="helper-text text-center">
                  This section shows how your scores compare with a larger
                  population. Understanding where you stand relative to others
                  can help you recognize strengths and areas for growth.
                </p>
                <div className="row g-4">
                  {/* Population Chart Area */}
                  <div className="col-lg-6 d-flex align-items-center justify-content-center">
                    <div className="position-relative w-100">
                      <div className="population-chart-container bg-white rounded-3 p-4 shadow-sm">
                        <canvas id="populationScoresChart"></canvas>
                      </div>
                    </div>
                  </div>

                  {/* Population Score Details Area */}
                  <div className="col-lg-6">
                    <div className="row g-4">
                      {analysis &&
                        Object.entries(analysis.population_scores).map(
                          ([key, value]) => {
                            return (
                              <div className="col-12">
                                <div className="content-card p-4 bg-white rounded-3 shadow-sm d-flex align-items-center">
                                  {iconMapping[key.toLowerCase()]}
                                  <div>
                                    <h4 className="content-title mb-1">
                                      {capitalizeFirstLetter(key)}
                                    </h4>
                                    <p className="mb-0 text-muted">
                                      Median: {value.median_line}
                                      <br />
                                      Shaded Area: {value.shaded_area[0]}-
                                      {value.shaded_area[1]}
                                      <br />
                                      Grey Box Plot: {value.grey_box_plot[0]}-
                                      {value.grey_box_plot[1]}
                                    </p>
                                    <p class="helper-text">
                                      {value.description}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )}
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <footer className="text-center animate-fadeIn d-flex align-items-center justify-content-center">
              <PDFDownloadButton
                label={'Download PDF'}
                loadingLabel={'Generating PDF...'}
                isLoading={pdfLoading}
                onClick={handleDownloadPDF}
              />
            </footer>
          </>
        )}
      </div>
    </div>
  );
};

export default MotivatorsReport;
