import { useEffect, useState } from 'react';
import './style.css';
import { toast } from 'react-toastify';
import Search from '../Jobick/Jobs/ui/search';
import { Button, Card, Form, Spinner } from 'react-bootstrap';
import moment from 'moment';
import {
  formatNumberWithCommas,
  formatToCurrency,
  getItemsPerPage
} from '../../utils/CommonFunctions';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { IoSearch } from 'react-icons/io5';

const Billing = () => {
  const [billingDetails, setBillingDetails] = useState(null);
  const [searchQuery, setSetSearchQuery] = useState('');
  const [pageVal, setPageVal] = useState(1); // for value of pagination
  const [totalPages, setTotalPages] = useState(1);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [isLoadingTransactions, setIsLoadingTransactions] = useState(false);
  const [totalTransactions, setTotalTransactions] = useState();
  const transactionsPerPage = 5;

  const accessToken = localStorage.getItem('userDetails')
    ? JSON.parse(localStorage.getItem('userDetails')).session.accessToken
    : null;

  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const orgId = userDetails?.user?.organizationId;

  const openLoader = () => {
    const loaderEvent = new CustomEvent('toggleLoader', {
      detail: {
        action: 'open',
        title: 'Fetching billing details',
        fullSize: true
      }
    });
    window.dispatchEvent(loaderEvent);
  };

  const closeLoader = () => {
    const loaderEvent = new CustomEvent('toggleLoader', {
      detail: { action: 'close' }
    });
    window.dispatchEvent(loaderEvent);
  };

  const handlePreviousPageClick = () => {
    if (pageVal === 1) {
      return;
    }
    setPageVal(pageVal - 1);
  };

  const handleNextPageClick = () => {
    if (pageVal >= totalPages) {
      return;
    }
    setPageVal(pageVal + 1);
  };

  const renderTransactionTableBody = () => {
    if (isLoadingTransactions) {
      return (
        <tr>
          <td colSpan={4} className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </td>
        </tr>
      );
    } else {
      const transactions = billingDetails.transactions;
      // if (transactions.length === 0) {
      //   return (
      //     <div className="d-flex flex-column align-items-center mt-5 w-100">
      //       <IoSearch size={120} color="#878787B7" />
      //       <h2>No results found</h2>
      //       <h3 style={{ color: '#828282' }}>Change your Job and try again</h3>
      //     </div>
      //   )
      // }
      return transactions.map(({ _id, timestamp, remarks, points }) => (
        <tr key={_id} style={{ height: '4.5rem' }}>
          <td style={{ width: '4rem' }} className="text-truncate">
            {moment(timestamp).format('YYYY-MM-DD')}
          </td>
          <td style={{ maxWidth: '14rem' }} className="text-truncate">
            {_id}
          </td>
          <td style={{ maxWidth: '34rem' }} className="text-truncate">
            {remarks}
          </td>
          <td
            style={{ maxWidth: '1rem', textAlign: 'left' }}
            className="text-truncate"
          >
            {points}
          </td>
        </tr>
      ));
    }
  };

  const createUrl = () => {
    let url = `${
      process.env.REACT_APP_DEV_API
    }/v1/organization/${orgId}/billing?limit=${getItemsPerPage()}`;
    if (searchQuery) {
      url = url + `&search=${searchQuery}`;
    }
    if (pageVal) {
      url = url + `&page=${pageVal}`;
    }

    return url;
  };

  const fetchBillingDetails = async () => {
    try {
      setIsLoadingTransactions(true);
      const response = await fetch(createUrl(), {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      });

      if (!response.ok) {
        toast.error('Something went wrong');
        return;
      }

      const { data } = await response.json();
      setTotalPages(data.totalPages);
      setTotalTransactions(200);
      setBillingDetails(data);
    } catch (error) {
      console.log('Error fetching application details', error);
      toast.error('Something went wrong');
    } finally {
      setIsLoadingTransactions(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 940);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    fetchBillingDetails();
  }, [searchQuery, pageVal]);

  useEffect(() => {
    (async () => {
      try {
        setIsLoadingPage(true);
        const response = await fetch(createUrl(), {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        });

        if (!response.ok) {
          toast.error('Something went wrong');
          return;
        }

        const { data } = await response.json();
        setTotalPages(data.totalPages);
        setTotalTransactions(200);
        setBillingDetails(data);
      } catch (error) {
        console.log('Error fetching application details', error);
        toast.error('Something went wrong');
      } finally {
        setIsLoadingPage(false);
      }
    })();
  }, []);

  const pgItem1 =
    pageVal === 1
      ? 1
      : pageVal >= totalPages && totalPages > 2
        ? totalPages - 2
        : pageVal - 1;
  const pgItem2 =
    pageVal === 1
      ? 2
      : pageVal >= totalPages && totalPages > 2
        ? totalPages - 1
        : pageVal;
  const pgItem3 =
    pageVal === 1
      ? 3
      : pageVal >= totalPages && totalPages > 2
        ? totalPages
        : pageVal + 1;
  return (
    <div class="d-flex w-100 flex-column align-items-start gap-5">
      <div className="billing-header">
        <div className="billing-titles">
          <h1 className="billing-page-title">Billing Dashboard</h1>
          <p className="mb-0">
            Manage your billing information and transactions seamlessly.
          </p>
        </div>
      </div>

      {isLoadingPage ? (
        <div className="d-flex w-100 justify-content-center">
          <Spinner />
        </div>
      ) : (
        <>
          <div
            className={`d-flex gap-3 w-100 justify-content-between ${
              isMobile ? 'flex-column' : 'billing-cards'
            }`}
          >
            <Card className={` mb-0 h-100 ${isMobile ? 'w-100' : 'w-50'}`}>
              <Card.Body className="bg-primary text-white rounded d-flex flex-column gap-2">
                <Card.Title className="text-white mb-0">
                  Current Credits
                </Card.Title>
                <Card.Text className="display-5 text-white fw-bold">
                  {billingDetails && billingDetails.currentCredits
                    ? formatNumberWithCommas(billingDetails.currentCredits)
                    : '-'}
                </Card.Text>
                <Card.Text>Available credits for your transactions.</Card.Text>
              </Card.Body>
            </Card>
            <Card className={` mb-0 h-100 ${isMobile ? 'w-100' : 'w-50'}`}>
              <Card.Body className="rounded d-flex flex-column gap-2">
                <Card.Title className="text-dark mb-0">
                  Redeem Coupon
                </Card.Title>

                <Form className="d-flex gap-3">
                  <Form.Control type="text" placeholder="Enter coupon code" />
                  <Button disabled>Redeem</Button>
                </Form>
                <Card.Text className="h5 text-black-50">
                  Have a coupon? Redeem it here to get additional credits.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>

          {billingDetails && billingDetails.transactions && (
            <div className="d-flex flex-column gap-4 w-100">
              <div
                className={`w-100 d-flex justify-content-between gap-2 ${
                  isMobile
                    ? 'flex-column align-items-start'
                    : 'align-items-center'
                }`}
              >
                <p className="h3 mb-0">Usage and Transactions</p>
                <Search
                  styles={!isMobile ? {} : { width: '100%' }}
                  searchVal={searchQuery}
                  setSearchVal={setSetSearchQuery}
                />
              </div>
              <div class="table-responsive">
                <table
                  id="job-table"
                  className="table table-fixed display dataTablesCard mb-4 job-table card-table dataTable no-footer w-100 position-relative"
                  style={{
                    height:
                      billingDetails.transactions.length === 0 ? '500px' : ''
                  }}
                >
                  <thead>
                    <tr className="user-select-none ">
                      <th style={{ width: '4rem' }}>Date</th>
                      <th style={{ width: '14rem' }}>Transaction ID</th>
                      <th style={{ width: '34rem' }}>Description</th>
                      <th style={{ width: '1rem', textAlign: 'left' }}>
                        Credits Used
                      </th>
                    </tr>
                  </thead>
                  {billingDetails.transactions.length === 0 ? (
                    <div className="position-absolute top-50 start-50 translate-middle d-flex flex-column align-items-center mt-5 w-100">
                      <IoSearch size={120} color="#878787B7" />
                      <h2>No results found</h2>
                      <h3 style={{ color: '#828282' }}>
                        Change your Job and try again
                      </h3>
                    </div>
                  ) : (
                    <tbody>{renderTransactionTableBody()}</tbody>
                  )}
                </table>
              </div>

              <nav
                className={`d-flex ${
                  isMobile
                    ? 'flex-column align-items-center'
                    : 'justify-content-between'
                }`}
              >
                <p className="dataTables_info">{`Showing ${
                  billingDetails.transactions.length !== 0
                    ? transactionsPerPage * (pageVal - 1) + 1
                    : 0
                } to ${
                  billingDetails.transactions
                    ? transactionsPerPage * (pageVal - 1) +
                      billingDetails.transactions.length
                    : 0
                } of ${totalPages} entries`}</p>
                <ul className="pagination pagination-circle justify-content-center">
                  <li className="page-item page-indicator">
                    <button
                      onClick={handlePreviousPageClick}
                      disabled={pageVal === 1}
                      className={' page-link'}
                      style={{ backgroundColor: pageVal === 1 && '#ECECEC',
                        cursor: pageVal === 1 && 'not-allowed',
                       }}
                    >
                      <FaAngleLeft
                        style={{ color: pageVal === 1 && '#616161' }}
                      />
                    </button>
                  </li>
                  {pgItem1 > 1 ? (
                    <li className="page-item d-flex align-items-end">
                      <label className="fs-4">...</label>
                    </li>
                  ) : (
                    <div style={{ width: '15px' }} />
                  )}
                  <li
                    className={`page-item ${pgItem1 === pageVal && 'active'}`}
                    onClick={() => {
                      setPageVal(pgItem1);
                    }}
                  >
                    <Button className="page-link">{pgItem1}</Button>
                  </li>
                  {totalPages > 1 && (
                    <li
                      className={`page-item ${pgItem2 === pageVal && 'active'}`}
                      onClick={() => {
                        setPageVal(pgItem2);
                      }}
                    >
                      <Button className="page-link">{pgItem2}</Button>
                    </li>
                  )}
                  {totalPages > 2 && (
                    <li
                      className={`page-item ${pgItem3 === pageVal && 'active'}`}
                      onClick={() => {
                        setPageVal(pgItem3);
                      }}
                    >
                      <Button className="page-link">{pgItem3}</Button>
                    </li>
                  )}
                  {pgItem3 < totalPages ? (
                    <li className="page-item d-flex align-items-end">
                      <label className="fs-4">...</label>
                    </li>
                  ) : (
                    <div style={{ width: '15px' }} />
                  )}
                  <li className="page-item page-indicator">
                    <button
                      onClick={handleNextPageClick}
                      disabled={pageVal >= totalPages}
                      className="page-link"
                      style={{
                        backgroundColor: pageVal >= totalPages && '#ECECEC',
                        cursor: pageVal >= totalPages && 'not-allowed',
                      }}
                    >
                      <FaAngleRight
                        style={{ color: pageVal >= totalPages && '#616161' }}
                      />
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          )}

          {billingDetails && billingDetails.totalDue && (
            <div className="d-flex flex-column w-100 gap-3">
              <p className="h3">Amount Due</p>
              <Card className={`${isMobile ? 'w-100' : 'amount-due-card'}`}>
                <Card.Body>
                  <Card.Text className="text-danger h2 mb-3">
                    {`Total Due: ${formatToCurrency(
                      billingDetails.totalDue?.amount ?? 0
                    )}`}
                  </Card.Text>
                  <Card.Text className="mb-3 h5 text-black-50">
                    {`Please complete your payment by ${moment(
                      billingDetails.totalDue.lastDate
                    ).format('YYYY-MM-DD')} to avoid service interruption.`}
                  </Card.Text>
                  <Button variant="primary" className="w-100" disabled>
                    Proceed to Payment
                  </Button>
                </Card.Body>
              </Card>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Billing;
