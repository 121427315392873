import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import currencySymbols from 'currency-symbol-map';
import { formatToCurrency } from '../../utils/CommonFunctions';
import { BsFillSuitcaseLgFill } from 'react-icons/bs';
import { FaDotCircle, FaMapMarkedAlt } from 'react-icons/fa';

const JobView = () => {
  const { jobId } = useParams() || {};
  const [jobDetails, setJobDetails] = useState(null);
  const [loading, setLoading] = useState(0); // 0=loading 1=loaded 2=errored
  const navigate = useNavigate();
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const orgId = userDetails?.user?.organizationId;
  const [currencySymbol, setCurrencySymbol] = useState('₹');

  useEffect(() => {
    const storedCurrency = localStorage.getItem('currency');

    if (storedCurrency) {
      const symbol = currencySymbols(storedCurrency) || '₹';
      setCurrencySymbol(symbol);
    }
  }, []);

  const fetchJobDetails = async () => {
    const URL = `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/job/${jobId}`;
    try {
      setLoading(0);
      const response = await fetch(URL);
      if (!response.ok) {
        setLoading(2);
        navigate('/page-error-404');
        return;
      }
      const body = await response.json();
      const data = body.data;
      const responsibilitiesArray = data.responsibilities
        .split('. ')
        .map((item) => item.trim())
        .filter((item) => item);

      data['responsibilities'] = responsibilitiesArray;
      setJobDetails(data);
      setLoading(1);
    } catch (error) {
      console.log('Error fetching job details', error);
      navigate('/page-error-404');
    }
  };

  useEffect(() => {
    fetchJobDetails();
  }, [jobId]);

  const formatStringForFrontend = (str) => {
    const words = str.split('_');

    const formatWord = (word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    };

    const formattedWords = words.map((word) => {
      if (word.includes(' ')) {
        return word
          .split(' ')
          .map((w) => {
            return formatWord(w);
          })
          .join(' ');
      } else {
        return formatWord(word);
      }
    });

    return formattedWords.join(' ');
  };

  const handleCloneJob = () => {
    navigate(`/clone-job/${jobId}`);
  };

  const timeAgo = (pastDate) => {
    const currentDate = new Date();
    const pastDateTime = new Date(pastDate);

    const diffInMs = currentDate - pastDateTime;

    const seconds = Math.floor(diffInMs / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (years > 0) {
      return years === 1 ? '1 year ago' : `${years} years ago`;
    } else if (months > 0) {
      return months === 1 ? '1 month ago' : `${months} months ago`;
    } else if (weeks > 0) {
      return weeks === 1 ? '1 week ago' : `${weeks} weeks ago`;
    } else if (days > 0) {
      return days === 1 ? '1 day ago' : `${days} days ago`;
    } else if (hours > 0) {
      return hours === 1 ? '1 hour ago' : `${hours} hours ago`;
    } else if (minutes > 0) {
      return minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`;
    } else {
      return seconds === 1 ? '1 second ago' : `${seconds} seconds ago`;
    }
  };

  const handleAddNewJob = () => {
    navigate('/new-job');
  };

  const renderExperienceField = (min, max) => {
    if (min || min === 0) {
      if (max) {
        return `${min} - ${max} Years`;
      } else {
        return `${min} Years`;
      }
    } else {
      return '';
    }
  };
  const renderExperienceLabel = (min, max) => {
    if (min || min === 0) {
      if (max) {
        return 'Experience';
      } else {
        return 'Minimum Experience';
      }
    } else {
      return '';
    }
  };

  return (
    <>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <h3 className="mb-0 me-auto">Job View</h3>
        <div>
          <Button
            className="btn btn-primary me-3 btn-sm"
            onClick={handleAddNewJob}
          >
            <i className="fas fa-plus me-2"></i>Add New Job
          </Button>
          <Link
            to={`/job-application/${jobId}`}
            className="btn btn-secondary btn-sm p-1"
            title="View Applications"
          >
            <svg
              version="1.1"
              viewBox="0 0 2048 2048"
              width="24"
              height="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="white"
                transform="translate(254)"
                d="m0 0h1217l4 1 24 8 19 10 16 13 9 9 11 15 8 15 7 21 2 11 1 11v1500l-2 17-6 21-8 16-7 11-11 13-12 11-14 9-14 7-19 6-12 2-11 1h-1186l-19-2-17-5-21-10-12-9-11-9-11-13-10-16-7-16-5-18-2-18v-748l2-11 5-10 9-10 8-5 12-3h10l13 4 10 7 8 11 3 9 1 6 1 746 3 14 4 8 6 7 10 6 12 3h1183l12-3 10-6 7-8 4-9 2-10v-1492l-2-10-4-9-6-8-10-6-11-3-7-1h-1173l-13 2-12 6-7 7-5 10-3 16-1 409-3 12-5 9-8 8-9 5-12 3h-8l-12-3-10-7-7-8-5-10-2-11v-413l2-18 4-15 5-13 8-15 9-12 9-10 10-9 14-9 17-8 20-6z"
              />
              <path
                transform="translate(1845,320)"
                d="m0 0h14l10 3 9 6 8 9 4 8 2 7 1 17v1548l-1 22-2 14-7 21-8 16-10 14-10 11-10 8-16 10-16 7-16 5-7 2h-1288l2-2-9-4-10-9-6-9-3-10v-11l4-13 6-9 7-6 12-6 13-2 1258-1 12-3 9-6 5-5 4-5 3-9 1-5 1-26v-1518l1-35 4-12 6-9 8-7 10-5z"
                fill="white"
              />
              <path
                transform="translate(1687,160)"
                d="m0 0h9l9 2 10 5 7 6 6 9 3 8 1 5v1582l-2 16-6 20-8 16-7 11-13 15-11 9-14 9-15 7-16 5-10 2-10 1-22 1h-1072l-15-1-12-3-10-7-7-8-5-11-1-4v-13l4-13 7-9 9-7 10-4 6-1 1063-1 42-1 11-3 9-6 5-5 5-8 3-12 1-1571 2-12 7-14 7-7 14-7z"
                fill="white"
              />
              <path
                transform="translate(423,295)"
                d="m0 0h375l17 2 12 6 6 5 7 10 3 8 1 6v391l-3 12-5 9-5 5-9 7-10 3-7 1h-389l-13-3-10-7-7-8-5-10-2-12v-386l2-10 6-12 5-5 6-5 10-5zm38 81-1 1v302l4 1 299-1v-303z"
                fill="white"
              />
              <path
                transform="translate(866,995)"
                d="m0 0h458l18 2 12 6 9 9 6 12 2 12-3 15-7 11-9 8-9 4-11 2h-472l-13-3-10-7-7-8-5-10-1-4v-16l4-11 8-11 11-7 10-3z"
                fill="white"
              />
              <path
                transform="translate(866,1299)"
                d="m0 0h458l18 2 12 6 9 9 6 12 2 12-3 15-7 11-9 8-9 4-11 2h-472l-13-3-10-7-7-8-5-10-1-4v-16l4-11 8-11 11-7 5-2z"
                fill="white"
              />
              <path
                transform="translate(644,929)"
                d="m0 0 10 1 10 3 11 8 7 9 4 10 1 3v13l-3 10-9 13-135 135-13 8-8 2h-14l-8-2-10-6-12-11-69-69-7-10-4-9-1-5v-10l4-13 8-11 9-7 11-4 10-1 13 3 12 7 13 12v2l4 2v2l4 2v2h2v2h2v2h2v2h2v2l4 2v2h2v2h2v2l4 2 9 9 4-1 1-2h2l2-4 15-15h2l2-4h2l2-4h2l2-4 16-16h2l2-4 6-5 6-7 6-5 6-7 6-5 6-7h2l2-4 5-5h2v-2l12-9 8-4z"
                fill="white"
              />
              <path
                transform="translate(642,1235)"
                d="m0 0h10l13 4 9 7 7 8 4 8 2 7v12l-3 10-7 11-14 15-120 120-10 7-8 4-11 3-7-1-11-3-12-8-79-79-7-11-3-7-1-5v-9l3-12 8-12 11-8 7-3 6-1h11l13 4 12 9 45 45 3 1 104-104 13-9z"
                fill="white"
              />
              <path
                transform="translate(1030,295)"
                d="m0 0h273l16 2 12 6 7 6 7 11 3 11v9l-3 12-8 12-8 6-9 4-11 2h-285l-13-3-10-7-7-8-5-10-1-4v-16l4-11 7-10 10-7 7-3z"
                fill="white"
              />
              <path
                transform="translate(1031,487)"
                d="m0 0h269l19 2 12 6 7 6 7 11 3 11v10l-4 13-7 10-8 6-9 4-11 2h-285l-13-3-10-7-7-8-5-10-1-4v-16l4-11 8-11 11-7 5-2z"
                fill="white"
              />
              <path
                transform="translate(1031,679)"
                d="m0 0h269l19 2 12 6 7 6 7 11 3 11v10l-4 13-7 9-8 7-9 4-11 2h-285l-13-3-10-7-7-8-5-10-1-4v-16l4-11 8-11 11-7 5-2z"
                fill="white"
              />
              <path
                transform="translate(189,667)"
                d="m0 0h13l10 3 11 7 7 9 4 8 2 8v10l-4 13-6 8-5 5-9 6-11 3h-11l-13-4-9-7-6-7-5-10-1-4v-16l4-11 8-11 11-7z"
                fill="white"
              />
              <path
                transform="translate(351,1808)"
                d="m0 0h10l13 4 11 8 7 10 4 12v12l-3 10-7 11-7 6-9 5-9 2h-10l-12-3-10-7-7-8-5-11-1-4v-13l3-10 6-10 9-8 11-5z"
                fill="white"
              />
              <path transform="translate(1477)" d="m0 0" fill="white" />
              <path transform="translate(251)" d="m0 0" fill="white" />
            </svg>
          </Link>
        </div>
      </div>
      {loading === 1 && jobDetails && (
        <div className="row">
          <div className="col-xl-9 col-xxl-8">
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div
                    className="card-header"
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div>
                      <h4 className="fs-20 d-block">
                        <Link className="text-black" to={'#'}>
                          {jobDetails.title}
                        </Link>
                      </h4>
                      <div className="d-block">
                        {/* <span className="me-2"><Link to={"#"}><i className="text-primary fas fa-briefcase me-2"></i>Apcd company</Link></span> */}
                        <span className="me-2">
                          <label>
                            <FaMapMarkedAlt className="text-primary mr-2 " />
                            {jobDetails.location}
                          </label>
                        </span>
                        {/* <span><Link to={"#"}><i className="text-primary fas fa-eye me-2"></i>View</Link></span> */}
                      </div>
                      <div className="d-block">
                        {/* <span className="me-2"><Link to={"#"}><i className="text-primary fas fa-briefcase me-2"></i>Apcd company</Link></span> */}
                        <span className="me-2">
                          <label>
                            <BsFillSuitcaseLgFill className="mr-2 text-primary" />
                            {jobDetails.companyName}
                          </label>
                        </span>
                        {/* <span><Link to={"#"}><i className="text-primary fas fa-eye me-2"></i>View</Link></span> */}
                      </div>
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="btn btn-primary btn-block d-flex justify-content-center align-items-center"
                        onClick={handleCloneJob}
                      >
                        Clone Job
                      </button>
                    </div>
                  </div>

                  <div className="card-body pb-0">
                    <h4 className="fs-20 mb-3">Skill & Experience</h4>
                    <div className="ms-2 d-flex flex-wrap">
                      {jobDetails.skills.map((skill) => (
                        <label className="bg-secondary-light me-2 px-3 py-1 rounded text-secondary ">
                          {skill}
                        </label>
                      ))}
                    </div>
                    <h4 className="fs-20 mt-2 mb-3">Overview</h4>
                    <div className="ms-2">
                      <p>{jobDetails.description}</p>
                    </div>
                    <h4 className="fs-20 mb-3">Requirements</h4>
                    <div className="ms-2 d-flex flex-column gap-2 justify-content-start mb-4">
                      {jobDetails.responsibilities.map((item) => (
                        <div
                          key={item}
                          className="d-flex gap-1 align-items-center gap-2"
                        >
                          <FaDotCircle className="text-primary border-2" />
                          <p className="mb-0">{item}</p>
                        </div>
                      ))}
                    </div>
                    <h4 className="fs-20 mb-3">Qualifications</h4>
                    <div className="ms-2  d-flex flex-column gap-2 justify-content-start mb-4">
                      {jobDetails.qualifications.split('. ').map((item) => (
                        <div
                          key={item}
                          className="d-flex gap-1 align-items-center gap-2"
                        >
                          <FaDotCircle className="text-primary  border-2" />
                          <p className="mb-0">{item}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-xxl-4">
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-header border-0 pb-0">
                    <h4 className="fs-20 mb-0">Details</h4>
                  </div>
                  <div className="card-body pt-4">
                    <div className="mb-3 d-flex gap-5 justify-content-between">
                      <h5 className="mb-1 fs-14 custom-label">
                        Work Location Type
                      </h5>
                      <span>
                        {formatStringForFrontend(jobDetails.workLocationType)}
                      </span>
                    </div>

                    <div className="mb-3 d-flex gap-5 justify-content-between">
                      <h5 className="mb-1 fs-14 custom-label">
                        {renderExperienceLabel(
                          jobDetails.minimumExperience,
                          jobDetails.maximumExperience
                        )}
                      </h5>
                      <span>
                        {renderExperienceField(
                          jobDetails.minimumExperience,
                          jobDetails.maximumExperience
                        )}
                      </span>
                    </div>
                    <div className="mb-3 d-flex gap-5 justify-content-between">
                      <h5 className="mb-1 fs-14 custom-label">Salary</h5>
                      <span>
                        {jobDetails.minimumSalary ? (
                          <>
                            <span className="salary-amount">
                              {formatToCurrency(jobDetails.minimumSalary) || 0}
                            </span>
                            <span className="salary-separator">
                              &nbsp;-&nbsp;
                            </span>
                            <span className="salary-amount">
                              {formatToCurrency(jobDetails.maximumSalary) || 0}
                            </span>
                          </>
                        ) : jobDetails.maximumSalary ? (
                          <span className="salary-amount">
                            {formatToCurrency(jobDetails.maximumSalary) || 0}
                          </span>
                        ) : (
                          'NA'
                        )}
                      </span>
                    </div>

                    <div className="mb-3 d-flex gap-5 justify-content-between">
                      <h5 className="mb-1 fs-14 custom-label">Location</h5>
                      <span>{jobDetails.location}</span>
                    </div>
                    <div className="mb-3 d-flex gap-5 justify-content-between">
                      <h5 className="mb-1 fs-14 custom-label">Job Type</h5>
                      <span>{`${formatStringForFrontend(
                        jobDetails.jobType
                      )} (${formatStringForFrontend(
                        jobDetails.workHours
                      )})`}</span>
                    </div>

                    <div className="mb-3 d-flex gap-5 justify-content-between">
                      <h5 className="mb-1 fs-14 custom-label">Posted Date</h5>
                      <span>{timeAgo(jobDetails.createdAt)}</span>
                    </div>
                  </div>
                  <div className="card-footer border-0 pt-0 "></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {loading === 0 && (
        <div className="d-flex flex-column align-items-center justify-content-center gap-5 mt-5 pt-5">
          <div className="mt-5 mb-5 pt-5 pb-4"></div>
          <Spinner animation="border mb-3" style={{ color: '#858585' }} />
        </div>
      )}
      {loading === 2 && <h1>Job not found</h1>}
    </>
  );
};
export default JobView;
