import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import '../Jobs/NewJob/loader.css';
import CreateAdminModal from './CreateAdminModal';
import Flag from 'react-world-flags';
import { countryCodes } from '../../utils/CommonFunctions';
import { Dropdown, Spinner } from 'react-bootstrap';
import LocationInput from './locationInput';

const defaultCountryCode = {
  name: 'India',
  code: '+91',
  iso: 'IN'
};

const CreateOrganisation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { orgId } = useParams();
  const [showAdminModal, setShowAdminModal] = useState(false);
  const [name, setName] = useState('');
  const [firstAdmin, setFirstAdmin] = useState(null);
  const [uniqueName, setUniqueName] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [locationSelected, setLocationSelected] = useState(false);
  const [userLocation, setUserLocation] = useState('');
  const [timezone, setTimezone] = useState('');
  const [searchCountryCode, setSearchCountryCode] = useState('');
  const handleOpenAdminModal = () => setShowAdminModal(true);
  const handleCloseAdminModal = () => setShowAdminModal(false);
  const [selectedCountryCode, setSelectedCountryCode] =
    useState(defaultCountryCode);
  const [error, setError] = useState({
    errorFor: '',
    error: ''
  });

  const validateUniqueName = (value) => {
    const namePattern = /^[a-z_]+$/;

    if (!namePattern.test(value)) {
      setError({
        errorFor: 'uniqueName',
        error:
          'Unique Name can only contain lowercase letters without spaces or special characters.'
      });
    } else {
      setError({
        errorFor: '',
        error: ''
      });
    }

    setUniqueName(value);
  };

  const isOnlyWhitespace = (str) => {
    return str.trim().length === 0;
  };

  const fetchOrgDetail = async () => {
    if (!orgId) {
      return;
    }

    setLoading(true);
    try {
      const superAdminUser = JSON.parse(localStorage.getItem('superAdminUser'));
      const accessToken = superAdminUser?.session?.accessToken || null;

      if (!accessToken) {
        throw new Error('Access token not found');
      }

      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setName(data.data.organization.name);
      setUniqueName(data.data.organization?.uniqueName ?? '');
      setAddress(data.data.organization.address);
      setUserLocation(data.data.organization.address);
      setPhone(data.data.organization.phone);
      setEmail(data.data.organization.email);
      setTimezone(data.data.organization.timeZone);
      setSelectedCountryCode(
        countryCodes.find(
          (countryCode) =>
            countryCode.code === data.data.organization.countryCode
        )
      );

      if (data.data.admins && data.data.admins.length > 0) {
        setFirstAdmin(data.data.admins[0]);
      } else {
        setFirstAdmin(null);
      }
    } catch (error) {
      if (error.message.includes('Cast to ObjectId failed')) {
        toast.error('Invalid Organization ID');
      } else {
        toast.error('An error occurred while fetching organisation details.');
        navigate('/page-error-404');
      }
      console.error('Error fetching organization details:', error);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   let isMounted = true;
  //   const controller = new AbortController();

  //   // const fetchOrgDetail = async () => {
  //   //   if (!orgId) return;

  //   //   setLoading(true);
  //   //   try {
  //   //     const superAdminUser = JSON.parse(localStorage.getItem("superAdminUser"));
  //   //     const accessToken = superAdminUser?.session?.accessToken || null;

  //   //     if (!accessToken) {
  //   //       throw new Error("Access token not found");
  //   //     }

  //   //     const response = await fetch(
  //   //       `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}`,
  //   //       {
  //   //         signal: controller.signal,
  //   //         headers: {
  //   //           Authorization: `Bearer ${accessToken}`,
  //   //         }
  //   //       }
  //   //     );

  //   //     if (!response.ok) {
  //   //       throw new Error(`HTTP error! status: ${response.status}`);
  //   //     }

  //   //     const data = await response.json();

  //   //     if (isMounted) {
  //   //       setName(data.data.organization.name);
  //   //       setAddress(data.data.organization.address);
  //   //       setPhone(data.data.organization.phone);
  //   //       setEmail(data.data.organization.email);

  //   //       if(data.data.admins && data.data.admins.length > 0){
  //   //         setFirstAdmin(data.data.admins[0]);
  //   //       } else{
  //   //         setFirstAdmin(null);
  //   //       }
  //   //     }
  //   //   } catch (error) {
  //   //     if (isMounted && error.name !== 'AbortError') {
  //   //       if (error.message.includes("Cast to ObjectId failed")) {
  //   //         toast.error("Invalid Organization ID");
  //   //       } else {
  //   //         toast.error("An error occurred while fetching organisation details.");
  //   //         navigate("/404");
  //   //       }
  //   //       console.error("Error fetching organization details:", error);
  //   //     }
  //   //   } finally {
  //   //     if (isMounted) {
  //   //       setLoading(false);
  //   //     }
  //   //   }
  //   // };

  //   fetchOrgDetail();

  //   return () => {
  //     isMounted = false;
  //     controller.abort();
  //   };
  // }, [orgId]);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const fetchOrgDetail = async () => {
      if (!orgId) {
        return;
      }

      setLoading(true);
      try {
        const superAdminUser = JSON.parse(
          localStorage.getItem('superAdminUser')
        );
        const accessToken = superAdminUser?.session?.accessToken || null;

        if (!accessToken) {
          throw new Error('Access token not found');
        }

        const response = await fetch(
          `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}`,
          {
            signal: controller.signal,
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (isMounted) {
          setUniqueName(data.data.organization?.uniqueName ?? '');
          setName(data.data.organization.name);
          //setAddress(data.data.organization.address);
          setUserLocation(data.data.organization.address);
          setPhone(data.data.organization.phone);
          setEmail(data.data.organization.email);
          setTimezone(data.data.organization.timeZone);
          setSelectedCountryCode(
            countryCodes.find(
              (countryCode) =>
                countryCode.code === data.data.organization.countryCode
            )
          );

          if (data.data.admins && data.data.admins.length > 0) {
            setFirstAdmin(data.data.admins[0]);
          } else {
            setFirstAdmin(null);
          }
        }
      } catch (error) {
        if (isMounted && error.name !== 'AbortError') {
          if (error.message.includes('Cast to ObjectId failed')) {
            toast.error('Invalid Organization ID');
          } else {
            toast.error(
              'An error occurred while fetching organization details.'
            );
            navigate('/404');
          }
          console.error('Error fetching organization details:', error);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    const resetForm = () => {
      setName('');
      setAddress('');
      setPhone('');
      setEmail('');
      setFirstAdmin(null);
    };

    if (orgId) {
      fetchOrgDetail();
    } else {
      resetForm();
      setLoading(false);
    }

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [orgId, navigate]);

  const handleClose = () => {
    {
      orgId
        ? navigate('/super-admin/list-organisation')
        : navigate('/super-admin');
    }
  };

  const handleValidationError = (message) => {
    if (message.includes('phone')) {
      if (message.includes('required') || message.includes('empty')) {
        return "Phone number can't be empty";
      }
      return 'Phone number is invalid';
    }
    if (message.includes('name')) {
      if (message.includes('required') || message.includes('empty')) {
        return "Organisation name can't be empty";
      }
      return 'Organisation Unique name is already taken. Please choose a different one.';
    }
    if (message.includes('uniqueName')) {
      if (message.includes('required') || message.includes('empty')) {
        return "Organisation unique name can't be empty";
      }
      return 'Organisation name is invalid';
    }
    if (message.includes('address')) {
      if (message.includes('required') || message.includes('empty')) {
        return "Location can't be empty";
      }
      return 'Location is invalid';
    }
    if (message.includes('email')) {
      if (message.includes('required') || message.includes('empty')) {
        return "Email can't be empty";
      }
      return 'Email is invalid';
    }
    return 'An unknown error occurred';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name || isOnlyWhitespace(name)) {
      toast.error('Organisation name cannot be empty or contain only spaces');
      return;
    }

    if (phone && phone.length !== 10) {
      toast.error('Phone number must be exactly 10 digits');
      return;
    }

    if (!userLocation || isOnlyWhitespace(userLocation)) {
      toast.error("Location can't be empty");
      return;
    }

    if (!timezone || isOnlyWhitespace(timezone)) {
      toast.error("Timezone can't be empty");
      return;
    }

    if (error.error) {
      toast.error(error.error);
      return;
    }

    const method = orgId ? 'PUT' : 'POST';
    const url = orgId
      ? `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}`
      : `${process.env.REACT_APP_DEV_API}/v1/organization`;

    try {
      setLoading(true);
      const superAdminUser = JSON.parse(localStorage.getItem('superAdminUser'));
      const accessToken = superAdminUser?.session?.accessToken || null;

      if (!accessToken) {
        throw new Error('Access token not found');
      }

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          name: name.trim(),
          uniqueName,
          address: userLocation,
          timeZone: timezone,
          countryCode: selectedCountryCode.code,
          phone,
          email: email.trim()
        })
      });

      const data = await response.json();

      if (response.ok) {
        toast.success(
          orgId
            ? 'Organisation Updated Successfully'
            : 'New Organisation Created Successfully'
        );
        // You might want to navigate or reset form here
        navigate('/super-admin/list-organisation');
      } else {
        // Handle all error cases here
        if (
          data.message &&
          data.message.includes('E11000 duplicate key error')
        ) {
          if (data.message.includes('name_1 dup key')) {
            toast.error(
              `An organisation with the name "${name}" already exists.`
            );
          } else if (data.message.includes('uniqueName_1 dup key')) {
            toast.error(
              `An organisation with the name "${uniqueName}" already exists.`
            );
          } else if (data.message.includes('email_1 dup key')) {
            toast.error(
              `An organisation with the email "${email}" already exists.`
            );
          } else if (data.message.includes('phone_1 dup key')) {
            toast.error(
              `An organisation with the phone number "${phone}" already exists.`
            );
          } else {
            toast.error('This organisation already exists.');
          }
        } else if (
          data.message &&
          data.message.includes('Cast to ObjectId failed')
        ) {
          toast.error('Invalid Organization ID');
        } else {
          const errorMessage = handleValidationError(data.message);
          toast.error(errorMessage);
        }
      }
    } catch (error) {
      console.error('Error in handleSubmit:', error);
      toast.error(
        `An error occurred while ${
          orgId ? 'updating' : 'creating'
        } the Organisation.`
      );
    } finally {
      setLoading(false);
    }
  };

  const filteredCountryCodes = (search) => {
    return countryCodes.filter((countryCode) =>
      countryCode.name.toLowerCase().includes(search.toLowerCase())
    );
  };

  useEffect(() => {
    if (location.pathname === '/super-admin/create-organisation') {
      setUniqueName('');
      setTimezone('');
      setSelectedCountryCode(defaultCountryCode);
      setUserLocation('');
      setError({
        errorFor: '',
        error: ''
      });
    }
  }, [location]);

  return (
    <>
      <style>
        {`input.form-control,
          textarea.form-control,
          .custom-dropdown-toggle {
            font-family: inherit;
            font-size: 0.875rem;
            color: gray;
          }

          input.form-control::placeholder,
          textarea.form-control::placeholder,
          .custom-dropdown-toggle {
            color: lightgray;
          }


        .dropdown-menu {
        font-size: 0.875rem !important;
        }

        label.form-error {
          color: red;
          font-size: 0.7rem;
        }

        .dropdown-item {
          padding: 0.5rem 1rem;
        }
  
          .custom-dropdown-menu {
            transform: translate3d(0px, 30px, 0px) !important;
            min-width: 100% !important;
            font-style : inherit
          }
          

          .dropdown-item.active {
          background-color: #f8f9fa;
          color: #16181b;
          }

          .dropdown-item.active:before {
          content: '✓ ';
          margin-right: 5px;
          
          }

          .custom-dropdown-toggle {
            width: 100% !important;
            color:lightgray;
            font-weight : 400;
            font-size: 0.875 rem;
            font-style : inherit
            }

            .admin-details{
            height : auto !important;
            min-height:38px;
            white-space : normal;
            overflow : visible;
            border:none !important;
            // background-color: lightgrey;
            }

            .admin-details p{
            line-height : 1.2;
            font-family : inherit !important;
            }
        `}
      </style>
      {loading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
      <div className="d-flex align-items-center mb-4">
        <h3 className="mb-0 me-auto">
          {orgId ? 'Edit Organisation' : 'Add Organisation'}
        </h3>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-6  col-md-6 mb-4">
                  <label className="form-label font-w500">
                    Organisation Name
                    <span className="text-danger scale5 ms-2">*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="form-control solid"
                    placeholder="Thinkscoop Technologies"
                    aria-label="name"
                  />
                </div>
                <div className="col-xl-6  col-md-6 mb-4">
                  <label className="form-label font-w500">
                    Phone<span className="text-danger scale5 ms-2">*</span>
                  </label>
                  <div className="d-flex align-items-center justify-content-center flex-row custom-phone-field">
                    <Dropdown
                      className="form-control solid style-1 d-flex align-items-center"
                      style={{ width: 140 }}
                    >
                      <Dropdown.Toggle
                        tabIndex="0"
                        as="div"
                        className="custom-dropdown-toggle fs-16 font-w500 justify-content-between d-flex align-items-center"
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                            e.preventDefault();
                            e.target.click();
                          }
                        }}
                      >
                        <Flag
                          code={selectedCountryCode?.iso}
                          style={{ height: 20 }}
                        />{' '}
                        {selectedCountryCode?.code}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="custom-dropdown-menu dropdown-menu dropdown-menu-end mt-1">
                        <div className="d-flex align-items-center justify-content-between px-3 py-2">
                          <input
                            type="text"
                            placeholder="Search"
                            id="country-code-search"
                            className="form-control solid"
                            aria-label="search"
                            value={searchCountryCode}
                            onChange={(e) =>
                              setSearchCountryCode(e.target.value)
                            }
                          />
                        </div>
                        <div className="custom-scrollable-menu">
                          {filteredCountryCodes(searchCountryCode).length >
                          0 ? (
                            filteredCountryCodes(searchCountryCode).map(
                              (value) => (
                                <Dropdown.Item
                                  key={value.iso}
                                  onClick={() => {
                                    setSelectedCountryCode(value);
                                    setSearchCountryCode('');
                                  }}
                                >
                                  <Flag
                                    code={value.iso}
                                    style={{ height: 20 }}
                                  />{' '}
                                  {value.code} {value.name}
                                </Dropdown.Item>
                              )
                            )
                          ) : (
                            <Dropdown.Item disabled>
                              No country code found
                            </Dropdown.Item>
                          )}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                    <input
                      type="text"
                      value={phone}
                      placeholder="9898998989"
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d{0,10}$/.test(value)) {
                          setPhone(value);
                        }
                      }}
                      name="phone"
                      className="form-control solid"
                      aria-label="phone"
                      maxLength="10"
                    />
                  </div>
                </div>
                <div className="col-xl-6  col-md-6 mb-4">
                  <label className="form-label font-w500">
                    Organisation's Unique Name
                    <span className="text-danger scale5 ms-2">*</span>
                  </label>
                  <input
                    type="text"
                    name="unqiueName"
                    value={uniqueName !== null ? uniqueName : ''}
                    onChange={(e) => validateUniqueName(e.target.value)}
                    className="form-control solid"
                    placeholder={!uniqueName ? 'thinkscoopinc' : ''}
                    aria-label="name"
                  />
                  {error.errorFor === 'uniqueName' && (
                    <label className="form-error">{error.error}</label>
                  )}
                </div>
                <div className="col-xl-6  col-md-6 mb-4">
                  <label className="form-label font-w500">
                    Email<span className="text-danger scale5 ms-2">*</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-control solid"
                    placeholder="Example@gmail.com"
                    aria-label="name"
                  />
                </div>

                {/* <div className="col-xl-6  col-md-6 mb-4">
                  <label className="form-label font-w500">
                    Address
                    <span className="text-danger scale5 ms-2">*</span>
                  </label>
                  <textarea
                    className="form-control solid"
                    placeholder="40C, Aero Arcade, Mohali, India"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    name="address"
                    aria-label="address"
                    rows="4"
                    style={{
                      color: "gray",
                      "::placeholder": { color: "lightgray" },
                    }}
                  />
                </div> */}
                <div className="col-xl-6  col-md-6 mb-4">
                  <label className="form-label font-w500">
                    Location
                    <span className="text-danger scale5 ms-2">*</span>
                  </label>
                  <LocationInput
                    setUserLocation={setUserLocation}
                    setLocationSelected={setLocationSelected}
                    location={userLocation}
                  />
                </div>
                <div className="col-xl-6  col-md-6 mb-4">
                  <label className="form-label font-w500">
                    TimeZone<span className="text-danger scale5 ms-2">*</span>
                  </label>
                  <input
                    type="text"
                    name="timezone"
                    value={timezone}
                    onChange={(e) => {
                      setTimezone(e.target.value.toUpperCase());
                    }}
                    className="form-control solid"
                    placeholder="TimeZone"
                    aria-label="name"
                  />
                </div>

                <div>
                  {/* <input
                      // type="email"
                      // name="email"
                      // value={}
                      // onChange={(e) => setEmail(e.target.value)}
                      className="form-control solid"
                      placeholder=""
                      aria-label="name"
                      /> */}
                  {firstAdmin ? (
                    <div className="col-xl-6 col-md-6 mb-4">
                      <label className="form-label font-w500">Admin</label>
                      <div className="form-control solid admin-details">
                        <p className="mb-1">Name: {firstAdmin.name}</p>
                        <p className="mb-0">Email: {firstAdmin.email}</p>
                      </div>
                    </div>
                  ) : orgId ? (
                    <div className="col-xl-6 col-md-6 mb-4">
                      <CreateAdminModal
                        onAdminCreated={() => {
                          fetchOrgDetail();
                        }}
                        show={showAdminModal}
                        onHide={handleCloseAdminModal}
                        orgId={orgId}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="card-footer text-end">
              <div>
                <button
                  className="btn btn-danger light me-3"
                  onClick={handleClose}
                >
                  Close
                </button>
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="btn btn-primary me-3"
                >
                  {orgId ? 'Update Organisation' : 'Create Organisation'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateOrganisation;
